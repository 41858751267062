import { extendObservable } from "mobx";

import EventApi from "../services/EventApi";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import EventModel from "~/models/EventModel";
import UploadAPI from "../services/UploadAPI";

class EventStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      event: undefined,
      eventList: [],
      staffs: [],
      loading: true,
      translatables: [],
      url: '',
      dateInit: '',
      dateEnd : '',
      hourEvent: '',
      local: '',
      active: false,
      file : undefined,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "created,desc",
      filters: {},
    });
  }

  reset() {
    this.event = undefined;
    this.eventList = [];
    this.sort = "created,desc";
    this.size = 10;
    this.page = 0;
    this.loading = false;
    this.translatables = [];
    this.url = '';
    this.dateInit= '';
    this.dateEnd = '';
    this.hourEvent= '';
    this.local = '';
    this.active = false;
    this.file = undefined;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    if (prop == "local") {
      this.local = value;
    } else if (prop == "hourEvent") {
      this.hourEvent = value;
    } else if (prop == "dateInit") {
      this.dateInit = value;
    } else if (prop == "dateEnd") {
      this.dateEnd = value;
    } else if (prop == "url") {
      this.url = value;
    } else if (prop == "file") {
      this.file = value;
    }else if (prop == "active") {
      this.active = value;
    }
  }

  /**Cria um novo usuário adminin */
  async onCreate() {
    this.loading = true;

    const json = new EventModel()
    json.active = this.active;
    json.translatables = this.translatables;
    json.ordering = this.ordering;
    json.url = this.url;
    json.dateInit = this.dateInit;
    json.dateEnd = this.dateEnd;
    json.hourEvent = this.hourEvent;
    json.local = this.local;
    const responseFile = await UploadAPI.saveFile(this.file, "news");

    if (responseFile?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, responseFile?.error);
    } else {
      json.file = {
        "@class": "br.com.stoom.clickstarrett.model.ClickFile",
        uuid: responseFile.data.uuid
      }
    }
    const data = JSON.stringify(json);

    const response = await EventApi.create(data);
    this.loading = false;
    if (!response.error) {
      this.event = new EventModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Evento criada com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "created,desc";

    sort ? (this.sort = sort) : (this.sort = "created,desc");

    await this.getList();
    return true;
  }

  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await EventApi.getAlls({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.eventList = response.content.map(
      (usr) => new EventModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }


  /**
    * @param  {string} Uuid
   */
  async get(data) {
    this.loading = true;
    const response = await EventApi.getUser(data);
    this.translatables = response.translatables;
    this.dateInit = response.dateInit;
    this.dateEnd = response.dateEnd;
    this.hourEvent = response.hourEvent;
    this.local = response.local;
    this.active = response.active;
    this.file = response.file;
    this.url = response.url;

    this.loading = false;
    if (!response.error) {
      this.event = new EventModel(response);
      return this.event;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async onUpdate() {
    this.loading = true;

    const json = this.event
    json.url = this.url;
    json.dateInit = this.dateInit;
    json.dateEnd = this.dateEnd;
    json.hourEvent = this.hourEvent;
    json.local = this.local;
    json.active = this.active;
    json.translatables = this.translatables;
    json.ordering = this.ordering;
    if (this.file.uuid === undefined) {
      const responseFile = await UploadAPI.saveFile(this.file, "news");

      if (responseFile?.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, responseFile?.error);
      } else {
        json.file = {
          "@class": "br.com.stoom.clickstarrett.model.ClickFile",
          uuid: responseFile.data.uuid
        }
      }
    } else {
      json.file['@class'] = 'br.com.stoom.clickstarrett.model.ClickFile';
    }
    const data = JSON.stringify(json);
    const response = await EventApi.update(
      this.event.uuid,
      data
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Evento atualizada com sucesso!"
      );
      this.event = new EventModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async onDelete(uuid) {
    this.loading = true;
    const response = await EventApi.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Evento excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  updateTranslatables(value, language, keyName) {

    const objIndex = this.translatables.findIndex(
      (item) =>
        item.language === language &&
        item.keyName === keyName
    );
    if (objIndex !== -1 && value === '') {
      const filter = this.translatables.filter(
        (_, index) => index !== objIndex
      );

      this.translatables = filter;
    } else if (objIndex !== -1) {
      const object = this.translatables.find(
        (item, index) => index === objIndex
      );

      const data = {
        active: object.active,
        deleted: object.deleted,
        created: object.created,
        keyName: object.keyName,
        language: object.language,
        uuid: object.uuid,
        value: value,
      };

      const updateTranslatable = this.translatables.map((item, index) =>
        index === objIndex ? { ...data } : item
      );

      this.translatables = updateTranslatable;
    } else {
      const data = {
        value: value,
        keyName: keyName,
        language: language,
      };

      this.translatables.push(data);
    }
  }

}
export default EventStore;
