export const InputTypes = {
  TEXT: "input-text",
  PASSWORD: "input-password",
  CEP: "input-cep",
  CNPJ: "input-cnpj",
  CPF: "input-cpf",
  CPFCNPJ: "input-cpf-cnpj",
  DATE: "input-date",
  DECIMAL: "input-decimal",
  EMAIL: "input-email",
  INT: "input-int",
  NUMBER: "input-number",
  TIME: "input-time",
  INFO: "input-info",
  CHECKBOX: "input-checkbox",
  TOOGLE: "input-toogle",
  CLICKABLETEXT: "input-clickable-text",
  BUTTON: "input-button",
  DROPZONE: "input-dropzone",
  DROPZONESLOTABLE: "input-dropzone-slotable",
  TREE: "input-tree",
  SELECT: "input-select",
  ASYNCSELECT: "input-async-select",
  TEXTAREA: "input-text-area",
  BONDINGTABLE: "input-bonding-table",
  TABLE: "input-table",
  SELECT_BRANCHES: "input-select-branches",
  SELECT_BANNERS: "input-select-banners",
  SELECT_CATEGORY: "input-select-category",
  SELECT_COLOR: "input-select-color",
  SELECT_SIZE: "input-select-size",
  SELECT_VALUE: "input-select-value",
  PHONE: "input-phone",
  COORDINATE: "input-coordinate",
  HTML: "input-html",
  HEXCODECOLOR: "input-hexcode-color",
  SELECT_ALTERNATIVE: "input-select-alternative",
  EDITOR_TEXT: "input-editor-text",
};
