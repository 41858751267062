import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class RecommendedProductsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.clickstarrett.model.ClickProduct";
    this.name = data.name;
    this.skuCode = data.skuCode;
    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];
  }
}

export default RecommendedProductsModel;
