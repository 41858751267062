import React, { forwardRef } from 'react';
import InputComponent from '../InputComponent';
import { cryptoPass } from '~/helpers/utils/Functions';

/**Input password */
const InputPasswordComponent = forwardRef((props, ref) => {
  const {
    onBlur,
    minLength = '6',
    autoComplete = 'off',
    maxLength = '255',
    name = 'password',
    id = 'password',
    infoPassword,
    ...otherProps
  } = props;
  /**Criptografa a senha */
  const handlePassword = (e) => {
    cryptoPass(e.target.value).then((crypto) => onBlur && onBlur(e, crypto));
  };
  return (
    <InputComponent
      onBlur={handlePassword}
      autoComplete={autoComplete}
      maxLength={maxLength}
      type="password"
      name={name}
      id={id}
      minLength={minLength}
      required
      ref={ref}
      infoPassword={infoPassword}
      enabledEye={"enabled"}
      {...otherProps}
    />
  );
});

export default InputPasswordComponent;
