import BaseModel from './BaseModel';

export default class VideoModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.ordering = data.ordering;
    this.language = data.language;
    this.videos = data.videos || [];
  }
}