import { extendObservable } from "mobx";
import OrderAPI from "../services/OrderAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { ORDER_STATUS } from "../models/order/OrderStatusModel";
import OrderItemModel from "~/models/order/OrderItemModel";
import RecomendedItemModel from "../models/order/RecomendedItemModel";
import ShippingDetails from "../models/ShippingDetails";
import OrderModel from "../models/order/OrderModel";

class OrderStore {
  totalPages = 0;
  page = 0;
  size = 20;
  filterStatus = undefined;
  filterStaff = undefined;
  userUuid = undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      loading: false,
      order: undefined,
      orders: [],
      filters: {},
      status: undefined,
    });
  }

  get merchant() {
    return this.rootStore.usersStore.userMerchant;
  }

  /**Limpa dados de pedidos */
  reset() {
    this.order = undefined;
    this.orders = [];
    this.totalPages = 0;
    this.page = 0;
    this.userUuid = undefined;
  }

  /**Retorna instancia da notification store */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna pedido do usuario */
  async get(orderUuid, userUuid = this.userUuid) {
    this.loading = true;
    this.userUuid = userUuid;
    const response = await OrderAPI.getOrderUser(orderUuid, this.userUuid);
    if (!response.error) {
      this.order = new OrderModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, statusValue, sort) {
    this.page = numPage;
    this.size = size;

    sort
      ? await this.getList(sort, size, statusValue)
      : await this.getList("created,desc", size, statusValue);

    return true;
  }

  async getOrderByStaff(uuidStaff) {
    this.filterStaff = uuidStaff;

    await this.getList(this.filterStatus);
    this.loading = true;
    if (uuidStaff !== "ALL")
      this.preOrders = this.preOrders.filter(
        (pO) =>
          pO.customer.administrator &&
          pO.customer.administrator.uuid === uuidStaff
      );
    this.loading = false;
  }

  async getListByStatus(statusName, sort) {
    this.filterStatus = statusName;

    sort
      ? await this.getList(sort, this.size, statusName)
      : await this.getList("created,desc", this.size, statusName);
  }

  async getListByAdmin(
    uuidAdmin = "",
    statusValue,
    sort = "created,desc",
    size = this.size,
    page,
    filterType = "",
    filter = "",
    tab,
    fromDate = null,
    toDate = null,
    numberOrder,
    numberErp,
    nameStatus,
    statusName,
    statustype
  ) {
    this.loading = true;
    let params;
    let searchParams;
    let dateRange;
    let filterDefault = false;

    // Verifique se existe um filtro diferente do status_name e formata os parametros a requisição

    if (this.filters.status) {
      filterType
        ? (searchParams = `status_statusName:${this.filters.status}`)
        : (searchParams = `status_statusName:${this.filters.status}`);

      toDate
        ? (dateRange = `created>${fromDate},created<${toDate},`)
        : (dateRange = `created:${fromDate},`);
    }

    if (!filterType || !statusValue) {
      filterDefault = true;
    }

    if (
      fromDate &&
      !statusName &&
      nameStatus !== "idOrder" &&
      nameStatus !== "externalid"
    ) {
      if (fromDate) {
        filterDefault = false;
        toDate
          ? (dateRange = `created>${fromDate},created<${toDate},`)
          : (dateRange = `created:${fromDate}`);
      } else {
        dateRange = `created<${toDate}`;
      }
    }

    // Verifica se existe se existe data para formatar o valor *search* dos parametros
    if (dateRange) {
      params = {
        search: dateRange + (searchParams ? searchParams : []),
        page: page ? page : tab ? 0 : this.page,
        size,
        sort,
      };
    } else {
      params = {
        search: searchParams,
        page: page ? page : tab ? 0 : this.page,
        size,
        sort,
      };
    }

    const response = await OrderAPI.list(params, filterDefault);

    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;

      let listOrder = response.content.map((order) => new OrderModel(order));
      this.orders = listOrder;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Busca lista de pedidos em analise. */
  async listAnalise(sort = "created", size = 20) {
    return await this.getList(sort, size, ORDER_STATUS.ANALYSIS);
  }

  /**troca texto observação orderITem*/
  async changeObservationItem(orderUuid, itemUuid, textObservation) {
    this.loading = true;
    const response = await OrderAPI.changeObservationItem(
      orderUuid,
      itemUuid,
      textObservation
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**troca texto observação orderITem*/
  async changeMainObservation(orderUuid, preOrderUuid, textObservation) {
    this.loading = true;
    if (!orderUuid) {
      await this.createOrder(preOrderUuid, undefined, textObservation);
      return true;
    } else {
      const response = await OrderAPI.changeObservation(
        orderUuid,
        textObservation
      );
      if (response.error)
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return false;
    }
  }

  async setShippingOptions(orderUuid, customerUiid, shippingOptionList) {
    let shippingDetails = new ShippingDetails(shippingOptionList);
    this.loading = true;
    const response = await OrderAPI.setShippingOptions(
      orderUuid,
      shippingDetails
    );

    if (!response.error) {
      await this.changeStatusOrder("CHECKOUT", orderUuid, customerUiid);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza quantidade do item */
  updateAmountItem(product, preOrderItem, amount) {
    const item = this.order.orderItems.find(
      (item) =>
        item.product.uuid === product && item.preOrderItem === preOrderItem
    );
    if (!item) return;
    if (amount === 0) {
      this.order = this.order.orderItems.filter(
        (oItem) => !JSON.stringify(item) === JSON.stringify(oItem)
      );
    }
    item.amount = amount;
  }

  /**Cria novo pedido */
  async addNewOrderItem(
    preOrderUuid,
    product,
    preOrderItem,
    orderUuid,
    customerUuid
  ) {
    this.loading = true;
    const orderItem = new OrderItemModel({
      product,
      amount: 1,
      preOrderItem,
    });
    if (!orderUuid) return await this.createOrder(preOrderUuid, orderItem);
    else return await this.addProduct(orderItem, customerUuid, orderUuid);
  }

  /**Adiciona produto ao carrinho... */
  async addProduct(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.addItems(customerUuid, orderUuid, [item]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria pedido à partir de um preOrder */
  async createOrder(preOrderUuid, orderItem, observation) {
    this.loading = true;
    const euroOrder = orderItem
      ? new OrderModel({
          orderItems: [orderItem],
          merchant: this.merchant,
        })
      : new OrderModel({
          orderItems: [],
          merchant: this.merchant,
          observation,
        });

    const response = await OrderAPI.create(preOrderUuid, euroOrder);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria novo item recomendado */
  async addProductRecomended(orderUuid, product) {
    this.loading = true;
    const recomendedItem = new RecomendedItemModel({
      product: { uuid: product.uuid },
      order: { uuid: orderUuid },
    });
    const response = await OrderAPI.addProductRecomended(orderUuid, [
      recomendedItem,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria novo item recomendado */
  async removeProductRecomended(orderUuid, remendedItem) {
    this.loading = true;
    const response = await OrderAPI.removeRecomendedItems(orderUuid, [
      remendedItem,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeObservationRecomended(
    orderUuid,
    recomendedUuid,
    textObservation
  ) {
    this.loading = true;
    const response = await OrderAPI.changeObservationRecomended(
      orderUuid,
      recomendedUuid,
      textObservation
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeImporter(orderUuid, importer) {
    this.loading = true;
    const response = await OrderAPI.changeImporter(orderUuid, importer);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Altera observação de produto recomendado */
  async changeAmountRecomended(orderUuid, recomendedUuid, amount) {
    this.loading = true;
    const response = await OrderAPI.changeAmountRecomended(
      orderUuid,
      recomendedUuid,
      amount
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getListByAdmin(filters.from, filters.to);
  }

  /**Busca lista de pedidos */
  async getList(from, to) {
    this.loading = true;
    const params = {
      status: "CART;NEW;ANALYSIS;CANCELED",
      from: from,
      to: to,
    };

    const filterDefault = true;
    const response = await OrderAPI.list(params, filterDefault);
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      let listOrder = response.content
        .map((order) => new OrderModel(order))
        .filter((order) => order.status.statusName !== ORDER_STATUS.CART);
      this.orders = listOrder;
    }
    this.loading = false;
    return response;
  }

  /**Altera status do pedido(carrinho) atual */
  async changeStatusOrder(newStatus, orderUuid, customerUuid) {
    this.loading = true;
    const response = await OrderAPI.changeOrderStatus(customerUuid, orderUuid, {
      statusName: newStatus,
    });
    if (!response.error) {
      this.order = new OrderModel(response);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Resposta enviada ao cliente"
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Remove produto do carrinho...*/
  async updateOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.addItems(customerUuid, orderUuid, [item]);
    this.loading = false;
    return response;
  }

  /**Remove produto do carrinho...*/
  async removeOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.removeItems(customerUuid, orderUuid, [
      item,
    ]);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }
}

export default OrderStore;
