import React, { forwardRef, useEffect, useState } from 'react';
import InputComponent from '../InputComponent';
import { formatNumbers } from '~/helpers/utils/Functions';

const InputNumbersComponent = forwardRef((props, ref) => {
  const {
    prefix = '',
    defaultValue,
    onChange,
    useMask = true,
    ...otherProps
  } = props;

  const [initValue, setInitValue] = useState('');
  useEffect(() => {
    if (defaultValue) {
      setInitValue(
        useMask ? formatNumbers(defaultValue, prefix) : defaultValue
      );
    } else {
      setInitValue('');
    }
  }, [defaultValue, useMask, prefix]);
  /**Formata moeda */
  const formatDecimal = (e) => {
    const value = e.target.value;
    if (!value) e.target.value = '';
    if (useMask) e.target.value = formatNumbers(e.target.value, prefix);
    setInitValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <InputComponent
      value={initValue}
      onChange={formatDecimal}
      ref={ref}
      {...otherProps}
    />
  );
});

export default InputNumbersComponent;
