import { inject, observer } from "mobx-react";
import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";

const InputHTMLComponent = (props) => {
  const {
    name = "htmlCode",
    id = "htmlCode",
    required,
    control,
    defaultValue,
    onChange,
    value,
    theme = "github",
  } = props;

  const htmlChange = (e) => {
    onChange && onChange(e);
  };

  return (
    <AceEditor
      mode="html"
      theme={theme}
      name={name}
      width="100%"
      onChange={(value) => htmlChange(value)}
      fontSize={12}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={true}
      defaultValue={defaultValue}
      value={value}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 1,
      }}
    />
  );
};

export default InputHTMLComponent;
