import React from 'react';

/**
 * @Name: Título Padrão
 * @Data: 2019
 * @Desc: O Título Padrão do Sistema, nele cabe um subtitulo.
 * @props:
 *
 */

const TitlePageComponent = (props) => {
  // Recebe as propriedades
  const {
    title,
    subtitle,
    iconClass,
    buttonTitle,
    type: pageTitle = false,
    clickButton,
    clickIcon,
  } = props;

  return (
    <React.Fragment>
      <div className={`TitlePageComponent ${pageTitle ? 'page-title' : ''}`}>
        <h1 data-cy="title-page">
          <i className={iconClass} onClick={clickIcon}></i>
          {title}
        </h1>

        {buttonTitle && clickButton && (
          <button data-cy="button-route" onClick={clickButton.click}>{buttonTitle}</button>
        )}

        {subtitle && <p>{subtitle}</p>}
      </div>
    </React.Fragment>
  );
};

export default TitlePageComponent;
