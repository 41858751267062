import React from "react";
import ActionIconComponent from "../ActionIconComponent/ActionIconComponent";
import LoadingComponent2 from "../LoadingComponent2/LoadingComponent2";
import TitleComponent from "../TitleComponent/TitleComponent";
import CardPainelEmptyComponent from "./CardPainelEmptyComponent/CardPainelEmptyComponent";

/**
 * @Name: CardPainelComponent
 * @Data: 2019
 * @Desc: Os cards dos paineis de controle.
 * @props:
 *
 */

const CardPainelComponent = (props) => {
  // Recebe as propriedades
  const {
    title,
    type,
    subtitle,
    // large,
    children,
    iconList,
    id,
    iconNew,
    iconDelete,
    className,
    loading = false,
    isOpen = false,
    iconAccordion,
    // small,
  } = props;
  // const colLg = !large && !small && "col-lg-6";
  return (
    <>
      {children ? (
        <div
          className={`${className ? className : ""} card-manager ${
            loading && "pointer-none"
          }`}
        >
          {iconList && (
            <ActionIconComponent
              icon="fas fa-list-ul"
              onClick={iconList.click}
            />
          )}
          {iconNew && (
            <ActionIconComponent
              icon="fas fa-plus-circle"
              onClick={iconNew.click}
            />
          )}
          {iconDelete && (
            <ActionIconComponent
              icon="fas fa-trash"
              onClick={iconDelete.click}
            />
          )}
          {iconAccordion && (
            <ActionIconComponent
              icon={`fas fa-${isOpen ? "chevron-up" : "chevron-down"}`}
              onClick={iconAccordion.click}
              id={id}
            />
          )}
          {title && (
            <TitleComponent
              type={type}
              title={title}
              subtitle={!loading ? subtitle : "Carregando..."}
            />
          )}

          {loading && <LoadingComponent2 style={{}} />}
          {!loading && children}
        </div>
      ) : (
        <CardPainelEmptyComponent />
      )}{" "}
    </>
  );
};

export default CardPainelComponent;
