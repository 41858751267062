import BaseAPI, { URLS } from "./BaseAPI";

class ProductAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que cria novo produto.
   * @param  {Object} product - Objeto do produto que será criado
   */
  static async save(product) {
    try {
      const response = await BaseAPI.post(URLS.PRODUCTS, product);
      if (response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o produto" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o produto");
    }
  }

  /**
   * Request que desabilita uma variação de um modelo.
   * @param  {string} productUuid - identificador do produto (nesse caso a variação)
   */
  static async disableVariation(productUuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PRODUCTS}/${productUuid}/deactivate`
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao desabilitar a variação" };
    } catch (e) {
      return this._exception(e, "Falha ao desabilitar a variação");
    }
  }

  /**
   * Request que habilita uma variação de um modelo.
   * @param  {string} productUuid - identificador do produto (nesse caso a variação)
   */
  static async activateVariation(productUuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.PRODUCTS}/${productUuid}/activate`
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao habilitar a variação" };
    } catch (e) {
      return this._exception(e, "Falha ao habilitar a variação");
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async list(params = {}, type) {
    try {
      if (!!params?.sort) {
        params.sort = params.sort.replace(/[\s-]/g, '_').replace(/(.)(?=[A-Z])/g, '$1_').toLowerCase();
      }
      const response = await BaseAPI.get(`/manager/products/search/full`, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async getProductBySku(params = {}) {
    try {
      const response = await BaseAPI.get("/manager/products/stocks", params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }

  /** Retorna categories de um produto  */
  /**
   * @param  {string} uuid - Uuid do produto que quer as categorias
   */
  static async getCategories(produtUuid) {
    try {
      const url = `manager/products/${produtUuid}/categories`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Produto naõ encontrado"
            : "Produto não está em nenhuma categoria"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Produto sem categorias");
    }
  }

  /** Retorna categories de um produto  */
  /**
   * @param  {string} uuid - Uuid do produto que quer as categorias
   */
  static async getVariations(produtUuid) {
    try {
      const url = `${URLS.PRODUCTS}/${produtUuid}/variations`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Produto naõ encontrado"
            : "Produto não está em nenhuma categoria"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Produto sem categorias");
    }
  }

  /**
   * Request que retorna variações disponíveis filtradas de acordo com o term
   * @param {string} name nome do produto
   * @GetMapping("/products/products/variations?name=engrenagem")
   */
  static async getAvailableProductVariations(filter) {
    try {
      const url = `${URLS.PRODUCTS}/filters`;
      const response = await BaseAPI.get(url, { filter });
      if (response.status === 200) return response.data;

      return { error: "Falha ao retornar as variações disponiveis" };
    } catch (e) {
      return this._exception(e, "Falha ao retornar as variações disponiveis");
    }
  }

  /** Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop - propriedade da busca (padrão uuid)
   * @param  {valor} value - valor a res pesquisado.
   */
  static async get(prop, value) {
    try {
      const url = `/manager/products/${value}/`;
      const response = await BaseAPI.get(url, { by: prop });
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Produto naõ encontrado"
            : "falha ao buscar produto"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Falha ao buscar produto");
    }
  }

  /**
   * Busca imagem do produto pelas metatags e products
   * @param  {string} productUuid -Uuid do produto
   * @param  {Array} metaTags - tags do produto.
   */
  static async getFile(productUuid, metaTags) {
    try {
      const array = Array.isArray(metaTags) ? metaTags : [metaTags];
      const url = `products/${productUuid}/${URLS.FILES}/`;
      const response = await BaseAPI.get(url, array);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Produto naõ encontrado"
            : "falha ao buscar imagem do produto"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Falha ao buscar produto");
    }
  }

  /**
   * Request que lista Produtos
   * @param {string} productUuid Uuid do produto
   * @param {string} princingUuid Princing que será removido do produto
   * delete /manager/products/<uuid>/pricing/<uuid>
   */
  static async removeProcuctPricing(productUuid, princingUuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.PRODUCTS}/${productUuid}/pricing/${princingUuid}`
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao vincular grupo com produto" };
    } catch (e) {
      return this._exception(e, "Falha ao vincular grupo com produto");
    }
  }

  /**
   * Request que lista Produtos
   * @param {string} productUuid Uuid do produto
   * @param {ProductPropertyModel} group Grupo que contém contém o princing do produto
   * @PostMapping("/products/{uuid}/pricing")
   */
  static async addProcuctPricing(productUuid, group) {
    try {
      const url = `${URLS.PRODUCTS}/${productUuid}/pricing`;
      const response = await BaseAPI.post(url, group);
      if (response.status === 200) return response.data;
      return { error: "Falha ao vincular grupo com produto" };
    } catch (e) {
      return this._exception(e, "Falha ao vincular grupo com produto");
    }
  }

  /**
   * Request que lista Produtos Modelo
   * @param {string} name nome do produto
   * @GetMapping("/products/products/parents?name=engrenagem")
   */
  static async getParentProducts(name) {
    try {
      const url = `${URLS.PRODUCTS}/parents`;
      const response = await BaseAPI.get(url, { name });
      if (response.status === 200) return response.data;

      return { error: "Falha ao vincular grupo com produto" };
    } catch (e) {
      return this._exception(e, "Falha ao vincular grupo com produto");
    }
  }

  /**
   * Request que lista Produtos pelo tipo (MODEL, VARIATION, PART)
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async listByType(params = {}, productType) {
    try {
      const url = `${URLS.PRODUCTS}/type/${productType}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }

  /**
   * Request que lista Produtos pelo tipo (MODEL, VARIATION, PART)
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async exportCSVByType(params = {}, productType) {
    try {
      const url = `${URLS.PRODUCTS}/export-csv/${productType}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao exportar CSV de produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao exportar CSV de produtos");
    }
  }

  /**
   * Atualizada campos de um produto.
   * @param  {Object} params
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.PRODUCTS}/${uuid}`, data);
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao atualizar produto" };
    } catch (e) {
      return this._exception(e, "Erro ao atualizar produto");
    }
  }

  /**
   * Deleta produto.
   * @param  {string} uuid
   */
  static async delete(uuid) {
    try {
      const url = `${URLS.PRODUCTS}/${uuid}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return {
        error:
          "Não foi possível deletar o produto! Verifique se ele está vinculado a categorias! Ou possui vendas.",
      };
    } catch (e) {
      return this._exception(
        e,
        "Não foi possível deletar o produto! Verifique se ele está vinculado a categorias! Ou possui vendas."
      );
    }
  }

  static async getPordOrigens() {
    try {
      const url = "/manager/productOrigin";
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Produto naõ encontrado"
            : "Produto não está em nenhuma categoria"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Produto sem categorias");
    }
  }

  static async getRatings(params) {
    try {
      const url = "/manager/products/evaluations";
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "comentarios naõ encontrado"
            : "Nenhum comentario encontrado"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Produto sem categorias");
    }
  }

  static async updateStatusComment(el, uuid, status) {
    try {
      const url = `/manager/products/evaluations/${uuid}/status/${status}`;

      const response = await BaseAPI.patch(url, el);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Falha ao atualizar commentario"
            : "Falha ao atualizar commentario"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Produto sem categorias");
    }
  }

  static async importImages() {
    try {
      const url = "/manager/insumosHolambra-products/run-import-images-thread";
      const response = await BaseAPI.post(url);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Erro ao importar imagens"
            : "Erro ao importar imagens"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Erro ao importar imagens");
    }
  }

  /**
   * Request que lista as variações com params
   * @param {string} name nome do produto
   * @param {Object} params objeto com os parametros
   */
  static async getProductVariationsWithParams(params = {}) {
    try {
      const url = `${URLS.PRODUCTS}/type/VARIATION`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }

  /**
   * Request que lista os modelos com params
   * @param {string} name nome do produto
   * @param {Object} params objeto com os parametros
   */
  static async getProductModelsWithParams(params = {}) {
    try {
      const url = `${URLS.PRODUCTS}/type/MODEL`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }

  /**
   * Request que lista as variações
   * @param {string} name nome do produto
   */
  static async getProductVariations(name) {
    try {
      const url = `${URLS.PRODUCTS}/type/VARIATION/${
        name ? `?search=name:*${name}*` : ""
      }`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar produtos" };
    } catch (e) {
      return this._exception(e, "Falha ao listar produtos");
    }
  }
}

export default ProductAPI;
