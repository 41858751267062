import { action, decorate, extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { currencyToNumber, onlyUnique } from "~/helpers/utils/Functions";
import CategoryModel from "~/models/CategoryModel";
import ProductModel from "~/models/ProductModel";
import FileModel from "~/models/FileModel";
import { ProductTypeEnum } from "~/helpers/utils/enums/ProductTypeEnum";

import SupplierModel from "~/models/SupplierModel";
import CategoryAPI from "../services/CategoryAPI";
import ProductAPI from "../services/ProductAPI";
import UploadAPI from "../services/UploadAPI";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  product: undefined,
  products: [],
  properties: [],
  classes: [],
  pricings: [],
  getFiles: [],
  applications: [],
  suppliers: undefined,
  tabType: ProductTypeEnum.MODEL,
  prodOrigens: [],
  deleteFileList: [],
  uploadNewImage: [],
};
class ProductStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = "created,desc";
  filter = "";
  name = "";
  skuCode = "";
  language = "ptBR";
  categoriesRemove = [];
  categoriesAdd = [];
  variations = [];
  princingsRemove = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.product = null;
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 10;
    this.sort = "created,desc";
    this.filter = "";
    this.term = "";
    this.categoriesRemove = [];
    this.categoriesAdd = [];
    this.variations = [];
    this.princingsRemove = [];
    this.applications = [];
    this.deleteFileList = [];
    this.uploadNewImage = [];
    // this.propertiesStore.reset();
  }

  resetListing() {
    this.page = 0;
  }

  /**Métodos referente a versão com variação */

  /**Finalizou os métodos referente a versão com variação */

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna instancia da propertiesStore */
  get propertiesStore() {
    // return this.rootStore.propertiesStore;
  }

  /**Retorna o merchant do usuário atual */
  get merchant() {
    return this.rootStore.usersStore.user.merchant;
  }

  getCustomerType(customerType) {
    return this.rootStore.customerTypeStore.getCustomerType(customerType);
  }

  createNewPrices() {
    var customerType = this.getCustomerType("F/J");
    let price = {
      price: 0,
      customerType: customerType,
    };

    return [price];
  }

  createNewStock() {
    // var customerType = this.getCustomerType('F/J');
    let stock = { total: 0 };

    return [stock];
  }

  /**Cria novo produto */
  createEmptyProduct(isParent) {
    this.product = undefined;
    this.product = new ProductModel();
    this.product.active = false;
    this.product.parent = isParent;
  }

  /**Atualiza propriedades do produto. */
  async onChange(prop, value) {
    const product = this.product;
    switch (prop) {
      case "name":
        product.name = value;
        if (!product.shortName) product.shortName = value;
        break;
      case "baseProduct":
        product.baseProduct = { uuid: value.value };
        break;
      case "color":
        product.color = { uuid: value.value };
        break;
      case "size":
        product.size = { uuid: value.value };
        break;
      case "description":
        product.description = value;
        if (!product.shortDescription || product.shortDescription.length < 20)
          product.shortDescription = value;
        break;
      case "fakePrice":
        product.fakePrice = currencyToNumber(value);
        if (!product.price) product.price = currencyToNumber(value);
        break;
      case "size":
        product.size = { uuid: value.value };
        break;
      case "supplier":
        product.setSupplier({
          uuid: value.value,
        });
        break;
      case "prices":
        if (product.prices.length > 0) {
          product.prices[0].price = currencyToNumber(value);
        } else if (product.prices.length === 0) {
          product.prices.push({
            price: currencyToNumber(value),
            customerType:
              this.rootStore.customerTypeStore.getDefaultCustomerType(),
          });
        }
        break;
      case "stocks":
        if (product.stock) {
          product.stocks.total = currencyToNumber(value);
        } else if (product.stocks.length === 0) {
          product.stocks.push({
            total: currencyToNumber(value),
            customerType:
              this.rootStore.customerTypeStore.getDefaultCustomerType(),
          });
        }
        break;
      case "absoluteDiscount":
      case "quantityStockEmail":
      case "percentualDiscount":
      case "weight":
      case "height":
      case "width":
      case "length":
      case "shippingWeight":
      case "shippingHeight":
      case "shippingWidth":
      case "shippingLength":
      case "committedStock":
      case "securityStock":
      case "deliveryTime":
        const number = currencyToNumber(value);
        number > 0 ? (product[prop] = number) : (product[prop] = 0);
        break;
      default:
        product[prop] = value;
    }
    this.product = new ProductModel(product);
  }

  updateTranslatables(value, language) {
    const objIndex = this.product.translatables.findIndex(
      (item) =>
        item.language === language &&
        item.keyName === "PRODUCT_LONG_DESCRIPTION"
    );

    if (objIndex !== -1) {
      const object = this.product.translatables.find(
        (item, index) => index === objIndex
      );

      const data = {
        active: object.active,
        deleted: object.deleted,
        created: object.created,
        keyName: object.keyName,
        language: object.language,
        uuid: object.uuid,
        value: value,
      };

      const updateTranslatable = this.product.translatables.map((item, index) =>
        index === objIndex ? { ...data } : item
      );

      this.product.translatables = updateTranslatable;
    } else {
      const data = {
        value: value,
        keyName: "PRODUCT_LONG_DESCRIPTION",
        language: language,
      };
      this.product.translatables.push(data);
    }
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.product.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.product.categories.some((mCat) => mCat.uuid === cat.uuid)
    );
    const output = [...this.product.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);

    return output;
  }

  addSuppl(data) {
    this.product.supplier = new SupplierModel({
      uuid: data.value,
    });
  }

  removeAppl(uuid) {
    this.product.applications = this.product.applications.filter(
      (m) => m.uuid !== uuid
    );
    this.applications = this.changeToApplicationsSelect(
      this.product.applications
    );
  }

  /**Adiciona imagem ao produto */
  handleProductImages(tag, file) {
    if (!this.product) this.createEmptyProduct();
    const product = this.product;

    const fileItem = product.files.find((file) => file.metaTags[0] === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new FileModel({
        file,
        ...file,
        metaTags: [tag],
        size: 1,
        ordering: product.files.length > 0 ? product.files.length - 1 : 0,
      });
      product.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  handleRemoveImageVariation(index) {
    const product = this.product;
    const files = [];

    product.files.map((f, i) => {
      if (index !== i) {
        files.push(f);
      }
    });

    product.files = files.map((f, i) => ({
      ...f,
      metaTags: i === 0 ? ["card"] : [`carousel-${i}`],
    }));

    this.product = new ProductModel(product);
  }

  async addPricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.addProcuctPricing(
      this.product.uuid,
      propertyGroup
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getParentProducts(name) {
    const search = `name;*${name}*`;
    const response = await ProductAPI.list({ search }, ProductTypeEnum.MODEL);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  /**Vincula princig no produto */
  async removePricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.removeProcuctPricing(
      this.product.uuid,
      propertyGroup.uuid
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort = "created,desc") {
    this.page = numPage;
    this.size = size;
    this.sort = sort;
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;

    await this.getListByType(this.tabType);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getListByType(this.tabType);
  }

  /**Salva um novo produto */
  async onCreate() {
    this.loading = true;
    const files = [...this.product.files];
    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    this.product.cubage = parseFloat(this.product?.cubage?.replace(",", "."));
    const data = this.product;
    delete data.files;
    data.merchant = this.merchant;
    let response = await ProductAPI.save(data);
    if (!response.error) {
      const product = new ProductModel(response.data);
      this.product = product;
      if (this.product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }

      this.sendNewFiles(files, product.uuid);
      this.createEmptyProduct();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Produto Cadastrado");
    } else {
      this.product.files = files;
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Não foi possível cadastrar o produto"
      );
    }
    this.loading = false;
    return response;
  }

  /**Envia propriedades */
  async handlePricings(productUuid) {
    const groups = [];
    this.propertiesStore.classesSelecteds.map((cls) =>
      groups.push(...cls.groups)
    );

    //Pega todos os princings ativos ( > 0)
    const arrayAdd = [];
    let arrayRemoveds = [];

    //Se grupos forem  > 0 verifica o que é novo e o que foi removido.
    if (groups.length > 0) {
      groups.map((pricing) => {
        if (pricing.priceIncrease > 0) arrayAdd.push(pricing);
        else if (pricing.uuid && pricing.priceIncrease <= 0)
          arrayRemoveds.push(pricing);
        return pricing;
      });
      //Verifica variações que foram removidas.
      arrayRemoveds = [
        ...arrayRemoveds,
        ...this.variations.filter(
          (variation) => !groups.some((group) => group.uuid === variation.uuid)
        ),
      ];
    }
    //Senão todas as variações foram deletadas.
    else arrayRemoveds = this.variations;
    let promises = [];
    promises.push(
      arrayAdd.map(
        async (property) =>
          await ProductAPI.addProcuctPricing(productUuid, property)
      )
    );
    promises.push(
      arrayRemoveds.map(
        async (pricing) =>
          await ProductAPI.removeProcuctPricing(productUuid, pricing.uuid)
      )
    );

    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Vincula produtos Variacao e produtoBase em categorias. */
  async spreadProductBaseCategoriesToProductVariations() {
    let promises = [];

    let relatedProducts;

    relatedProducts = [this.product, ...this.product.productVariations];

    if (this.categoriesAdd.length > 0) {
      promises = this.categoriesAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(
            category.uuid,
            relatedProducts.map((item) => {
              return { uuid: item.uuid };
            })
          )
      );
    }
    if (this.categoriesRemove.length > 0) {
      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }
    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Vincula cateogrias de produto base para o produto variacao. */
  async setProductBaseCategoriesToVariation() {
    let promises = [];

    const relatedProducts = [this.product];

    const categoriesToRemove = await ProductAPI.getCategories(
      this.product.uuid
    );

    if (categoriesToRemove.length > 0) {
      promises = [
        ...promises,
        categoriesToRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }

    const categoriesToAdd = await ProductAPI.getCategories(
      this.product.baseProduct.uuid
    );

    if (categoriesToAdd.length > 0) {
      promises = categoriesToAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(
            category.uuid,
            relatedProducts.map((item) => {
              return { uuid: item.uuid };
            })
          )
      );
    }

    promises.length > 0 &&
      (await this.handlePromises(promises, "Falha ao desvincular categorias"));
  }

  /**Busca todos os produtos */
  async getList(data = {}) {
    this.loading = true;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      ...data,
    };

    const response = await ProductAPI.list(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos pelo tipo*/
  async getListByType(productType) {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      search: this.filter,
      term: this.term,
      language: this.language,
      name: this.name,
      skuCode: this.skuCode,
    };

    const response = await ProductAPI.list(params, productType);
    if (!response.error) {
      this.tabType = productType;

      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos pelo tipo*/
  async exportCSVByType(productType) {
    this.loading = true;
    const params = {
      // page: this.page,
      // size: this.size,
      sort: this.sort,
      search: this.filter,
    };

    const response = await ProductAPI.exportCSVByType(params, productType);
    if (!response.error) {
      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getListBySku(skuCode = this.filter) {
    this.loading = true;
    const params = {
      sku: skuCode,
    };
    const response = await ProductAPI.getProductBySku(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza Produto */
  async onUpdate(removeItem) {
    this.loading = true;

    // const categories = this.product.categories;
    // this.product.categories = [];
    delete this.product.merchant;
    // delete this.product.prices;
    // this.product.cubage = isNaN(this.product.cubage)
    //   ? parseFloat(this.product.cubage.replace(',', '.'))
    //   : this.product.cubage;

    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    const files = this.product.files.filter((item) => item.uuid === undefined);
    await this.sendFiles(this.product.uuid, files);
    if (removeItem) {
      this.product.baseProduct = null;
    }

    const response = await ProductAPI.update(this.product.uuid, this.product);
    if (!response.error) {
      this.product = new ProductModel(response.data);
      if (this.product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }
      // await this.handlePricings(this.product.uuid);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Alterações gravadas com sucesso."
      );
    } else {
      this.product.files = files;
      // this.product.categories = categories;

      //this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Deleta produto */
  async onDelete(uuid) {
    this.loading = true;
    const response = await ProductAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Produto deletado");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Desassocia a variação */
  async disableVariation(variationUuid) {
    const response = await ProductAPI.disableVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Variação desabilitada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Desassocia a variação */
  async activateVariation(variationUuid) {
    const response = await ProductAPI.activateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Variação habilitada");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca categorias do produto */
  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new CategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = "uuid") {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);

      this.getFiles = product.files;

      if (product.parent)
        product.categories = await this.getCategories(product.uuid);
      this.product =
        product.prices.length === 0
          ? new ProductModel({
              ...product,
              prices: this.createNewPrices(),
            })
          : new ProductModel(product);

      this.product =
        this.product.stocks.length === 0
          ? new ProductModel({
              ...this.product,
              stocks: this.createNewStock(),
            })
          : new ProductModel(product);

      // this.applications = this.changeToApplicationsSelect(
      //   this.product.applications
      // );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async addingNewFiles(newFiles, productUuid) {
    const filesUuid = await this.sendNewFiles(newFiles, productUuid);
    const filesUuidFilter = filesUuid.map((item) => item.data);
    const productsFiles = this.product.files.filter((item) => item.uuid);
    this.product.files = [...productsFiles];
    filesUuidFilter.map((item) => {
      const newFileItem = new FileModel({
        item,
        ...item,
        metaTags: ["PRODUCT_IMAGE"],
        size: 1,
        idFile: item.idFile,
      });
      this.product.files.push(newFileItem);
    });
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(productUuid, files) {
    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter((file) => file.uuid);

    newFiles.length > 0 && (await this.addingNewFiles(newFiles, productUuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    this.deleteFileList.length > 0 &&
      (await this.deleteFiles(this.deleteFileList));
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFileProduct(file.uuid);
    });
    return await this.handlePromises(promise, "Falha o deletar arquivo");
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      "Falha atualizar arquivos."
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async sendNewFiles(newFiles, productUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      return await UploadAPI.uploadProduct(productUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(products = this.products) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: `${prd.skuCode} - ${prd.name}`,
    }));
  }

  changeToApplicationsSelect(app) {
    return app.map((m) => ({
      value: m.uuid,
      label: m.getNameSelect,
    }));
  }

  async checkCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.checkCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async sendCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.sendCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      "Falha anexar arquivos."
    );
    if (response[1].data.sucess) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Atualizado com sucesso");
    }
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async getCsvProducts() {
    this.loading = true;
    let response = await UploadAPI.getProductsCSV();
    if (!response.error) {
      this.loading = false;
      return response.data;
    }
    this.loading = false;
    return;
  }

  /**Adiciona Propriedade no product */
  addPropertieSelected(propertie) {
    this.product.productProperties.push(propertie);
  }

  /**Remove a propriedade selecionada */
  removePropertieSelected(element) {
    this.loading = true;
    const productProperties = this.product.productProperties.filter(
      (e) => e.uuid !== element.uuid
    );
    this.product.productProperties = productProperties;
    this.loading = false;
  }

  /**Adiciona Parte/Produto no itemsCombo */
  addComboSelected(partProduct) {
    this.product.itemsCombo.push(partProduct);
  }

  /**Remove a Parte/Produto no itemsCombo */
  removeComboSelected(element) {
    this.loading = true;
    const itemsCombo = this.product.itemsCombo.filter(
      (e) => e.product.uuid !== element.product.uuid
    );
    this.product.itemsCombo = itemsCombo;
    this.loading = false;
  }

  //**Valida se o produto possui categoria ativa no cadastro */
  checkProductHasCategory() {
    if (this.product.categories && this.product.categories.length > 0) {
      if (this.categoriesRemove.length === this.product.categories.length) {
        if (this.categoriesAdd.length > 0) {
          return true;
        } else {
          return false;
        }
      } else return true;
    } else if (this.categoriesAdd.length > 0) {
      return true;
    }

    return false;
  }

  async getProdOrigens() {
    const response = await ProductAPI.getPordOrigens();

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Retorna variações filtradas de acordo com o term */
  async getAvailableProductVariations(filter) {
    const response = await ProductAPI.getAvailableProductVariations(filter);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    else return response;
  }

  addProductVariation(product) {
    this.product.productVariations.push(product);
  }

  removeProductVariation(product) {
    this.product.productVariations.filter(
      (variation) => variation.uuid === product.uuid
    );
  }

  async getProductVariations(name) {
    const response = await ProductAPI.getProductVariations(name);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Adiciona imagem ao produto */
  handleAddProductImage(files, uuidProduct) {
    if (!this.product) this.createEmptyProduct();

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      const ordering =
        this.product.files.length > 0 ? this.product.files.length : 0;
      const newFileItem = new FileModel({
        file,
        ...file,
        metaTags: ["PRODUCT_IMAGE"],
        size: 1,
        idFile: ordering,
        ordering,
      });

      if (uuidProduct) {
        this.uploadNewImage.push(newFileItem);
      }
      this.product.files.push(newFileItem);
    }
  }

  handleDeleteProductImage(fileRemove, uuidProduct) {
    if (uuidProduct) {
      this.uploadNewImage = this.uploadNewImage.filter(
        (file) => file !== fileRemove
      );
    }

    this.product.files = this.product.files.filter(
      (file) => file !== fileRemove
    );

    if (fileRemove?.uuid) this.deleteFileList.push(fileRemove);
    return this.product.files;
  }

  setProductFiles(files) {
    this.product.files = files;
  }
}

export default ProductStore;

decorate(ProductStore, {
  getList: action,
  createEmptyProduct: action,
});
