import React, { forwardRef, useEffect, useState } from "react";
import { validateCPF } from "~/helpers/utils/Validators";
import { maskCPF } from "~/helpers/utils/Masks";
import InputComponent from "../InputComponent";
import { onlyNumbers } from "~/helpers/utils/Functions";
import BlockErrorComponent from "../../BlockErrorComponent/BlockErrorComponent";

/**
 * CPF Component
 *  */
const InputCPFComponent = forwardRef((props, ref) => {
  const {
    name = "cpf",
    id = "cpf",
    mask = maskCPF,
    onChange,
    onBlur,
    defaultValue,
    ...otherProps
  } = props;
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const { isValid } = validateCPF(defaultValue);
      setStatus(isValid);
    }
  }, [defaultValue]);

  /** Devolve apenas numeros */
  const handleChange = (e) => {
    if (!onChange) return;
    const value = onlyNumbers(e.target.value);
    onChange(e, value);
  };

  /** Verifica se é um CPF válido */
  const checkCPF = (e) => {
    const { isValid } = validateCPF(e.target.value);
    setStatus(isValid);
    if (onBlur) onBlur(e, onlyNumbers(e.target.value));
  };

  return (
    <React.Fragment>
      <InputComponent
        onChange={handleChange}
        mask={mask}
        name={name}
        id={id}
        onBlur={checkCPF}
        isValid={status}
        defaultValue={defaultValue}
        type="text"
        minLength="14"
        maxLength="14"
        required
        ref={ref}
        {...otherProps}
      />

      <BlockErrorComponent visible={!status} message="CPF inválido" />
    </React.Fragment>
  );
});

export default InputCPFComponent;
