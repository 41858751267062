import React, { useState, useEffect, forwardRef } from 'react';

const InputTextAreaComponent = forwardRef((props, ref) => {
  const {
    onChange,
    className = 'text-area-component',
    name,
    id,
    rows,
    defaultValue,
    placeholder,
    disabled = false,
    maxlength = '5000',
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // const handleChange = (e) => {
  //   e.stopPropagation();
  //   setValue(e.target.value);
  //   onChange(e.target.value);
  // };

  return (
    <textarea
      onChange={onChange}
      className={className}
      name={name}
      id={id}
      rows={rows}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      maxLength={maxlength}
      ref={ref}
    />
  );
});

export default InputTextAreaComponent;
