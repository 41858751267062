import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import CampaignModel from "~/models/CampaignModel";

import {
  DateRestriction,
  PriceRestriction,
  ProductCategoryRestriction,
} from "~/models/RestrictionModel";
import CampaignAPI from "~/services/CampaignAPI";
import { v4 as uuidv4 } from "uuid";

import { isArrayEmpty, onlyUnique } from "~/helpers/utils/Functions";
import CampaignModelsEnum from "~/helpers/utils/enums/CampaignModelsEnum";
import DiscountModel from "../models/Discount";
import { PointOfSaleRestriction } from "../models/RestrictionModel";

const initValues = {
  loading: false,
  totalPages: 0,
  page: 0,
  size: 10,
  // Create Campaign
  typeCampaign: "",
  isAllProducts: false,
  campaign: new CampaignModel({ active: false }),
  infiniteCampaign: false,
  priceRestriction: new PriceRestriction(),
  dateRestriction: new DateRestriction(),
  productCategoryRestriction: new ProductCategoryRestriction(),
  pointOfSaleRestriction: new PointOfSaleRestriction(),
  campaigns: [],
  files: [],
  buyXPayY: {
    x: 0,
    y: 0,
  },
};

/**Store que manipula dados de Montadoras. */
class CampaignStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = "created,desc";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setPage(numPage, size = 10, useLogin = true, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList(useLogin);
    return this.totalPages;
  }

  reset() {
    this.campaign = new CampaignModel();
    this.dateRestriction = new DateRestriction();
    this.campaigns = [];
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.loading = false;
    this.applicationType = undefined;
    this.typeCampaign = "";
  }

  /**Busca lista de campanhas */
  async getList(useLogin = true) {
    if (useLogin) this.loading = true;
    const params = { size: this.size, page: this.page, sort: this.sort };
    const response = await CampaignAPI.getList(params);
    if (useLogin) this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.campaigns = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Função para ativar/inativar campanha no grid */
  async activeCampaign(campaign) {
    const response = await CampaignAPI.switchActiveFlag(campaign.uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        `Campanha ${campaign.active ? "ativada" : "inativada"} com sucesso`
      );
      this.getList();
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      `Erro ao ${campaign.active ? "ativar" : "inativar"} a campanha`
    );
    return false;
  }

  // *********** Criação e Edição de campanha ***************

  /**Função para buscar uma campanha para ser usada da tela de update */
  async getCampaignByUuid(campaignUuid) {
    const response = await CampaignAPI.getCampaignByUuid(campaignUuid);
    if (!response.error) {
      this.campaign = new CampaignModel(response);
      if (!!response?.discounts[0]?.buyXPayY?.x) {
        this.buyXPayY.x = response.discounts[0].buyXPayY.x;
        this.buyXPayY.y = response.discounts[0].buyXPayY.y;
      }
      this.typeCampaign = response?.type;

      if (response?.restrictions && response?.restrictions?.length > 0) {
        this.organizeRestrictions(response?.restrictions);

        const productsRestriction = response.restrictions?.map((restriction) =>
          restriction["@class"].includes("ProductCategoryRestriction")
        );
        if (productsRestriction && productsRestriction?.products?.length > 0) {
          this.productCategoryRestriction.products = [
            ...productsRestriction.products,
          ];
        }
      }

      return this.campaign;
    }

    return false;
  }

  /**Função para entrar nas restricoes e direcionar para o objetos */
  async organizeRestrictions(restrictions) {
    restrictions.map((r) => {
      switch (r["@class"]) {
        case "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction":
          this.productCategoryRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.DateRestriction":
          this.dateRestriction = r;
          if (
            r.maxDate === "2121-05-03T00:00:00.000+0000" ||
            r.maxDate === "2121-05-03T00:00:00.000+00:00"
          ) {
            this.infiniteCampaign = true;
          }
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.PriceRestriction":
          this.priceRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction":
          this.customerRestriction = r;
          break;
        default:
          break;
      }
    });
  }

  /**Função chamada depois de mudar o modelo de campanha */
  selectedModelCampaign(modelSelected) {
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.typeCampaign = modelSelected;
    this.isAllProducts = false;
    this.handleCampaignChange("type", modelSelected);
  }

  /**Função chamada ao setar todos os produtos no aplicável á */
  checkedAllProducts() {
    this.isAllProducts = true;
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = undefined;
  }

  /**Função que atribui o valor do campo no model de campanha */
  handleCampaignChange(prop, value) {
    const { campaign } = this;
    campaign[prop] = value;
    this.campaign = new CampaignModel(campaign);
  }

  /**Função que seta se a campanha é infinita ou não */
  checkedInfiniteCampaign(checked) {
    this.infiniteCampaign = checked;
    if (checked) {
      this.dateRestriction.maxDate = undefined;
    }
  }

  /**Altera o a data da restrição */
  handleChangeDate(prop, value) {
    if (prop === "maxDate") {
      this.dateRestriction.maxDate = value;
    } else {
      this.dateRestriction.minDate = value;
    }
  }

  /**Funcao utilizada para alterar valor da restricao de priceRestriction */
  changePriceRestriction(prop, value) {
    const { priceRestriction } = this;
    priceRestriction[prop] = value;
    this.priceRestriction = priceRestriction;
  }

  /**Salva o file de imagem no bannerFile */
  handleUploadImageBannerFile(file) {
    this.files = file;
  }

  /**Altera as propriedades de desconto */
  handleChangeDiscount(prop, value) {
    const updatedCampaign = { ...this.campaign };

    if (typeof value !== "number") value = value.replace(",", ".");
    if (updatedCampaign.discounts.length > 0)
      updatedCampaign.discounts[0][prop] = value / 100;

    this.campaign = new CampaignModel(updatedCampaign);
  }

  /**Salva uma nova campanha */
  async onSave() {
    this.loading = true;
    const { campaign } = this;
    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = "2121-05-03T00:00:00.000Z";
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);
    campaign.restrictions = [...restrictions];

    campaign.banner = {
      tag: "SLIDESHOW_PRINCIPAL",
      nameTag: campaign.name + uuidv4(),
      categoryUUID: "",
      active: true,
      responsive: false,
    };

    const response = await CampaignAPI.save(campaign);
    this.loading = false;

    if (!response.error) {
      this.campaign = response.data;
      // this.sendNewFiles(this.campaign.banner.uuid, this.campaign);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Campanha adicionada com sucesso!"
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao adicionar a campanha"
    );
    return false;
  }

  /**Funcao para atualizar a campanha */
  async onUpdate() {
    this.loading = true;

    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = "2121-05-03T00:00:00.000Z";
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);
    const { campaign } = this;

    campaign.restrictions = restrictions;

    this.reset();
    const response = await CampaignAPI.update(campaign, campaign.uuid);

    this.loading = false;

    if (!response.error) {
      // Logica para atualizar a imagem se necessario imagem
      if (this.deleteImageUuid) {
        const deleteImage = await this.handleRemoveUpdateImage();

        if (deleteImage) {
          this.sendNewFiles(this.campaign.banner.uuid, this.campaign);
        }
      }
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Campanha atualizada com sucesso!"
      );

      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao atualizar a campanha"
    );
    return false;
  }

  /**Seta as restricoes de acordo com a campanha para usar no post/put */
  setRestrictionsInBody(type) {
    switch (type) {
      case CampaignModelsEnum.VALOR_BASE_COM_X_OFF:
        this.campaign.bean = "Discount_Campaign";
        this.priceRestriction.maxPrice = 9999999999;
        this.priceRestriction.minPrice = parseFloat(
          this.priceRestriction.minPrice
        );

        return [
          this.dateRestriction,
          this.productCategoryRestriction,
          this.priceRestriction,
        ];

      case CampaignModelsEnum.DESCONTO_PERCENTUAL:
        this.campaign.bean = "Discount_Campaign";

        if (this.isAllProducts) {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.avenidaPointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.avenidaPointOfSaleRestriction,
            ];
          }
          return [this.dateRestriction, this.productCategoryRestriction];
        }

      case CampaignModelsEnum.LEVE_X_PAGUE_Y:
        this.campaign.bean = "Avenida_Buy_X_Pay_Y_Campaign";
        if (this.campaign.discounts.length > 0)
          this.campaign.discounts[0].amountPay = parseInt(
            this.campaign.discounts[0].amountPay
          );
        if (this.isAllProducts) {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.pointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.pointOfSaleRestriction,
            ];
          }
          return [this.dateRestriction, this.productCategoryRestriction];
        }

      case CampaignModelsEnum.LEVE_X_PAGUE_Y:
        this.campaign.bean = "Avenida_Buy_X_Pay_Y_Campaign";
        if (this.campaign.discounts.length > 0)
          this.campaign.discounts[0].amountPay = parseInt(
            this.campaign.discounts[0].amountPay
          );
        if (this.isAllProducts) {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.pointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.pointOfSaleRestriction,
            ];
          }
          return [this.dateRestriction, this.productCategoryRestriction];
        }

      case CampaignModelsEnum.X_OFF_Y_UNIDADE:
        this.campaign.bean = "Avenida_Buy_X_Pay_Y_Campaign";

        if (this.campaign.discounts.length > 0)
          this.campaign.discounts[0].amountToPay = 0;

        if (this.isAllProducts) {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.pointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.pointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.pointOfSaleRestriction,
            ];
          }
          return [this.dateRestriction, this.productCategoryRestriction];
        }

      case CampaignModelsEnum.Progressive_Discount:
        this.campaign.bean = "Progressive_Discount_Campaign";
        if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
          return [
            this.dateRestriction,
            this.productCategoryRestriction,
            this.avenidaPointOfSaleRestriction,
          ];
        }

        return [this.dateRestriction, this.productCategoryRestriction];

      default:
        return [];
    }
  }

  /** Upload de novas imagens */
  async sendNewFiles(bannerUuid, campaign) {
    const objectBannerFile = {
      name: campaign.name + uuidv4(),
      ordering: 1,
      promotionUUID: campaign.uuid,
      link: `/campanha/${campaign.uuid}`,
      description: "Descrição",
      subDescription: "Sub-Descrição",
    };

    // const response = await UploadAPI.sendNewFileBanner(
    //   bannerUuid,
    //   objectBannerFile,
    //   this.files
    // );

    // if (!response.error) {
    //   return true;
    // }

    return false;
  }

  /**Funcao para restar o array de restricao de categoria/produto */
  changeApplicationType(isEdit) {
    if (!isEdit) {
      this.productCategoryRestriction.products = [];
      this.productCategoryRestriction.categories = [];
    }
  }

  /**Verificao da application type */
  applicationTypeVerify() {
    let applicationType = false;

    if (
      this.productCategoryRestriction.categories &&
      this?.productCategoryRestriction?.categories?.length > 0
    ) {
      this.productCategoryRestriction.categories[0].type === "VITRINE"
        ? (applicationType = "CATEGORIA")
        : (applicationType = "COLECAO");
    } else if (
      this.productCategoryRestriction.products &&
      this.productCategoryRestriction.products.length > 0
    ) {
      applicationType = "VARIACAO";
    } else {
      applicationType = "VARIACAO";
      this.isAllProducts = true;
    }
    return applicationType;
  }

  /**Seta a categoria que vai receber a campanha */
  setCategory(categories) {
    const selected = categories.map((category) => {
      return {
        uuid: category.uuid,
        categoryName: category.categoryName,
        "@class": category["@class"],
      };
    });

    this.categoriesRemove = this.productCategoryRestriction.categories.filter(
      (cat) => !selected.some((mCat) => mCat.uuid === cat.uuid)
    );

    this.categoriesAdd = selected.filter(
      (cat) =>
        !this.productCategoryRestriction.categories.some(
          (mCat) => mCat.uuid === cat.uuid
        )
    );

    this.productCategoryRestriction.categories = [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    this.productCategoryRestriction.products = [];

    return [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Remove a categoria da campanha */
  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
  }

  /**Função para deletar campanha */
  async deleteCampaign(campaignUuid) {
    const response = await CampaignAPI.delete(campaignUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Campanha deletada com sucesso"
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, "Falha ao deletar a campanha");
  }

  /**Adiciona o produto na lista de restrição */
  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  /**Remove o produto que está na lista de restrição */
  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    this.renderAttribute = !this.renderAttribute;
  }

  /**Funcao utilizada para alterar valor da restricao buyXProductRestriction*/
  changeBuyXPayYRestriction(field, value) {
    if (field === "x&y") {
      this.buyXPayY.x = value;
      this.buyXPayY.y = value - 1;

      const buyXPayY = {
        x: Number(value),
        y: Number(value - 1),
      };
      const discountData = this.campaign.discounts[0]
        ? this.campaign.discounts[0]
        : {};
      this.campaign.discounts[0] = new DiscountModel({
        ...discountData,
        buyXPayY: { ...buyXPayY },
      });
      return;
    }
    this.buyXPayY[field] = value;

    this.campaign.discounts[0] = new DiscountModel({
      productsPercentageDiscount: 1,
      buyXPayY: {
        x: Number(this.buyXPayY.x),
        y: Number(this.buyXPayY.y),
      },
    });
  }

  addProgressiveDiscount() {
    if (this.campaign.discounts) {
      const newCampaign = Object.assign({}, this.campaign, {
        discounts: this.campaign.discounts.concat(new DiscountModel()),
      });
      this.campaign = newCampaign;
    }
  }

  removeProgressiveDiscount(index) {
    if (this.campaign.discounts) {
      const discounts = this.campaign.discounts;

      discounts.splice(index, 1);

      const newCampaign = Object.assign({}, this.campaign, {
        discounts: discounts,
      });

      this.campaign = newCampaign;
    }
  }

  handleChangeProgressiveDiscount(index, prop, value) {
    if (this.campaign.discounts) {
      let discounts = this.campaign.discounts.map(
        (discount) =>
          new DiscountModel({
            ...discount,
            productsPercentageDiscount: discount.productsPercentageDiscount,
          })
      );

      value = value.replace(",", ".");
      if (prop == "productsPercentageDiscount")
        discounts[index][prop] = value / 100;
      else discounts[index][prop] = value;

      const newCampaign = Object.assign({}, this.campaign, {
        discounts: discounts,
      });

      this.campaign = new CampaignModel(newCampaign);
    }
  }
}

export default CampaignStore;
