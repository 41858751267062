import { extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import CatalogosAPI from "../services/CatalogosAPI";
import UploadAPI from "../services/UploadAPI";

import CatalogosModel from "~/models/CatalogosModel";

class CatalogosStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      catalogosList: [],
      catalogoListSelect: undefined,
      catalogo: undefined,
      catalogoFile: {},
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "language,ordering",
      filters: {},
      loading: false,
    });
  }

  reset() {
    this.catalogosList = [];
    this.catalogoListSelect = undefined;
    this.catalogo = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "language,ordering";
    this.loading = false;
  }

  initializeCatalogo() {
    this.catalogo = new CatalogosModel();
    this.catalogoFile = {};
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await CatalogosAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.catalogosList = [];
      return;
    }

    this.catalogosList = response.content.map(
      (catalogo) => new CatalogosModel(catalogo)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    this.page = page;
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async uploadFiles(files) {
    const promisses = files
      .filter((item) => item.attachment)
      .map(async (item) => {
        const reponse = await UploadAPI.saveFile(item.attachment, "catalogo");

        return {
          '@class': 'br.com.stoom.clickstarrett.model.ClickCatalogSubjectFile',
          file: {
            '@class': 'br.com.stoom.clickstarrett.model.ClickFile',
            uuid: reponse?.data?.uuid,
          },
          name: item?.name,
          ordering: item?.ordering,
          url: item?.url,
          downloadLink: item?.downloadLink,
        };
      });

    const responses = await Promise.all(promisses);

    if (responses.filter((el) => el.error).length > 0) {
      await this.roollbackFiles(responses.filter((el) => !el.error));
      throw new Error(
        responses
          .filter((el) => el.error)
          .map((el) => el.error)
          .join("; ")
      );
    }

    return responses;
  }

  async roollbackFiles(files) {
    try {
      const promisses = files.map((file) =>
        UploadAPI.removeFile(file.file.uuid)
      );
      await Promise.all(promisses);
    } catch (error) {
      console.log("roollbackFiles error", error);
    }
  }

  async save() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;
    const filesResponses = await this.uploadFiles(this.catalogo.files);
    const data = JSON.stringify({
      ...this.catalogo,
      files: filesResponses.filter((file) => !file.error),
      merchant,
    });

    const response = await CatalogosAPI.save(data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    }

    this.loading = false;
    return response;
  }

  async update() {
    this.loading = true;

    for (const subject of this.catalogo.subjects) {
      const filesResponses = await this.uploadFiles(subject.files);
      filesResponses.map((file) => subject.files.push(file));
      subject.files = subject.files.filter((item) => item.file);
    }
    const data = JSON.stringify({
      ...this.catalogo,
    });

    const response = await CatalogosAPI.update(this.catalogo.uuid, data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
    }

    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await CatalogosAPI.get(uuid);
    this.loading = false;
    this.catalogo = new CatalogosModel(response);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await CatalogosAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  async addFile() {
    const tempCatalogo = this.catalogo;

    for (let index = 0; index < tempCatalogo?.subjects?.length; index++) {
      const element = tempCatalogo?.subjects[index];

      if (this.catalogoFile?.catalogo_file_area === element.name) {
        element.files.push({
          attachment: this.catalogoFile?.catalogo_file_file,
          area: this.catalogoFile?.catalogo_file_area,
          url: this.catalogoFile?.catalogo_file_link_acess,
          downloadLink: this.catalogoFile?.catalogo_file_link_download,
          name: this.catalogoFile?.catalogo_file_name,
          ordering: this.catalogoFile?.catalogo_file_ordering,
        });
      }
    }

    this.catalogo = new CatalogosModel(tempCatalogo);
    this.catalogoFile = {};
  }

  async removeFile(file) {
    const tempCatalogo = this.catalogo;
      for (let index = 0; index < tempCatalogo?.subjects?.length; index++) {
        const element = tempCatalogo?.subjects[index];
        element.files = element?.files?.filter((item) => item !== file)
      }
      this.catalogo = new CatalogosModel(tempCatalogo);
  }

  onChangeCatalogoFile(prop, value) {
    switch (prop) {
      default: {
        this.catalogoFile[prop] = value;
      }
    }
  }

  onChange(prop, value) {
    const tempCatalogo = this.catalogo;
    switch (prop) {
      case "catalogo_file_file":
      case "catalogo_file_name":
      case "catalogo_file_ordering":
      case "catalogo_file_link_acess":
      case "catalogo_file_link_download":
      case "catalogo_file_area":
        this.onChangeCatalogoFile(prop, value);
        return;
      default:
        tempCatalogo[prop] = value;
    }
    this.catalogo = new CatalogosModel(tempCatalogo);
  }
}

export default CatalogosStore;
