import React, { useState, forwardRef } from "react";
import { maskCEP } from "~/helpers/utils/Masks";
import { yupValidator } from "~/helpers/validators/yupValidator";
import { documentSchema } from "~/helpers/yupSchemas/documentSchema";
import InputComponent from "../InputComponent";
import { Controller } from "react-hook-form";
/**
 * CEP Component
 *  */
const InputCEPComponent = forwardRef((props, ref) => {
  const [error, setError] = useState();

  const validate = async (value) => {
    await yupValidator(setError, documentSchema, {
      postcode: value,
    });
  };

  const {
    name = "cep",
    id = "cep",
    mask = maskCEP,
    required,
    control,
    defaultValue,
    ...otherProps
  } = props;
  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <InputComponent
            {...props}
            mask={mask}
            name={name}
            id={id}
            onKeyUp={(e) => validate(e.target.value)}
            type="text"
            required={required}
            ref={ref}
            {...field}
          />
        )}
      />

      <div>{error}</div>
    </>
  );
});

export default InputCEPComponent;
