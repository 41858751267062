import React, { forwardRef } from 'react';
import ButtonComponent from '../../ButtonComponent';
import TableDinamicComponent from '~/components/TableDinamicComponent/TableDinamicComponent';
import TypeTableEnum from '~/helpers/utils/enums/TypeTableEnum';
import AsyncSelectComponent from '../../AsyncSelectComponent/AsyncSelectComponent';

const InputBondingTableComponent = forwardRef((props, ref) => {
  const {
    columns,
    items,
    isLoading,
    searchMethod,
    sortMethod,
    handleAdd,
    handleRemove,
    handleEdit,
  } = props;

  const itemsWithMethods = [
    ...items,
    ...[
      handleEdit
        ? {
            heading: 'Editar',
            type: TypeTableEnum.ICON,
            icon: 'fa fa-edit',
            action: handleEdit,
          }
        : null,
      handleRemove
        ? {
            heading: 'Deletar',
            type: TypeTableEnum.ICON,
            icon: 'fa fa-trash',
            action: handleRemove,
          }
        : null,
    ].filter((i) => i == null),
  ];

  return (
    <div className="input-bonding-table">
      <section className="search-section-wrapper">
        <div className="async-search-wrapper">
          <AsyncSelectComponent
            findByName={searchMethod}
            isLoading={isLoading}
          />
        </div>
        <div className="col-3 search-button-wrapper">
          <ButtonComponent onClick={handleAdd}>Adicionar</ButtonComponent>
        </div>
      </section>
      <TableDinamicComponent
        columns={columns}
        items={itemsWithMethods}
        onSort={sortMethod}
        initialSort={'name'}
      />
    </div>
  );
});

export default InputBondingTableComponent;
