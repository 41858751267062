import AccountModel from './AccountModel';

class AdministratorModel extends AccountModel {
  constructor(data = {}) {
    super(data);
    //this['@class'] = "br.com.stoom.kernel.model.persistence.Administrator";
  }

  get isSuperAdmin() {
    return this.user.role === 'STOOM' || this.user.role === 'SUPERADMIN';
  }
}

export default AdministratorModel;
