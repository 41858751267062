import BaseModel from './BaseModel';

class OurStoreModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.addressComplement = data.addressComplement;
    this.block = data.block;
    this.city = data.city;
    this.phone = data.phone;
    this.state = data.state;
    this.zipcode = data.zipcode;
    this.country = data.country;
    this.street = data.street;
    this.number = data.number;
    this.channelSalesRepresentative = data.channelSalesRepresentative;
    this.lat = data.lat;
    this.lng = data.lng;
  }
}

export default OurStoreModel;
