export const ModalIdsEnum = Object.freeze({
  DISABLE_VARIATION: "disable-variation",
  DELETE_CUSTOMER: "delete-customer",
  DELETE_MANAGER: "delete-manager",
  DELETE_PROPERTIE: "delete-propertie",
  DELETE_ATTRIBUTE: "delete-attribute",
  DELETE_COMPARATIVE: "delete-comparative",
  DELETE_ATTRIBUTE_ALIAS: "delete-attribute-alias",
  UNLINK_VARIATION: "unlink-variation",
  EDIT_VARIATION_IMAGES: "edit-varation-images",
  DELETE_IMAGE_TREASURE: "delete-image-category",
  PRODUCT_KIT: "product-kit",
  DELETE_ORDINATION_DISCOUNT: "delete-ordination-discount",
  DELETE_CATEGORY_PROPERTIE: "delete-category-propertie",
  DELETE_COMPARATIVE_ITEM: "delete-comparative",
  DELETE_PRODUCT: "delete-product",
  DELETE_PRODUCT_ALTERNATIVE: "delete-product-alternative",
  DELETE_CUSTOMER_GROUP: "delete-group-clientes",
  DELETE_PRODUCT_KIT: "delete-product-kit",
  DELETE_IMAGE_CREATE_BANNER: "delete-image-create-banner",
  DELETE_IMAGE_UPDATE_BANNER: "delete-image-update-banner",
  DELETE_CAMPAIGN: "delete-campaign",
  VIEW_IMAGE_CREATE_BANNER: "view-image-create-banner",
  VIEW_IMAGE_UPDATE_BANNER: "view-image-update-banner",
  DELETE_PROMOTION: "delete-promotion-modal",
  DELETE_SHIPPING_COMPANY: "delete-shipping-company-modal",
  DELETE_ZIP_CODE_RANGE: "delete-zip-code-range-modal",
  DELETE_WEIGHT_RANGE: "delete-weight-range-modal",
  DELETE_ADMIN: "delete-administrator",
  DELETE_BANNERS: "delete-banners",
  DELETE_IMAGE_VARIATION_ASSOCIATED: "delete-imagem-variation-associated",
  REVERSAL_VALUE: "reversal-value-modal",
  IS_ALL_PRODUCTS_CAMPAIGN: "is-all-products-campaign",
  DELETE_COMPONENT_HOME_STORE: "delete-component-home-store",
  VIEW_COMPONENT_HOME_STORE: "view-component-home-store",
  DELETE_COMPONENT_MOBILE_HOME_STORE: "delete-component-mobile-home-store",
  VIEW_COMPONENT_MOBILE_HOME_STORE: "view-component-mobile-home-store",
  DELETE_PRODUCT_HOME_STORE: "delete-product-home-store",
  DELETE_NEWSLETTER: "delete-newsletter-modal",
  INFORMATION_HOME_MODAL: "information-home-modal",
  VARIATION_UNLINK: "variation-unlink",
  DELETE_CUSTOMER: "delete-customer",
  DELETE_BRAND: "delete-brand",
  EDIT_IMAGE_BANNER: 'edit-image-banner',
});
