import { bannerTagsTypes } from "../helpers/utils/Selects";
import BaseModel from "./BaseModel";
import MerchantModel from "./MerchantModel";

class BannerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this["@class"] = "br.com.stoom.clickstarrett.model.ClickBanner";
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.uuid = data.uuid;
    this.nameTag = data.nameTag;
    this.tag = data.tag;
    this.tagLabel = bannerTagsTypes.filter((item) => item?.value === data.tag)[0]?.label ?? data.tag;
    this.responsive = data.responsive;
    this.files = data.files || [];
    if (data?.branch) delete data.branch["@class"];
    this.branch = data.branch;
  }
}

export default BannerModel;
