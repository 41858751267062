import BaseModel from "../BaseModel";
import ProductModel from "../ProductModel";
import OrderModel from "./OrderModel";

class RecomendedItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.eurobrake.model.EuroRecommendedItem";
    this.product = data.product && new ProductModel(data.product);
    this.order = data.order && new OrderModel(data.order);
    this.observation = data.observation || "";
    this.unitPrice = data.unitPrice;
    this.amount = data.amount || 1;
  }
}

export default RecomendedItemModel;
