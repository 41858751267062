import BaseModel from "./BaseModel";

class CompetitorProductsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.clickstarrett.model.ClickProduct";
    this.brand = data.brand;
    this.code = data.code;
    this.uuid = data.uuid;
  }
}

export default CompetitorProductsModel;
