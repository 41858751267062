import BaseModel from './BaseModel';

/**Modelo usuário */
class UserModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.username = data.username;
    this.email = data.email;
    this.password = data.password;
    this.role = data.role;
    this.bypass2fa = false;
  }
}

export default UserModel;
