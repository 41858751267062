import React, { forwardRef } from "react";
import { maskHexCode } from "~/helpers/utils/Masks";
import InputComponent from "../InputComponent";
import { Controller } from "react-hook-form";

/**
 * CPF Component
 *  */
const InputHexCodeColorComponent = forwardRef((props, ref) => {
  const {
    name = "hexCodeColor",
    id = "hexCodeColor",
    mask = maskHexCode,
    required,
    control,
    defaultValue,
  } = props;

  return (
    <React.Fragment>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <InputComponent
            {...props}
            mask={mask}
            name={name}
            id={id}
            type="text"
            required={required}
            ref={ref}
            {...field}
          />
        )}
      />
    </React.Fragment>
  );
});

export default InputHexCodeColorComponent;
