import React from "react"
const FallowUpAndDown = () => {
    return <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.18755 6.1363H10.8124C10.9986 6.1363 11.1598 6.06888 11.2958 5.93391C11.4317 5.79899 11.5 5.63921 11.5 5.45455C11.5 5.26988 11.4318 5.11018 11.2958 4.97506L6.48336 0.202389C6.34739 0.0676123 6.18632 0 6 0C5.81368 0 5.65261 0.0676123 5.51652 0.202389L0.704076 4.97506C0.567988 5.11003 0.5 5.26988 0.5 5.45455C0.5 5.63917 0.567988 5.79899 0.704076 5.93391C0.840314 6.06888 1.00139 6.1363 1.18755 6.1363Z" fill="#DBDBDB"/>
    <path d="M10.8124 8.86328H1.18755C1.00123 8.86328 0.840164 8.93074 0.704076 9.06556C0.567988 9.20052 0.5 9.36026 0.5 9.54493C0.5 9.72959 0.567988 9.88944 0.704076 10.0243L5.51652 14.7969C5.65276 14.9319 5.81383 14.9995 6 14.9995C6.18617 14.9995 6.34739 14.9319 6.48336 14.7969L11.2958 10.0243C11.4317 9.88944 11.5 9.72959 11.5 9.54489C11.5 9.36026 11.4318 9.20052 11.2958 9.06552C11.1598 8.93059 10.9986 8.86328 10.8124 8.86328Z" fill="#DBDBDB"/>
    </svg>
}
const FollowUp = () => {
    return <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.687552 6.1363H10.3124C10.4986 6.1363 10.6598 6.06888 10.7958 5.93391C10.9317 5.79899 11 5.63921 11 5.45455C11 5.26988 10.9318 5.11018 10.7958 4.97506L5.98336 0.202389C5.84739 0.0676123 5.68632 0 5.5 0C5.31368 0 5.15261 0.0676123 5.01652 0.202389L0.204076 4.97506C0.0679876 5.11003 0 5.26988 0 5.45455C0 5.63917 0.0679876 5.79899 0.204076 5.93391C0.340314 6.06888 0.501385 6.1363 0.687552 6.1363Z" fill="#444444"/>
    <path d="M10.3124 8.86328H0.687552C0.501235 8.86328 0.340164 8.93074 0.204076 9.06556C0.0679876 9.20052 0 9.36026 0 9.54493C0 9.72959 0.0679876 9.88944 0.204076 10.0243L5.01652 14.7969C5.15276 14.9319 5.31383 14.9995 5.5 14.9995C5.68617 14.9995 5.84739 14.9319 5.98336 14.7969L10.7958 10.0243C10.9317 9.88944 11 9.72959 11 9.54489C11 9.36026 10.9318 9.20052 10.7958 9.06552C10.6598 8.93059 10.4986 8.86328 10.3124 8.86328Z" fill="#DBDBDB"/>
    </svg>
}
const FollowDown = () => {
    return <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.687552 6.1363H10.3124C10.4986 6.1363 10.6598 6.06888 10.7958 5.93391C10.9317 5.79899 11 5.63921 11 5.45455C11 5.26988 10.9318 5.11018 10.7958 4.97506L5.98336 0.202389C5.84739 0.0676123 5.68632 0 5.5 0C5.31368 0 5.15261 0.0676123 5.01652 0.202389L0.204076 4.97506C0.0679876 5.11003 0 5.26988 0 5.45455C0 5.63917 0.0679876 5.79899 0.204076 5.93391C0.340314 6.06888 0.501385 6.1363 0.687552 6.1363Z" fill="#DBDBDB"/>
    <path d="M10.3124 8.86328H0.687552C0.501235 8.86328 0.340164 8.93074 0.204076 9.06556C0.0679876 9.20052 0 9.36026 0 9.54493C0 9.72959 0.0679876 9.88944 0.204076 10.0243L5.01652 14.7969C5.15276 14.9319 5.31383 14.9995 5.5 14.9995C5.68617 14.9995 5.84739 14.9319 5.98336 14.7969L10.7958 10.0243C10.9317 9.88944 11 9.72959 11 9.54489C11 9.36026 10.9318 9.20052 10.7958 9.06552C10.6598 8.93059 10.4986 8.86328 10.3124 8.86328Z" fill="#444444"/>
    </svg>
}
    
export { FallowUpAndDown, FollowUp, FollowDown }