import BaseAPI, { URLS } from "./BaseAPI";

class EventApi {

    static async create(data) {
        try {
            const response = await BaseAPI.post(`/manager${URLS.EVENT}`, data)
            if (response.status === 201) return response;
            else return { error: 'Falha ao cadastrar Evento' };
        } catch (e) {
            return {
                error: e?.response?.data?.message
                  ? e?.response?.data?.message
                  : 'Falha ao cadastrar Evento',
              };
        }
    }

    static async update(uuid, data) {
        try {
            const response = await BaseAPI.put(`/manager${URLS.EVENT}`, data);
            if (response.status === 200) return response.data;
            else if (response.status === 400) return { error: response.message };
            else return { error: "Falha ao atualizar informações do Evento" };
        } catch (e) {
            return {
                error: e?.response?.data?.message
                  ? e?.response?.data?.message
                  : 'Falha ao atualizar Evento',
              };
        }
    }

    static async delete(uuid) {
        try {
            const response = await BaseAPI.delete(`/manager${URLS.EVENT}/${uuid}`);
            if (response.status === 200 || response.status === 204) return response.data;
            return { error: "Não foi possível excluir o Evento" };
        } catch (e) {
            console.log(e);
            return { error: e.message };
        }
    }

    static async getBy(prop, value) {
        try {
            const url = `${URLS.EVENT}/${value}/`;
            const params = { by: prop };
            const response = await BaseAPI.get(url, params);
            if (response.status === 200) return response.data;
            return { error: 'Falha ao buscar Evento' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    static async getUser(userName) {
        try {
            const url = `${URLS.EVENT}/${userName}`;
            const response = await BaseAPI.get(url);
            if (response.data.status) {
                return { error: 'Falha ao buscar Evento' };
            }
            return response.data;
        } catch (e) {
            console.log(e.message);
            return { error: 'Falha ao buscar Evento' };
        }
    }

    static async getAlls(params) {
        try {
            const response = await BaseAPI.get(`${URLS.EVENT}/filter`, params);
            if (response.status === 200) return response.data;
            return { error: 'Falha ao buscar Evento' };
        } catch (e) {
            console.log(e.message);
            return { error: 'Falha ao buscar Evento' };
        }
    }
}

export default EventApi;