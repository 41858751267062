import BaseModel from './BaseModel';

class PaymentDetailsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.valueToBill = data.valueToBill;
    this.valueToRefund = data.valueToRefund;
    this.gatewayId = data.gatewayId;
    this.creditCard = data.creditCard && new CreditCardModel(data.creditCard);
    this.invoice = data.invoice && new InvoiceModel(data.invoice);
    this.gatewayPaymentStatus = data.gatewayPaymentStatus;
    this.name = data.paymentOptionName;
    this.paymentType = data.paymentType;
    this.installments = data.installments ? data.installments : 1;
  }

  get aliasName() {
    switch (this.paymentType) {
      case 'INVOICE':
        return 'Boleto / Pix';
      case 'BANK_TRANSFER':
        return 'Boleto Faturado';
      case 'CREDIT_CARD':
        return 'Cartão de Crédito';
      default:
        return 'Outros';
    }
  }
}

class CreditCardModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.idCreditCard = data.idCreditCard;
    this.cardCarrier = data.cardCarrier;
    this.cardNumber = data.cardNumber;
    this.holderName = data.holderName;
  }
}

class InvoiceModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.active = data.active;
    this.barcode = data.barcode;
    this.ourNumber = data.ourNumber;
    this.url = data.url;
    this.qrCodeEmv = data.qrCodeEmv;
    this.qrCodeTxId = data.qrCodeTxId;
    this.qrCodeUrl = data.qrCodeUrl;
  }
}

export default PaymentDetailsModel;
