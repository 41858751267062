import BaseAPI, { URLS } from "./BaseAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

class AssistanceAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ASSISTANCES}`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de assistencias" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de assistencias" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.ASSISTANCES, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: "Falha ao salvar assistência" };
    } catch (e) {
      console.log(e?.response?.data?.message);
      return e?.response?.status === 400
        ? { error: e?.response?.data?.message ?? "Falha ao salvar assistência" }
        : { error: "Falha ao salvar assistência" };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ASSISTANCES}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar assistência" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao atualizar assistência" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ASSISTANCES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar assistência" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar assistência" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ASSISTANCES}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar assistência" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar assistência" };
    }
  }

  static async getChannels(isProductSegment, channelType) {
    try {
      const response = await BaseAPI.get(`/channelSalesRepresentative?${isProductSegment !== undefined ? `productSegment=${isProductSegment}` : ""}
      ${channelType !== undefined ? `&channelType=${channelType}` : ""}
      `);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao buscar lista de canais" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar lista de canais" };
    }
  }

  static async addChannels(uuid, channels) {
    const arrayChannels = Array.isArray(channels) ? channels : [channels];
    try {
      const response = await BaseAPI.put(`/manager/assistances/${uuid}/channels`, arrayChannels);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao adicionar canal" };
    } catch (e) {
      console.log(e);
      return e?.response?.status === 400
        ? { error: e?.response?.data?.message ?? "Falha ao salvar assistência" }
        : { error: "Falha ao salvar assistência" };
    }
  }

  static async deleteChannels(uuid, channels) {
    const arrayChannels = Array.isArray(channels) ? channels : [channels];
    try {
      const response = await BaseAPI.delete(`/manager/assistances/${uuid}/channels`, arrayChannels);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar canal" };
    } catch (e) {
      console.log(e.message);
      return e?.response?.status === 400
        ? { error: e?.response?.data?.message ?? "Falha ao salvar assistência" }
        : { error: "Falha ao salvar assistência" };
    }
  }

}

export default AssistanceAPI;
