import React from 'react';
import FormGroupComponentNew from '~/components/FormGroupComponentNew/FormGroupComponentNew';
import FormComponent from '~/components/FormComponent/FormComponent';
import ButtonComponent from '~/components/ButtonComponent';

import { useForm } from 'react-hook-form';

const FiltersComponent = ({ onFilter, group, cypressFields }) => {
  const { register, control, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
  });

  const onClear = () => {
    reset({});
    onFilter({});
  };

  return (
    <FormComponent onSubmit={handleSubmit(onFilter)}>
      <FormGroupComponentNew
        register={register}
        control={control}
        showCard={false}
        cypressFields={cypressFields}
        group={group}
        style={{ width: '100%' }}
      />
      <div className="buttons-container">
        <ButtonComponent
          // type="submit"
          value="submit"
          className="btn-secondary"
          onClick={onClear}
          data-cy="submit-clear"
        >
          Limpar
        </ButtonComponent>
        <ButtonComponent data-cy="submit-search" type="submit" value="submit">
          Pesquisar
        </ButtonComponent>
      </div>
    </FormComponent>
  );
};

export default FiltersComponent;
