import React, { forwardRef, useEffect, useState } from "react";
import { maskCNPJ } from "~/helpers/utils/Masks";
import { validateCNPJ } from "~/helpers/utils/Validators";
import InputComponent from "../InputComponent";
import { onlyNumbers } from "~/helpers/utils/Functions";
import BlockErrorComponent from "../../BlockErrorComponent/BlockErrorComponent";
import { Controller } from "react-hook-form";
/**
 *  CNPJComponent
 * */
const InputCNPJComponent = forwardRef((props, ref) => {
  const {
    control,
    name = "cnpj",
    id = "cnpj",
    mask = maskCNPJ,
    onChange,
    onBlur,
    defaultValue,
    setCnpjInvalid,
    required,
    ...otherProps
  } = props;
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      const isInvalid = validateCNPJ(defaultValue).isValid;
      setStatus(isInvalid);
      if (setCnpjInvalid) {
        setCnpjInvalid(isInvalid);
      }
    }
  }, [defaultValue]);

  /** Devolve apenas numeros */
  const handleChange = (e) => {
    const value = onlyNumbers(e.target.value);
    if (onChange) onChange(e, value);
  };

  const checkCNPJ = (e) => {
    const isInvalid = validateCNPJ(e.target.value).isValid;

    setStatus(isInvalid);
    if (setCnpjInvalid) {
      setCnpjInvalid(isInvalid);
    }

    const value = onlyNumbers(e.target.value);
    if (onBlur) onBlur(e, value);
  };

  return (
    <React.Fragment>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputComponent
            onChange={handleChange}
            mask={mask}
            onBlur={checkCNPJ}
            isValid={status}
            type="text"
            name={name}
            id={id}
            minLength="18"
            maxLength="18"
            required={required}
            ref={ref}
            defaultValue={defaultValue}
            {...otherProps}
            {...field}
          />
        )}
      />
      <BlockErrorComponent visible={!status} message="CNPJ Inválido" />
    </React.Fragment>
  );
});

export default InputCNPJComponent;
