import React, { useEffect } from 'react';
import InputSelectComponent from '../InputSelectComponent/InputSelectComponent';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const InputSelectColorComponent = (props) => {
  const { colorStore } = props;
  const { colorsListSelect } = colorStore;

  useEffect(() => {
    if (colorsListSelect.length === 0) colorStore.getColorListSelect();
  }, []);

  return <InputSelectComponent items={colorsListSelect} {...props} />;
};

export default inject('colorStore')(
  withRouter(observer(InputSelectColorComponent))
);
