import React from 'react';

/**
 * @name FormComponent
 * @description Formulário padrão do sistema
 * @param  {string} className - classe css
 * @param  {callback} onSubmit - chama evento após submit no form.
 * @param  {any} children - toda implementação filha
 */
const FormComponent = React.forwardRef((props, ref) => {
  const {
    className = 'form-group',
    onSubmit,
    children,
    ignoreEnter = true,
  } = props;

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        if (event.target.nodeName !== 'TEXTAREA' && ignoreEnter)
          event.preventDefault();
        break;
      default:
    }
  };
  return (
    <form
      className={className}
      onSubmit={(e) => onSubmit && onSubmit(e)}
      ref={ref}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {children}
    </form>
  );
});

export default FormComponent;
