import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import CustomerModel from "../models/CustomerModel";
import CustomerAPI from "../services/CustomerAPI";
import { entityTypes } from "~/helpers/utils/Selects";

class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customer: new CustomerModel(),
      customers: [],
      loading: false,
      totalPages: 0,
      size: 20,
      page: 0,
      filters: {},
      sort: "firstName",
    });
  }

  reset() {
    this.customer = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "firstName";
  }

  initializeCustomer() {
    this.customer = new CustomerModel();
  }

  /**Mensagem genérica. */
  showMessage(message) {
    this.toastHelper.notify(STATUS_HELPER.INFO, message);
  }

  /**Busca lista de clientes */
  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await CustomerAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.customers = response.content.map((usr) => new CustomerModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    !isPending ? await this.getList() : await this.getPending();
    return true;
  }

  /**Delete */
  async delete(uuid) {
    this.loading = true;
    const response = await CustomerAPI.delete(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário deletado com sucesso"
      );
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Cria um novo usuário (cliente) */
  async onCreate() {
    this.loading = true;
    this.customer["email"] = this.customer.user.email;
    this.customer.user.username = this.customer.user.email;

    if (this.customer.entity === "Fisica") {
      this.customer.birthdate = this.customer.birthdate
        .split("/")
        .reverse()
        .join("-");
    }
    this.customer["newsletter"] = false;
    const data = JSON.stringify(this.customer);
    const response = await CustomerAPI.createUser(data);

    if (!response.error) {
      this.customer = new CustomerModel({ entity: entityTypes[0].value });
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário criado com sucesso."
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    if (!this.customer) {
      this.customer = new CustomerModel();
    }
    const customer = this.customer;
    switch (prop) {
      case "email":
        customer.user.email = value;
        break;
      case "entity":
        customer.entity = value.value;
        break;
      case "gender":
        customer.gender = value.value;
        break;
      case "pass":
        customer.user.password = value;
        break;
      case "birthdate":
        customer.birthdate = value;
        break;
      case "exempt":
        value == true
          ? (customer.stateRegistration = "ISENTO")
          : (customer.stateRegistration = undefined);
        break;
      default:
        customer[prop] = value;
    }
    this.customer = new CustomerModel(customer);
  }

  /**
   * *Busca todas as informações de um usuario customer
   * @param  {} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await CustomerAPI.get("uuid", userName);
    if (!response.error) {
      this.customer = new CustomerModel(response);
      this.loading = false;
      return this.customer;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza informações do cliente */
  async update() {
    this.loading = true;
    this.customer["email"] = this.customer.user.email;

    // this.customer.user.uuid = 'br.com.stoom.partage.model.PartageCustomer';
    const customer = { ...this.customer };
    delete customer.uuid;
    const data = JSON.stringify(customer);
    const response = await CustomerAPI.update(this.customer.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }
}

export default CustomerStore;

decorate(CustomerStore, {
  create: action,
  update: action,
  get: action,
  getByQuery: action,
  getPending: action,
});
