import React, { forwardRef } from "react";
import InputComponent from "../InputComponent";
import { maskDate } from "~/helpers/utils/Masks";
import { Controller } from "react-hook-form";

/**Input de datas */
export default forwardRef((props, ref) => {
  const {
    control,
    defaultValue,
    name = "date",
    type = "text",
    id = "date",
    maxLength = "10",
    minLength = "10",
    mask = maskDate,
  } = props;

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputComponent
            {...props}
            defaultValue={defaultValue}
            mask={mask}
            type={type}
            id={id}
            maxLength={maxLength}
            minLength={minLength}
            ref={ref}
            {...field}
          />
        )}
      />
    );
  } else {
    return (
      <InputComponent
        {...props}
        defaultValue={defaultValue}
        mask={mask}
        type={type}
        name={name}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        ref={ref}
      />
    );
  }
});
