import React, { useEffect } from "react";
import InputSelectComponent from "../InputSelectComponent/InputSelectComponent";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

const InputSelectBannersComponent = (props) => {
  const { bannersStore } = props;
  const { bannerListSelect } = bannersStore;

  useEffect(() => {
    if (bannersStore.branchUuid) bannersStore.getBannerListSelect();
  }, [bannersStore.branchUuid]);

  return <InputSelectComponent items={bannerListSelect} {...props} />;
};

export default inject("bannersStore")(
  withRouter(observer(InputSelectBannersComponent))
);
