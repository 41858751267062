import BaseAPI, { URLS } from "./BaseAPI";

class OrderAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /*
   *Cria um novo pedido
   *@param {} -
   ****/
  static async create(preOderUuid, order) {
    try {
      const url = `${URLS.PRE_ORDERS}/${preOderUuid}/orders`;
      const response = await BaseAPI.post(url, order);
      if (response.status === 201) return response;
      return { error: "Falha o criar pedido" };
    } catch (e) {
      return this._exception(e, "Falha ao criar pedido");
    }
  }

  /**
    *@description Adiciona items ao pedido
    *@param {userUuid} userUuid identificador do usuário
    *@param {orderUuid} orderUuid identificador do pedido
    *@param {data} items que serão adicionados ao carrinho.
    @PostMapping("/{user_uuid}/orders/{order_uuid}/products")
    */
  static async addItems(userUuid, orderUuid, data) {
    try {
      const url = `customers/${userUuid}/orders/${orderUuid}/products`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao adiocionar produto" };
    } catch (e) {
      return this._exception(e, "Erro ao adicionar produto no pedido.");
    }
  }

  /**
    *@description Altera campo observação do orderItem
    *@param {number} orderUuid identificador do pedido
    *@param {number} itemUuid identificador do itemPedido
    *@param {string} textObservation texto Observação.
    @PostMapping("/order/{order_uuid}/items/{item_uuid}/observation")
    */
  static async changeObservationItem(orderUuid, itemUuid, textObservation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/items/${itemUuid}/observation`;
      const response = await BaseAPI.post(url, {
        observation: textObservation,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir texto observação" };
    } catch (e) {
      return this._exception(e, "Falha ao inserir texto observação.");
    }
  }

  /**
    *@description Altera campo observação do orderItem
    *@param {number} orderUuid identificador do pedido
    *@param {string} textObservation texto Observação.
    @PostMapping("/order/{order_uuid}/observation")
    */
  static async changeObservation(orderUuid, textObservation) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/observation`;
      const response = await BaseAPI.post(url, {
        observation: textObservation,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir texto observação" };
    } catch (e) {
      return this._exception(e, "Falha ao inserir texto observação.");
    }
  }

  /**
    *@description remove items do carrinho.
    *@param {userUuid} userUuid identificador do usuário
    *@param {orderUuid} orderUuid identificador do pedido
    *@param {data} items que serão adicionados ao carrinho.
    @PostMapping("/{user_uuid}/orders/{order_uuid}/products")
    */
  static async removeItems(userUuid, orderUuid, data) {
    try {
      const url = `customers/${userUuid}/orders/${orderUuid}/products`;
      const response = await BaseAPI.delete(url, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao remover produto" };
    } catch (e) {
      return this._exception(e, "Erro ao remover produto no pedido.");
    }
  }

  /**
   * Busca pedidos de um usuário
   * @param {orderUuid} orderUuid - uuid do pedido
   * @param {userUuid} uuid - uuid do usuário
   * */
  static async getOrderUser(orderUuid, userUuid) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/customers/${userUuid}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: "Ocorreu um erro ao buscar o pedido!" };
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  }

  /**
   * @description Altera statuso do pedido.
   * @param {userUuid} userUuid identificador do usuário
   * @param {orderUuid} orderUuid identificador do pedido
   * @param {OrderStatusModel} data objeto do novo status.
   * @PutMapping("/{user_uuid}/orders/{order_uuid}/status")
   * */
  static async changeOrderStatus(userUuid = "user", orderUuid, data) {
    try {
      ///orders/{order_uuid}/status/customers/{user_uuid}/status
      const url = `${URLS.ORDERS}/${orderUuid}/status/customers/${userUuid}/`;
      const response = await BaseAPI.put(url, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao alterar status do pedido" };
    } catch (e) {
      return this._exception(e, "Erro ao alterar status do pedido.");
    }
  }

  /**
   * Busca imagem do produto pelas metatags e products
   * @param  {string} orderUuid -Uuid do pedido
   * @param  {Array} metaTags - tags do pedido.
   */
  static async getFile(orderUuid, metaTags) {
    try {
      const array = Array.isArray(metaTags) ? metaTags : [metaTags];
      const url = `orders/${orderUuid}/${URLS.FILES}/`;
      const response = await BaseAPI.get(url, array);
      if (response.status === 200) return response.data;
      return {
        error: `${
          response.status === 404
            ? "Pedido naõ encontrado"
            : "falha ao buscar imagem do pedido"
        }`,
      };
    } catch (e) {
      return this._exception(e, "Falha ao buscar pedido");
    }
  }

  /**
   * Busca lista de ultimos pedidos
   * @param {params} pageable - parametros de paginação
   * */
  static async list(params, filterDefault) {
    try {
      const url = `${URLS.SEARCHORDERS}${filterDefault ? "" : "/search"}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Ocorreu um erro ao buscar lista de pedidos!" };
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  }

  /**
   * Busca pedidos de um usuário
   * @param {params} pageable - parametros de paginação
   * @param {userUuid} uuid - uuid do usuário
   * */
  static async listUser(userUuid, params) {
    try {
      const url = `${URLS.CUSTOMERS}/${userUuid}/orders`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Ocorreu um erro ao buscar lista de pedidos!" };
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  }

  /**
    *@description Adiciona items recomendados ao pedido
    *@param {orderUuid} orderUuid identificador do pedido
    *@param {object} data Lista de produtos recomendados.
    @PostMapping("/orders/{order_uuid}/recommended")
    */
  static async addProductRecomended(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao adiocionar produto recomendado" };
    } catch (e) {
      return this._exception(e, "Erro ao adicionar produto recomendado.");
    }
  }

  /**
    *@description remove items recomendados do pedido.
    *@param {orderUuid} orderUuid identificador do pedido
    *@param {data} items lista de items que serão removidos do pedido.
    @DeleteMapping("/orders/{order_uuid}/recommended")
    */
  static async removeRecomendedItems(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended`;
      const response = await BaseAPI.delete(url, data);
      if (response.status === 200) return response.data;
      return { error: "Erro ao remover produto" };
    } catch (e) {
      return this._exception(e, "Erro ao remover produto no pedido.");
    }
  }

  /**
    *@description Altera campo observação do recomendadeItem
    *@param {number} orderUuid identificador do pedido
    *@param {number} itemUuid identificador do itemPedido
    *@param {string} textObservation texto Observação.
    @PostMapping("/orders/{order_uuid}/recommended/{recommended_uuid}/observation")
    */
  static async changeObservationRecomended(
    orderUuid,
    recommendedUuid,
    textObservation
  ) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended/${recommendedUuid}/observation`;
      const response = await BaseAPI.post(url, {
        observation: textObservation,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir texto observação" };
    } catch (e) {
      return this._exception(e, "Falha ao inserir texto observação.");
    }
  }

  /**
    *@description Altera campo observação do recomendadeItem
    *@param {number} orderUuid identificador do pedido
    *@param {number} itemUuid identificador do itemPedido
    *@param {number} amount texto Observação.
    @PostMapping("/orders/{order_uuid}/recommended/{recommended_uuid}/amount")
    */
  static async changeAmountRecomended(orderUuid, recommendedUuid, amount) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/recommended/${recommendedUuid}/amount`;
      const response = await BaseAPI.post(url, { amount });
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir texto observação" };
    } catch (e) {
      return this._exception(e, "Falha ao inserir texto observação.");
    }
  }

  /**
    *@description Seta os tipos de frete para aquele serviço
    *@param {string} orderUuid identificador do pedido
    *@param {data} data shipping options list.
    @PostMapping("{{base_url}}/manager/orders/{{order_uuid}}/shipping/options")
    */
  static async setShippingOptions(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}/shippings/options`;
      const response = await BaseAPI.post(url, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir novos tipos de entregas." };
    } catch (e) {
      return this._exception(e, "Falha ao inserir novos tipos de entregas.");
    }
  }

  static async changeImporter(orderUuid, data) {
    try {
      const url = `${URLS.ORDERS}/${orderUuid}`;
      const response = await BaseAPI.patch(url, data);
      if (response.status === 200) return response.data;
      return { error: "Falha atualizar o pedido." };
    } catch (e) {
      return this._exception(e, "Falha atualizar o pedido.");
    }
  }
}

export default OrderAPI;
