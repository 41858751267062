import React, { forwardRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { clearCPFAndCNPJMask } from '~/helpers/utils/Functions';
import { validateCNPJ, validateCPF } from '~/helpers/utils/Validators';
import BlockErrorComponent from '../../BlockErrorComponent/BlockErrorComponent';

const cpfMask = '999.999.999-99';
const cnpjMask = '99.999.999/9999-99';

const InputCpfCnpjComponent = forwardRef(
  ({ defaultValue, onChange, onBlur, ...otherProps }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [mask, setMask] = useState(cpfMask);
    const [isCpf, setIsCPF] = useState(true);
    const [showError, setShowError] = useState(false);

    const onChangeInput = (e) => {
      if (e.target) {
        setInputValue(e.target.value);

        onChange && onChange(e);
      }
    };

    useEffect(() => {
      if (defaultValue) {
        if (defaultValue.length > 11) {
          setMask(cnpjMask);
          setIsCPF(false);
        }

        setInputValue(defaultValue);
      }
    }, [defaultValue]);

    const beforeMaskedValueChange = (newState, oldState, userInput) => {
      let { value } = newState;
      const { selection } = newState;
      const cursorPosition = selection ? selection.start : null;

      if (newState.value.length === oldState.value.length && userInput) {
        value += userInput;
        selection.start = cursorPosition + 1;
      }

      if (value.length > 14) {
        setShowError(!validateCNPJ(value).isValid);
        setIsCPF(false);
        setMask(cnpjMask);
      } else {
        setShowError(!validateCPF(value).isValid);
        setIsCPF(true);
        setMask(cpfMask);
      }

      if (value.length === 0) {
        setShowError(false);
      }

      return {
        value,
        selection,
      };
    };

    const onBlurInputMask = (e) => {
      e.target.value = clearCPFAndCNPJMask(e.target.value);

      onBlur && onBlur(e);
    };

    return (
      <div className="InputCpfCnpjComponent">
        <InputMask
          className={`form-control ${showError ? 'is-invalid' : ''}`}
          mask={mask}
          maskChar={null}
          value={inputValue}
          onChange={onChangeInput}
          beforeMaskedValueChange={beforeMaskedValueChange}
          onBlur={onBlurInputMask}
          ref={ref}
          {...otherProps}
        />
        {showError && (
          <>
            {isCpf && <BlockErrorComponent visible message="CPF inválido" />}
            {!isCpf && <BlockErrorComponent visible message="CNPJ inválido" />}
          </>
        )}
      </div>
    );
  }
);

export default InputCpfCnpjComponent;
