import { extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import OurStoreModel from "~/models/OurStoreModel";

import OurStoresAPI from "../services/OurStoresAPI";
import { onlyUnique } from "../helpers/utils/Functions";
import AssistanceAPI from "../services/AssistanceAPI";

class OurStoresStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      ourStoresList: [],
      ourStoresListSelect: [],
      ourStore: undefined,
      ourStoreFile: undefined,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "created,desc",
      filters: {},
      loading: false,
      loadingAddress: false,
      channels: [],
    });
  }

  reset() {
    this.ourStoresList = [];
    this.ourStoresListSelect = [];
    this.ourStoreFile = undefined;
    this.ourStore = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "created,desc";
    this.loading = false;
    this.loadingAddress = false;
    this.channels = [];
    this.channelsRemove = [];
    this.channelsAdd = [];
  }

  initializeOurStore() {
    this.ourStore = new OurStoreModel();
  }

  async getOurStoresListSelect() {
    const response = await OurStoresAPI.getList({
      size: 20,
      page: 0,
      sort: "name",
    });

    if (response.error) {
      this.ourStoresListSelect = [];
      return;
    }

    this.ourStoresListSelect = response.content.map((ourStore) => ({
      value: ourStore.uuid,
      label: ourStore.name,
    }));
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await OurStoresAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.ourStoresList = [];
      return;
    }

    this.ourStoresList = response.content.map(
      (ourStore) => new OurStoreModel(ourStore)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async getChannels(isProductSegment, channelType) {
    this.loading = true;

    const response = await AssistanceAPI.getChannels(
      isProductSegment,
      channelType
    );

    if (response?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.channels = [];
      this.loading = false;
    } else {
      this.channels = response.map((item) => {
        return {
          categoryName: item?.channels?.filter(
            (ch) => ch.language === "ptBR" || ch.language === "es"
          )[0]?.value,
          uuid: item?.uuid,
          value: item,
          childrenCategory: [],
        };
      });
    }
    this.loading = false;
  }

  async addChannels(ourStoreUuid) {
    if (this.channelsAdd && this.channelsAdd?.length > 0) {
      await this.channelsAdd.map(
        async (channel) =>
          await OurStoresAPI.addChannels(ourStoreUuid, channel.value).then(
            (result) => {
              if (result.error)
                this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
            }
          )
      );
    }
  }

  async deleteChannels(ourStoreUuid) {
    if (this.channelsRemove && this.channelsRemove?.length > 0) {
      await this.channelsRemove.map(
        async (channel) =>
          await OurStoresAPI.deleteChannels(ourStoreUuid, channel).then(
            (result) => {
              if (result.error)
                this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
            }
          )
      );
    }
  }

  setChannels(selecteds) {
    const ourStoreChannels = this.ourStore.channelSalesRepresentative ?? [];
    this.channelsRemove = ourStoreChannels.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.channelsAdd = selecteds.filter(
      (cat) => !ourStoreChannels.some((mCat) => mCat.uuid === cat.uuid)
    );
    const output = [...ourStoreChannels, ...this.channelsAdd]
      .filter((f) => this.channelsRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    return output;
  }

  async setPage(page) {
    this.page = page;
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async save() {
    this.loading = true;

    const response = await OurStoresAPI.save(this.ourStore);

    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }
    this.addChannels(response.uuid);
    this.deleteChannels(response.uuid);

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    return response;
  }

  async update() {
    this.loading = true;

    const response = await OurStoresAPI.update(
      this.ourStore.uuid,
      this.ourStore
    );

    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    this.addChannels(response.uuid);
    this.deleteChannels(response.uuid);

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await OurStoresAPI.get(uuid);
    this.loading = false;
    this.ourStore = new OurStoreModel(response);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await OurStoresAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  async getAddress(zipcode) {
    this.loadingAddress = true;
    const response = await OurStoresAPI.getAddress(zipcode);
    this.loadingAddress = false;

    if (response?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.onChange("zipcode", null);
      this.onChange("street", null);
      this.onChange("block", null);
      this.onChange("city", null);
      this.onChange("state", null);
      this.onChange("lat", null);
      this.onChange("lng", null);

    } else {
      this.onChange("lat", response.lat ?? null);
      this.onChange("lng", response.lng ?? null);
      this.onChange("street", response.street ?? null);
      this.onChange("block", response.block ?? null);
      this.onChange("city", response.city ?? null);
      this.onChange("state", response.state ? { label: response.state, value: response.state } : null);
    }
    return response;
  }

  onChange(prop, value) {
    const store = this.ourStore;

    switch (prop) {
      case "state":
        this.ourStore[prop] = value ? value.value : null;
        break;
      default:
        this.ourStore[prop] = value;
    }

    this.ourStore = new OurStoreModel(store);
  }
}

export default OurStoresStore;
