import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import AddressModel from "../models/AddressModel";
import AssistanceModel from "../models/AssistanceModel";
import AssistanceAPI from "../services/AssistanceAPI";
import { onlyUnique } from "~/helpers/utils/Functions";

class AssistanceStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      assistancesList: [],
      assistanceListSelect: undefined,
      assistance: undefined,
      address: undefined,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "created,desc",
      filters: {},
      loading: false,
      channels: [],
    });
  }

  reset() {
    this.assistancesList = [];
    this.assistanceListSelect = undefined;
    this.assistance = undefined;
    this.address = undefined;
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "created,desc";
    this.loading = false;
    this.channels = [];
    this.channelsRemove = [];
    this.channelsAdd = [];
  }

  initializeAssistance() {
    this.assistance = new AssistanceModel();
    this.address = new AddressModel();
    this.address = {
      block: "Pinheirinho",
      number: "1880",
      street: "Avenida Laroy S.Starrett",
      zipCode: 13306900,
    }
  }

  async get(uuid) {
    this.loading = true;
    const response = await AssistanceAPI.get(uuid);
    this.loading = false;
    this.assistance = new AssistanceModel(response);
    this.address = this.assistance.address;
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await AssistanceAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.assistancesList = [];
      return;
    }

    this.assistancesList = response.content.map(
      (assistance) => new AssistanceModel(assistance)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page, size = 20, sort = "created,desc") {
    this.page = page;
    this.size = size;
    this.sort = sort;
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async getChannels(isProductSegment, channelType) {
    this.loading = true;

    const response = await AssistanceAPI.getChannels(isProductSegment, channelType);

    if (response?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.channels = [];
      this.loading = false;
    } else {
      this.channels = response.map((item) => {
        return {
          categoryName: item?.channels?.filter(
            (ch) => ch.language === "ptBR" || ch.language === "es"
          )[0]?.value,
          uuid: item?.uuid,
          value: item,
          childrenCategory: [],
        };
      });
    }
    this.loading = false;

  }

  async addChannels(assistanceUuid) {
    if (this.channelsAdd && this.channelsAdd.length > 0) {
      await this.channelsAdd.map(
        async (channel) =>
          await AssistanceAPI.addChannels(assistanceUuid, channel.value).then(result => { if (result.error) this.toastHelper.notify(STATUS_HELPER.ERROR, result.error); })
      );
    }
  }

  async deleteChannels(assistanceUuid) {
    if (this.channelsRemove && this.channelsRemove.length > 0) {
      await this.channelsRemove.map(
        async (channel) =>
          await AssistanceAPI.deleteChannels(assistanceUuid, channel).then(result => { if (result.error) this.toastHelper.notify(STATUS_HELPER.ERROR, result.error); })
      );
    }
  }

  setChannels(selecteds) {
    const assistanceChannels = this.assistance.channelSalesRepresentative ?? [];
    this.channelsRemove = assistanceChannels.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.channelsAdd = selecteds.filter(
      (cat) => !assistanceChannels.some((mCat) => mCat.uuid === cat.uuid)
    );
    const output = [...assistanceChannels, ...this.channelsAdd]
      .filter((f) => this.channelsRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    return output;
  }

  async save() {
    this.loading = true;

    if (this.assistance.type !== "STARRETT_SALES_REPRESENTATIVE" && this.assistance.type !== "DISTRIBUTOR") {
      this.channels = [];
    }

    if (this.assistance.type !== "STARRETT_TECHNICIANS") {
      this.assistance.foodTechnician = false;
      this.assistance.starrettTechnician = false;
    }

    const data = JSON.stringify({
      ...this.assistance,
    });

    const response = await AssistanceAPI.save(data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
      if (this.assistance.type === "STARRETT_SALES_REPRESENTATIVE" || this.assistance.type === "DISTRIBUTOR") {
        this.addChannels(response.uuid);
        this.deleteChannels(response.uuid);
      }
    }

    this.loading = false;
    return response;
  }

  async update() {
    this.loading = true;

    if (this.assistance.type !== "STARRETT_SALES_REPRESENTATIVE" && this.assistance.type !== "DISTRIBUTOR") {
      this.channels = [];
    }

    if (this.assistance.type !== "STARRETT_TECHNICIANS") {
      this.assistance.foodTechnician = false;
      this.assistance.starrettTechnician = false;
    }

    const data = JSON.stringify({
      ...this.assistance,
    });

    const response = await AssistanceAPI.update(this.assistance?.uuid, data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
      if (this.assistance.type === "STARRETT_SALES_REPRESENTATIVE" || this.assistance.type === "DISTRIBUTOR") {
        this.addChannels(response.uuid);
        this.deleteChannels(response.uuid);
      }
    }

    return response;
  }

  async onDelete(uuid) {
    this.loading = true;
    const response = await AssistanceAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  async onChange(prop, value) {
    const assistance = this.assistance;
    const address = this.address;
    switch (prop) {
      case "tipo":
        assistance.type = value.value;
        break;
      case "startHourMonSat":
        assistance.startHourMonSat = value + ":00";
        break;
      case "endHourMonSat":
        assistance.endHourMonSat = value + ":00";
        break;
      case "startHourSunHol":
        assistance.startHourSunHol = value + ":00";
        break;
      case "endHourSunHol":
        assistance.endHourSunHol = value + ":00";
        break;
      case "zipCode":
        address.zipCode = parseInt(value.replace(/\D/g, ""));
        break;
      case "state":
        address.state = value ? value.value : undefined;
        break;
      case "country":
        address.country = value.value;
        if (address?.country !== 'Brasil') {
          address.state = "LATAM"
          address.city = "LATAM"
        } else {
          address.state = undefined
          address.city = undefined
        }
        break;
      case "city":
      case "street":
      case "number":
      case "block":
      case "addressComplement":
        address[prop] = value;
        break;
      default:
        assistance[prop] = value;
    }
    this.address = new AddressModel(address);
    assistance.address = this.address;
    this.assistance = new AssistanceModel(assistance);
  }
}

export default AssistanceStore;
