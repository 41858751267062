import BaseAPI, { URLS } from "./BaseAPI";

class CatalogosAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.CATALOGOS}`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de catálogos" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de catálogos" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.CATALOGOS, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: "Falha ao salvar catálogo" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao salvar catálogo" };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CATALOGOS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar catálogo" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao atualizar catálogo" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CATALOGOS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar catálogo" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar catálogo" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CATALOGOS}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar catálogo" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar catálogo" };
    }
  }

  static async removeImage(uuid, imageUuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.CATALOGOS}/${uuid}/image/${imageUuid}`
      );

      return response.status === 204 || response.status === 200
        ? response.data
        : { error: "Falha ao deletar imagem do catálogo" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar imagem do catálogo" };
    }
  }
}

export default CatalogosAPI;