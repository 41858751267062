import { extendObservable, decorate, action } from "mobx";

import CategoryModel from "~/models/CategoryModel";
import CategoryAPI from "../services/CategoryAPI";
import { categoryTypes } from "~/helpers/utils/Selects";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import UploadAPI from "../services/UploadAPI";
import FileModel from "~/models/FileModel";
import { CategoriesEnum } from "~/helpers/utils/enums/CategoriesEnum";

/**Valores inicias de variaveis observadas */
const initValues = {
  category: undefined,
  categories: [],
  categoriesListSelect: undefined,
  loading: false,
  disabledItem: false,
  showCaseCategories: [],
};
class CategoryStore {
  // totalPages = 0;
  //page = 0;
  //size = 20;
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.categoriesListSelect = undefined;
    this.category = undefined;
    this.categories = [];
  }

  /**Atualiza propriedades da categoria */
  onChange(prop, value) {
    if (prop === "ptBR" || prop === "enUS" || prop === "es") {
      this.addTranslate(prop, value);
    }

    switch (prop) {
      case "parentCategory":
        const parent = value ? value : undefined;
        this.category.parentCategory = parent;
        this.category = new CategoryModel(this.category);
        break;
      case "type":
        this.category.type = value.value;
      default:
        this.category[prop] = value;
    }
  }

  /**Cria nova categoria com dados padrão */
  emptyCategory() {
    const type = categoryTypes[0].value;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.category = new CategoryModel({
      type,
      merchant,
    });
  }

  async addTranslate(prop, value) {
    const data = {
      language: prop,
      value: value,
    };

    const verifyExisteItem = this.category.translatables.filter(
      (item) => item.language === prop
    );

    if (verifyExisteItem.length === 0) {
      this.category.translatables.push(data);
    } else {
      let new_updated_data = this.category.translatables.map((item) => {
        if (item.language === prop) {
          return {
            ...item,
            value: value,
          };
        }
        return item;
      });
      this.category.translatables = new_updated_data;
    }
  }

  /**Salva nova categoria. */
  async onCreate() {
    this.loading = true;
    const list = [].concat(this.category.files);
    this.category.files = [];

    const filterPtBr = this.category.translatables.filter(
      (item) => item.language === "ptBR"
    );

    const datacategory = {
      ...this.category,
      categoryName: filterPtBr[0].value,
      homeDisplay: this.category.homeDisplay || false,
      menuDisplay: this.category.menuDisplay || false,
    };

    let data = new CategoryModel(datacategory);
    data = JSON.stringify(data);
    const response = await CategoryAPI.save(data);
    this.loading = false;
    if (response.error) {
      if (
        response.error.includes("Order of the existing category!") &&
        response.status === 500
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Ordenação já existente");
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      this.emptyCategory();
      this.sendNewFiles(list, response.uuid);
      await this.getTree();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Categoria incluida com sucesso"
      );
    }
    return response;
  }

  /**Atualiza categoria categoria. */
  async onUpdate() {
    this.loading = true;
    const category = new CategoryModel(this.category);
    delete category.files;
    const data = JSON.stringify(category);
    const response = await CategoryAPI.update(this.category.uuid, data);
    this.loading = false;
    if (response.error) {
      if (
        response.error.includes("Order of the existing category!") &&
        response.status === 500
      ) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Ordenação já existente");
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      await this.getTree();
      this.handleFiles(this.category.files);
      this.category = new CategoryModel(response);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Dados atualizados");
    }
    return response;
  }

  /**Busca categorias em arvores*/
  async getTree() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    this.loading = false;
    if (!response.error) {
      this.categories = response.map((cat) => new CategoryModel(cat));

      this.showCaseCategories = this.categories.filter(
        (cat) => cat.type === CategoriesEnum.VITRINE
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Busca categoria por uuid */
  async get(uuid) {
    this.loading = true;
    this.disabledItem = false;
    const response = await CategoryAPI.get(uuid);
    if (!response.error) {
      this.category = new CategoryModel(response);
      // if (
      //   this.category.categoryName === 'Novos Produtos' ||
      //   this.category.categoryName === 'Produtos em promoção'
      // ) {
      //   this.disabledItem = true;
      // }
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta categoria */
  async delete(uuid) {
    this.loading = true;
    const response = await CategoryAPI.delete(uuid);
    if (!response.error) this.toastHelper.notify("info", "Categoria removida");
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async handleFiles(files) {
    this.loading = true;

    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter((file) => file.uuid && file.file);

    newFiles.length > 0 &&
      (await this.sendNewFiles(newFiles, this.category.uuid));
    updateds.length > 0 && (await this.updateFiles(updateds));

    this.loading = false;
    return true;
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async filesService(promise, message) {
    const response = await Promise.all(promise);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    await UploadAPI.removeFileCategory(files);
    this.get(this.category.uuid);

    //  await UploadAPI.removeFileCategory(file.uuid);
    // return await this.filesService(promise, 'Falha o deletar imagens');
  }

  /**Atualiza imagem da categoria */
  async updateFiles(files) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    await this.filesService(promise, "Falha ao atualizar imagens");
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  /**Envia imagem da categoria. */
  async sendNewFiles(files, categoryUuid) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadCategory(categoryUuid, file);
    });
    await this.filesService(promise, "ao incluir imagens");
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  /**Adiciona imagem ao produto */
  _handleImages(dropedFiles, tag, fileItem) {
    /**Altera arquivo. */
    const dropedFile = dropedFiles.length > 0 ? dropedFiles[0] : undefined;
    if (!fileItem && dropedFile) {
      const newFileItem = new FileModel({
        file: dropedFile,
        ...dropedFile,
        metaTags: [tag],
      });
      this.category.files.push(newFileItem);
    } else fileItem.updateFile(dropedFile); //FileItem existe, Atualiza valores do arquivo recebido
  }

  handleFileCategory(dropedFiles) {
    const category = this.category;
    this._handleImages(dropedFiles, "category", category?.fileCategory);
  }

  handleIconCategory(dropedFiles) {
    const category = this.category;
    this._handleImages(dropedFiles, "icon-category", category?.iconCategory);
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(excludes = {}) {
    if (!excludes) return;

    const localFilter = (category) => {
      const hasChildren = category.childrenCategory.length > 0;
      if (hasChildren) {
        category.childrenCategory =
          category.childrenCategory.filter(localFilter);
      }
      return !excludes.some((cat) => cat.uuid === category.uuid);
    };

    if (Array.isArray(excludes)) {
      return this.categories.filter(localFilter);
    }
    return this.categories.filter((cat) => cat.uuid !== excludes.uuid);
    //        return list.map(mCat => ({ value: mCat.uuid, label: mCat.categoryName }));
  }

  genarateSelectOptions(items, parentPath) {
    let options = [];
    items.forEach((item) => {
      const categoryName =
        (parentPath ? parentPath + "/" : "") + item.categoryName;
      options = [...options, { value: item.uuid, label: categoryName }];
      if (item.childrenCategory?.length > 0)
        options = [
          ...options,
          ...this.genarateSelectOptions(item.childrenCategory, categoryName),
        ];
    });

    return options;
  }

  async getListCategorySelect() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    this.categoriesListSelect = this.genarateSelectOptions(response);
    this.loading = false;
  }
}
decorate(CategoryStore, {
  get: action,
  getTree: action,
  save: action,
  update: action,
  delete: action,
  handleFiles: action,
});

export default CategoryStore;
