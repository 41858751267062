import React, { forwardRef } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import LoadingComponent2 from '../LoadingComponent2';

/**
 * @Name: ColComponent
 * @Data: 2019
 * @Desc: Div com classe bootstrap que agrupa diversos componentes em uma coluna
 * @param  {string} className="col"
 * @param  {string} size - (lg, md ou sm)
 * @param  {string} cols - ( 0 a 12)
 * @param  {any} children
 * @param  {any} style
 **/

const InformationPageComponent = forwardRef((props, ref) => {
  // Recebe as propriedades
  const { text, className = '', loading = false } = props;
  return (
    <>
      {!loading && (
        <div className={`text-box ${className}`}>
          <IoMdInformationCircle />
          <p>{text}</p>
        </div>
      )}

      {loading && (
        <div
          className={`size-informations-loading ${loading && 'pointer-none'}`}
        >
          <LoadingComponent2 style={{}} />
        </div>
      )}
    </>
  );
});

export default InformationPageComponent;
