const TypeTableEnum = Object.freeze({
  LINE: 'line',
  BUTTON: 'button',
  DATE: 'date',
  BOOLEAN: 'boolean',
  ICON: 'icon',
  IMAGE: 'image',
  TOOGLE: 'toogle',
  SELECT: 'select',
});

export default TypeTableEnum;
