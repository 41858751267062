import React, { forwardRef } from 'react';

const InputCheckboxComponent = forwardRef((props, ref) => {
  //Props
  const {
    defaultChecked,
    name = 'checkbox',
    id = 'checkbox',
    large,
    medium,
    small,
    dataOff,
    dataOn,
    onChange,
    disabled = false,
    className,
    horizontalLabel,
  } = props;

  //Tratativa inclusa para ter dinamicidade de estilizacao de checkbox para toogle horizontal e checkbox comum
  const wrapperClassName = `${className} checkbox d-flex ${
    medium ? 'switches_m' : ''
  } ${small ? 'switches_s' : ''} ${large ? 'switches_l' : ''} `;

  return (
    <div className={horizontalLabel ? wrapperClassName : ''}>
      <label className={!horizontalLabel ? wrapperClassName : ''}>
        {defaultChecked && (
          <input
            type="checkbox"
            disabled={disabled}
            name={name}
            id={id}
            ref={ref}
            className="ios-toggle checkbox2"
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
        )}
        {!defaultChecked && (
          <input
            type="checkbox"
            disabled={disabled}
            name={name}
            id={id}
            ref={ref}
            className="ios-toggle checkbox2"
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
        )}
        <label
          htmlFor={id}
          className="checkbox-label"
          data-off={dataOff}
          data-on={dataOn}
          disabled={disabled}
        />
      </label>
      {horizontalLabel && (
        <span className="horizontal-label">{horizontalLabel}</span>
      )}
    </div>
  );
});

export default InputCheckboxComponent;
