import BaseModel from "./BaseModel";

class MerchantModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.kernel.model.persistence.Merchant";
    this.name = data.name;
    this.nickname = data.nickname;
    this.entity = data.entity;
    this.document = data.document;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.domain = data.domain;
    this.mobile = data.mobile;
    this.buildTimeDays = data.buildTimeDays;
    this.percentage = data.percentage;
    this.percentageValue = data.percentageValue;
    this.zipCode = data.zipCode;
    this.street = data.street;
    this.block = data.block;
    this.number = data.number;
    this.city = data.city;
    this.state = data.state;
    this.addressComplement = data.addressComplement;
    this.country = data.country;
    this.externalId = data.externalId;
  }
}

export default MerchantModel;
