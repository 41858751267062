import CampaignModelsEnum from './enums/CampaignModelsEnum';
import PromotionApplicationTypeEnum from './enums/PromotionApplicationTypeEnum';

/** Validador de CPF */
export const validateCPF = (cpfNumber) => {
  const cpfLength = 11;
  cpfNumber = cpfNumber.replace(/\D/g, '');
  // Verifica o tamanho da string.
  if ([cpfLength].indexOf(cpfNumber.length) < 0) return { isValid: false };
  // Verifica se todos os dígitos são iguais.
  if (/^([0-9])\1*$/.test(cpfNumber)) return { isValid: false };
  // A seguir é realizado o cálculo verificador.
  const cpfArray = cpfNumber.split('').reverse().slice(2);
  // Calcula o primeiro dígito
  cpfArray.unshift(buildDigit(cpfArray, cpfLength));
  // Calcula o segundo dígito
  cpfArray.unshift(buildDigit(cpfArray, cpfLength));
  // Dígito verificador não é válido, resultando em falha.
  if (cpfNumber !== cpfArray.reverse().join('')) return { isValid: false };

  return { isValid: true };
};

/** Make de CPF digit validation */
const buildDigit = (arr = [], cpfLength = 0) => {
  const digit =
    arr
      .map((val, idx) => val * (idx + 2))
      .reduce((total, current) => total + current) % cpfLength;
  if (digit < 2) return 0;
  return cpfLength - digit;
};

// Validador de CNPJ
export const validateCNPJ = (value) => {
  const cnpj = value.replace(/[^\d]+/g, '');
  // Verifica valor empty
  if (cnpj === '') return { isValid: false };
  // Verifica tamanho menor que 14
  if (cnpj.length !== 14) {
    return { isValid: false };
  }
  // Elimina CNPJs isValidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return { isValid: false };
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  // Verifica o primeiro dígito
  if (resultado.toString() !== digitos.charAt(0)) {
    return { isValid: false };
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  // Verifica o segundo digito
  if (resultado.toString() !== digitos.charAt(1)) {
    return { isValid: false };
  }

  // CNPJ válido
  return { isValid: true };
};

/** Valida e-mail. */
export const validateEmail = (email) => {
  if (!email) return { isValid: false };
  const analyzeDoublePoints = email.split('');

  let doublePoint = false;

  analyzeDoublePoints.map((e, index) => {
    if (e === '.' && e === analyzeDoublePoints[index + 1]) {
      doublePoint = true;
    }
  });

  if (doublePoint) {
    return false;
  }

  // eslint-disable-next-lin
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return { isValid: re.test(String(email).toLowerCase()) };
};

export const validateColorHexCode = (color) => {
  const re = /#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g;
  return { isValid: re.test(String(color).toLowerCase()) };
};

export const validateCampaignModel = (
  campaign,
  productCategoryRestriction,
  applicationType,
  isAllProducts,
  priceRestriction,
  buyXPayY,
  zipCodeRestriction
) => {
  let isValid = true;
  let message = '';

  switch (campaign.type) {
    case CampaignModelsEnum.DESCONTO_PERCENTUAL:
      if (campaign.discounts.length > 0) {
        if (!campaign.discounts[0].productsPercentageDiscount) {
          isValid = false;
          message += 'Desconto é obrigatório; ';
        }

        if (campaign.discounts[0].productsPercentageDiscount > 1) {
          isValid = false;
          message += 'Desconto não pode ser maior que 100%; ';
        }
      }

      if (!applicationType) {
        isValid = false;
        message += 'Selecione a aplicação da campanha; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.CATEGORIA &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma categoria; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.COLECAO &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma coleção; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.VARIACAO &&
        (!productCategoryRestriction.products ||
          productCategoryRestriction?.products?.length === 0) &&
        !isAllProducts
      ) {
        isValid = false;
        message += 'Selecione pelo menos um produto; ';
      }

      return { isValid, message };

    case CampaignModelsEnum.X_OFF_Y_UNIDADE:
      if (campaign.discounts.length > 0) {
        if (!campaign.discounts[0].productsPercentageDiscount) {
          isValid = false;
          message += 'Desconto é obrigatório; ';
        }

        if (campaign.discounts[0].productsPercentageDiscount > 1) {
          isValid = false;
          message += 'Desconto não pode ser maior que 100%; ';
        }
      }

      if (!buyXPayY.y) {
        isValid = false;
        message += 'Unidade de pague é obrigatório; ';
      }

      if (!buyXPayY.x) {
        isValid = false;
        message += 'Unidade de leve é obrigatório; ';
      }

      if (!applicationType) {
        isValid = false;
        message += 'Selecione a aplicação da campanha; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.CATEGORIA &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma categoria; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.COLECAO &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma coleção; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.VARIACAO &&
        (!productCategoryRestriction.products ||
          productCategoryRestriction?.products?.length === 0) &&
        !isAllProducts
      ) {
        isValid = false;
        message += 'Selecione pelo menos um produto; ';
      }

      return { isValid, message };
    case CampaignModelsEnum.LEVE_X_PAGUE_Y:
      if (campaign.discounts.length > 0) {
        if (!buyXPayY.y || !buyXPayY.x) {
          isValid = false;
          message += 'Unidade de pague e leve é obrigatório; ';
        }
      }

      if (!applicationType) {
        isValid = false;
        message += 'Selecione a aplicação da campanha; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.CATEGORIA &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma categoria; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.COLECAO &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma coleção; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.VARIACAO &&
        (!productCategoryRestriction.products ||
          productCategoryRestriction?.products?.length === 0) &&
        !isAllProducts
      ) {
        isValid = false;
        message += 'Selecione pelo menos um produto; ';
      }
      return { isValid, message };

    case CampaignModelsEnum.Progressive_Discount:
      if (campaign.discounts.length > 0) {
        if (
          campaign.discounts.filter((discount) => !discount.amountToPay)
            .length > 0
        ) {
          isValid = false;
          message += 'Unidade de produto é obrigatório; ';
        }

        if (
          campaign.discounts.filter(
            (discount) => !discount.productsPercentageDiscount
          ).length > 0
        ) {
          isValid = false;
          message += 'Desconto é obrigatório; ';
        }

        if (
          campaign.discounts.filter(
            (discount) => discount.productsPercentageDiscount > 1
          ).length > 0
        ) {
          isValid = false;
          message += 'Desconto não pode ser maior que 100%; ';
        }
      }

      if (!applicationType) {
        isValid = false;
        message += 'Selecione a aplicação da campanha; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.CATEGORIA &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma categoria; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.COLECAO &&
        (!productCategoryRestriction.categories ||
          productCategoryRestriction?.categories?.length === 0)
      ) {
        isValid = false;
        message += 'Selecione uma coleção; ';
      } else if (
        applicationType.value === PromotionApplicationTypeEnum.VARIACAO &&
        (!productCategoryRestriction.products ||
          productCategoryRestriction?.products?.length === 0) &&
        !isAllProducts
      ) {
        isValid = false;
        message += 'Selecione pelo menos um produto; ';
      }
      return { isValid, message };
    case CampaignModelsEnum.Fixed_Price_Campaign:
      if (applicationType) {
        if (
          applicationType.value === PromotionApplicationTypeEnum.CATEGORIA &&
          (!productCategoryRestriction.categories ||
            productCategoryRestriction?.categories?.length === 0)
        ) {
          isValid = false;
          message += 'Selecione uma categoria; ';
        }

        if (
          applicationType.value === PromotionApplicationTypeEnum.COLECAO &&
          (!productCategoryRestriction.categories ||
            productCategoryRestriction?.categories?.length === 0)
        ) {
          isValid = false;
          message += 'Selecione uma coleção; ';
        }

        if (
          applicationType.value === PromotionApplicationTypeEnum.VARIACAO &&
          (!productCategoryRestriction.products ||
            productCategoryRestriction?.products?.length === 0) &&
          !isAllProducts
        ) {
          isValid = false;
          message += 'Selecione pelo menos um produto; ';
        }
      }

      if (
        (zipCodeRestriction.initialZipCode &&
          !zipCodeRestriction.finalZipCode) ||
        (!zipCodeRestriction.initialZipCode && zipCodeRestriction.finalZipCode)
      ) {
        isValid = false;
        message += 'Selecione o CEP; ';
      }

      if (
        !zipCodeRestriction.initialZipCode &&
        !zipCodeRestriction.finalZipCode &&
        !applicationType
      ) {
        isValid = false;
        message += 'Selecione CEP ou uma aplicação para a campanha; ';
      }
      return { isValid, message };
    case CampaignModelsEnum.VALOR_BASE_COM_X_OFF:
      if (campaign.discounts.length > 0) {
        if (!campaign.discounts[0].productsPercentageDiscount) {
          isValid = false;
          message += 'Desconto é obrigatório; ';
        }

        if (campaign.discounts[0].productsPercentageDiscount > 1) {
          isValid = false;
          message += 'Desconto não pode ser maior que 100%; ';
        }
      }

      if (!priceRestriction.minPrice) {
        isValid = false;
        message += 'Valor de pedido mínimo é obrigatório; ';
      }
    default:
      return { isValid, message };
  }
};
