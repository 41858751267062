import React, { forwardRef } from 'react';
import InputCheckboxComponent from '../InputCheckboxComponent';

/**
 * @Name: InputToogleComponent
 * @Data: 2022
 * @Desc: Componente de Toogle
 * @param  {string} defaultChecked
 * @param  {string} clic
 **/

const InputToogleComponent = forwardRef((props, ref) => {
  // Recebe as propriedades
  const {
    defaultChecked,
    onChange,
    id,
    name,
    data,
    toogleActive,
    toogleInactive,
    title,
    checked,
  } = props;

  return (
    <div className="option-toogle">
      <div className="icon-toogle">
        <InputCheckboxComponent
          small
          onChange={onChange && onChange}
          name={name}
          defaultChecked={defaultChecked || ''}
          id={id}
          required
          checked={checked}
          ref={ref}
        />
      </div>
      <div className="text-toogle">
        <h4>{title}</h4>
        <p>{data ? toogleActive : toogleInactive}</p>
      </div>
    </div>
  );
});

export default InputToogleComponent;
