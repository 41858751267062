import React from "react";
import { dateToText } from "~/helpers/utils/Functions";
import TypeTableEnum from "~/helpers/utils/enums/TypeTableEnum";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import InputSelectComponent from "~/components/InputComponent/InputSelectComponent/InputSelectComponent";
import InputToogleComponent from "../InputComponent/InputToogleComponent/InputToogleComponent";
import { FollowDown, FollowUp, FallowUpAndDown } from './TableDinamicIcons';

const useSort = (initialSort, onSort) => {
  const [sort, setSort] = React.useState(
    initialSort?.split(",").length < 1 ? initialSort + ",asc" : initialSort
  );

  const [sortFieldKey, setSortFieldKey] = React.useState(
    initialSort?.split(",").length < 1 ? initialSort + ",asc" : initialSort
  );

  const [direction, setDirection] = React.useState(
    initialSort?.includes(",desc") ? "desc" : "asc"
  );

  const handleClickHeading = (fieldKey) => {
    if (fieldKey === "files") return;
    const newDirection =
      sort && sort.includes(fieldKey) && !sort.includes(",desc")
        ? "desc"
        : "asc";
    const newSort = `${fieldKey}${newDirection === "desc" ? ",desc" : ""}`;
    setSortFieldKey(fieldKey);
    setDirection(newDirection);
    setSort(newSort);
    onSort(newSort);
  };

  return {
    sortFieldKey,
    direction,
    onClickHeading: handleClickHeading,
  };
};


const clearValue = (value) => {
  if (value.includes(',desc')) {
    let v = value.split(',');
    return v[0];
  }
  return value;
};

/**
 * @Name: Tabela Dinamica Padrão
 * @Data: 2022
 * @Desc: Monta uma tabela atraves dos valores passados no columns.
 * @props: {
 *    heading: Cabeçalho da tabela
 *    fieldKey: Key do objeto para acessar o valor que vai ser mostrado na td.
 *    type: Define a propriedade que ira aparecer na tela. ( icon |  line |  button | boolean | date )
 *    icon: Imagen do icone.
 *    title: Titulo do botão.
 *    action: Função do clique da td.
 * }
 */

const TableDinamicComponent = ({
  items,
  columns,
  totalPages,
  initialPage,
  onChangePage,
  onSort,
  initialSort,
}) => {
  const { onClickHeading, sortFieldKey, direction } = useSort(
    initialSort,
    onSort
  );
  return (
    <>
      <table className="table-dynamic">
        <thead>
          <tr>
            {columns &&
             columns.map((item, index) => (
              <th
                key={index}
                className={`table-header ${onSort ? 'is-ordering' : ''}`}
                style={item?.thStyles || {}}
                onClick={() =>
                  onSort &&
                  item.fieldKey &&
                  item.heading &&
                  onClickHeading(item.fieldKey)
                }
              >
                {item.heading}
                {   item.enabledFilter !== false && sortFieldKey && 
                  clearValue(sortFieldKey) === item.fieldKey ? (
                  (direction === 'asc' ? (
                    <FollowUp />
                  ) : (
                    <FollowDown />
                  ))) : (
                    onSort &&
                    item.fieldKey &&
                    item.heading &&
                    item.enabledFilter !== false && <FallowUpAndDown />
                  )}
              </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {items.length > 0 &&
            items.map((item, itemIndex) => (
              <tr key={itemIndex}>
                {columns.map((columnItem, index) => {
                  if (columnItem.fieldKey?.includes(".")) {
                    const itemSplit = columnItem.fieldKey.split(".");
                    return (
                      <td key={index} style={columnItem?.tdStyles || {}} data-cy={columnItem.heading}>
                        {itemSplit.length === 2 ? (
                          <p className="line-table" key={index}>
                            {item &&
                              item[itemSplit[0]] &&
                              item[itemSplit[0]][itemSplit[1]]}
                          </p>
                        ) : (
                          <p className="line-table" key={index}>
                            {item &&
                              item[itemSplit[0]][itemSplit[1]] &&
                              item[itemSplit[0]][itemSplit[1]][itemSplit[2]]}
                          </p>
                        )}
                      </td>
                    );
                  }
                  return (
                    <td key={index} style={columnItem?.tdStyles || {}} data-cy={columnItem.heading}>
                      {/* Linha comum que recebe apenas um objeto simples */}

                      {columnItem.type === TypeTableEnum.LINE && (
                        <p>{item[`${columnItem.fieldKey}`]}</p>
                      )}

                      {columnItem.type === TypeTableEnum.ICON && (
                        <i
                          data-cy="icon-table"
                          className={columnItem.icon}
                          onClick={() =>
                            columnItem.action && columnItem.action(item)
                          }
                        >
                          {item[`${columnItem.fieldKey}`]}
                        </i>
                      )}

                      {/* Linha para adicionar um objeto de remoção */}
                      {columnItem.type === TypeTableEnum.BOOLEAN && (
                        <>
                          <p
                            onClick={() =>
                              columnItem.action && columnItem.action(item)
                            }
                            className={`boolean-${
                              item[`${columnItem.fieldKey}`] === false
                                ? "false"
                                : "true"
                            }`}
                            key={index}
                          >
                            {item[`${columnItem.fieldKey}`] === true
                              ? columnItem.textOn || "Ativo"
                              : columnItem.textOff || "Inativo"}
                          </p>
                        </>
                      )}

                      {/* Adicionar um botão */}
                      {columnItem.type === TypeTableEnum.BUTTON && (
                        <button
                          onClick={() =>
                            columnItem.action && columnItem.action(item)
                          }
                          className={
                            ("aproved",
                            item.approved ? "aproved" : "not-aproved")
                          }
                        >
                          {item.approved
                            ? columnItem.textOn || "Aprovado"
                            : columnItem.textOff || "Pendente"}
                        </button>
                      )}
                      {columnItem.type === TypeTableEnum.DATE && (
                        <p>
                          {dateToText(
                            item[`${columnItem.fieldKey}`],
                            "/",
                            true
                          )}
                        </p>
                      )}

                      {/* Linha para adicionar um objeto de remoção */}
                      {columnItem.type === TypeTableEnum.IMAGE && (
                        <>
                          <img
                            onClick={() =>
                              columnItem.action && columnItem.action(item)
                            }
                            src={
                              item[`${columnItem.fieldKey}`].length > 0
                                ? item[`${columnItem.fieldKey}`][0].path
                                : null
                            }
                            key={index}
                            height={55}
                            width={55}
                            alt=""
                          />
                        </>
                      )}

                      {columnItem.type === TypeTableEnum.SELECT && (
                        <>
                          <InputSelectComponent
                            items={columnItem.items}
                            onChange={(e) => columnItem.onChange(e, item)}
                            defaultValue={columnItem.getDefaultValue(item)}
                          />
                        </>
                      )}

                      {columnItem.type === TypeTableEnum.TOOGLE && (
                        <>
                          <InputToogleComponent
                            defaultChecked={item[`${columnItem.fieldKey}`]}
                            id={item.uuid ?? item.tempUuid}
                            onChange={(e) =>
                              columnItem.action &&
                              columnItem.action(item, e.target.checked)
                            }
                            key={index}
                          />
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationComponent
        pages={totalPages}
        onChange={(selectedItem) => onChangePage(selectedItem.selected)}
        initialPage={initialPage}
      />
    </>
  );
};

export default TableDinamicComponent;
