import BaseAPI, { URLS } from './BaseAPI';

class OurStoresAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}/filter`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de lojas' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de lojas' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(`${URLS.BRANCHES}/create`, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: 'Falha ao salvar loja' };
    } catch (e) {
      return { error: e.response.data.message ?? 'Falha ao salvar loja' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.BRANCHES}/update`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar loja' };
    } catch (e) {
      if (e.response.status == 400) return { error: e.response.data.message };
      return { error: 'Falha ao salvar loja' };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar loja' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar loja' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.BRANCHES}/${uuid}`);
      return response.status === 204 || response.status === 200
        ? response.data
        : { error: 'Falha ao deletar loja' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao deletar loja' };
    }
  }

  static async getAddress(zipcode) {
    try {
      const response = await BaseAPI.get(`manager/address/zipcode/${zipcode}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha inesperado ao pesquisar CEP' };
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data?.message ?? 'Falha inesperado ao pesquisar CEP' };
    }
  }

  static async addChannels(uuid, channels) {
    const arrayChannels = Array.isArray(channels) ? channels : [channels];
    try {
      const response = await BaseAPI.put(`${URLS.BRANCHES}/${uuid}/channels`, arrayChannels);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao adicionar canal" };
    } catch (e) {
      console.log(e);
      return e?.response?.status === 400
        ? { error: e?.response?.data?.message ?? "Falha ao salvar assistência" }
        : { error: "Falha ao salvar assistência" };
    }
  }

  static async deleteChannels(uuid, channels) {
    const arrayChannels = Array.isArray(channels) ? channels : [channels];
    try {
      const response = await BaseAPI.delete(`${URLS.BRANCHES}/${uuid}/channels`, arrayChannels);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar canal" };
    } catch (e) {
      console.log(e.message);
      return e?.response?.status === 400
        ? { error: e?.response?.data?.message ?? "Falha ao salvar assistência" }
        : { error: "Falha ao salvar assistência" };
    }
  }

}

export default OurStoresAPI;
