import React, { forwardRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { BsEyeSlash, BsEye } from "react-icons/bs";

/**
 * @Name: InputComponent
 * @Data: 2019
 * @Desc: Input padrão do sistema.
 * @param  {} props - todas propriedades do input html
 * @Obs: Este comp possui muitas props, então para não limitar a sua utilização,
 *       não estamos recebendo apenas alguns valores, mas todas quanto forem necessárias
 *       e fazendo um Spread das props nos inputs(padrão e mascara.).
 *       Caso haja necessidade de alguma lógica relacionada à alguma prop,
 *       esta deverá ser extraida da props e a logica aplicada conforme necessidade.
 */
const InputComponent = forwardRef((props, ref) => {
  // caso não receba como props um className, ele receberá um valor padrão ('form-control')
  const [allowCheck, setAllow] = useState(true);
  const [baseValid, setValid] = useState(true);
  const {
    minLength,
    onChange,
    onBlur,
    mask,
    className = 'form-control',
    isValid = baseValid,
    type,
    negatives = false,
    disabledCssEye = "iconEye"
  } = props;
  const localClass = `${className} ${allowCheck ? `${!isValid ? 'is-invalid' : ''} ` : ''
    }`;
    const [showPassword, setShowPassword] = useState(false);

  /** Validação de numéros netativos */
  const checkNumbers = (e) => {
    if (!negatives && type && type === 'number') {
      const { value } = e.target;
      if (Number(value) < 0) e.target.value = 0;
    }
  };
  // Não faz a verificação no onChange, apenas no blur
  const handleChange = (e) => {
    e.target.setCustomValidity('');
    checkNumbers(e);
    setAllow(false);

    if (onChange) onChange(e);
  };
  // No blur, é feito a verificação inicial se o campo possui a quantidade mínima de caracteres,
  // e depois e retornado o callback para verifcações de camadas superiores.
  const handleBlur = (e) => {
    checkNumbers(e);

    // Converte em inteiro para verificação
    const length = parseInt(minLength, 10);

    // Verifica a quantidade mínima de caracteres, caso ela seja definida.
    let isMinLengthValid = true;

    if (e.target.value.length > 0) {
      isMinLengthValid = length > 0 ? e.target.value.length >= length : true;
    }

    // Atualização de estados do componente.
    setValid(isMinLengthValid);

    setAllow(true);

    // callback
    if (onBlur) onBlur(e);
  };

  // Como o react não reconhece algumas variáveis(por exemplo, isValid).
  // É necessário fazer um spread em uma variavel custom e remover
  // as propriedades que não são reconhecidas.
  const customProps = {
    ...props,
    className: props?.enabledEye === "enabled"? className : localClass,
    onChange: handleChange,
    onBlur: handleBlur,
    ref,
  };
  delete customProps.isValid;

  return mask ? (
    <MaskedInput {...customProps} guide={false} />
  ) : (
    props?.enabledEye === "enabled" ?
      <>
        <div className={disabledCssEye ? 'disablediconEye' : 'iconEye'}>
          <input {...customProps} type={showPassword ? "text" : "password"} />

          {!showPassword ? (
            <BsEyeSlash onClick={() => setShowPassword(true)} />
          ) : (
            <BsEye onClick={() => setShowPassword(false)} />
          )}
        </div>
        {props.infoPassword && props.infoPassword}
      </>
      : (
        <input {...customProps} />
      )
  );
});

export default InputComponent;
