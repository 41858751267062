import React from "react";
import CardPainelComponent from "../CardPainelComponent/CardPainelComponent";
import CollumComponent from "../CollumComponent/CollumComponent";
import InputWrapper from "../InputComponent/InputWrapper";

/**
 * @Name: FormGroupComponentNew
 * @Data: 2019
 * @Desc: Div com classe bootstrap que agrupa diversos componentes
 * @props:
 *  group - Objeto com titulo e Lista de objetos contendo campos
 */

const FormGroupComponentNew = (props) => {
  // Recebe as propriedades
  const {
    group,
    register,
    control,
    errors,
    cypressFields,
    style,
    showCard = true,
    infoPassword
  } = props;

  const emptyRender = ({ children }) => (
    <div className="empty-card-pannel-form">{children}</div>
  );
  const CardRender = showCard ? CardPainelComponent : emptyRender;

  return (
    <CollumComponent
      size="xl"
      cols={group.cols}
      style={style ? style : { display: "flex" }}
    >
      <CardRender title={group?.title}>
        <div className="from-group-content-wrapper">
          {group.fields.map((e, index) => (
            <InputWrapper
              {...e}
              key={e.id + "_" + index}
              register={register}
              control={control}
              error={errors && errors[e.id]}
              data-cy={cypressFields && cypressFields[e.id]}
              infoPassword={infoPassword}
            />
          ))}
        </div>
      </CardRender>
    </CollumComponent>
  );
};

export default FormGroupComponentNew;
