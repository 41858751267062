import React, { useEffect } from 'react';
import InputSelectComponent from '../InputSelectComponent/InputSelectComponent';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const InputSelectSizeComponent = (props) => {
  const { sizeStore } = props;
  const { sizesListSelect } = sizeStore;

  useEffect(() => {
    if (sizesListSelect.length === 0) sizeStore.getSizeListSelect();
  }, []);

  return <InputSelectComponent items={sizesListSelect} {...props} />;
};

export default inject('sizeStore')(
  withRouter(observer(InputSelectSizeComponent))
);
