import BaseModel from './BaseModel';
import FileModel from "./FileModel";
import TranslatablesModel from "./TranslatablesModel";
const handleTranslate = (data) => {
  if(data !== undefined) {
    if (data.length != 0 ) {
      const title = data.find((e) => e.keyName === "EVENT_NAME")
      if (title.language === "ptBR") {
        return title.value
      } else if (title.language === "es") {
        return title.value
      } else if (title.language === "enUS") {
        return title.value
      } else {
        return "Sem Título"
      }
    }else {
      return "Sem Título"
    }
  } else {
    return "Sem Título"
  }

}

class EventModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.slug = data.slug;
    this.local = data.local;
    this.hourEvent = data.hourEvent;
    this.dateInit = data.dateInit;
    this.dateEnd = data.dateEnd;
    this.url = data.url;
    this.active = data.active;
    this.file = new FileModel(data.file);
    this.translatables = data.translatables
    ? data.translatables.map((file) => new TranslatablesModel(file))
    : [];
    this.title = handleTranslate(data.translatables)

  }
}

export default EventModel;
