import React, { forwardRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Controller } from 'react-hook-form';

/**
 * @Name: InputAsyncSelectComponent
 * @Data: 2019
 * @Desc: Async Select padrão do sistema.
 * @param  {} props - todas propriedades do select html e/ou quais outras forem necessárias.
 * @param  {} loadOptions - São as opções de seleção do componente
 * @param  {} defaults - defaults é um objeto literal que deverá obrigatóriamente ter como atributos *defaultName e *defaultValue
 * @param {} children - Implementação livre filha do select
 *
 * @obs Quando children for recebido como props, a implementação option padrão será ignorada.
 */

const InputAsyncSelectComponent = forwardRef((props, ref) => {
  const {
    control,
    name,
    items = [],
    onInputChange,
    multiple,
    message = 'Sem opções.',
    placeholder = 'Selecione',
    isClearable,
    defaultValue,
    className,
    required,
  } = props;

  const [timeoutSearch, setTimeoutSearch] = useState(null);
  const [loading, setLoading] = useState(false);

  //Estilos customizados do react-select
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: 'gray' },
      border: '1px solid lightgray',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: state.isSelected ? '#00698F' : '#fff',
      ':active': {
        backgroundColor: '#00698F',
        color: '#ffffff',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const filterOptions = async (inputValue) => {
    //props.

    //função para filtro
    // let filter = items.filter(
    //   (item) => item.label.toLowerCase() === inputValue.toLowerCase()
    // );

    // if (filter.length === 0) {
    setLoading(true);
    const data = await onInputChange(inputValue);
    setLoading(false);
    return data;
    // }
    // return filter;
  };

  const handleSearch = (inputValue) => {
    const delay = 800;
    return new Promise((resolve) => {
      clearTimeout(timeoutSearch);
      if (inputValue === '') return '';
      setTimeoutSearch(
        setTimeout(() => {
          resolve(filterOptions(inputValue));
        }, delay)
      );
    });
  };

  return (
    <div className="select-component">
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <AsyncSelect
            cacheOptions
            styles={customStyles}
            isClearable={isClearable}
            placeholder={placeholder}
            className={className}
            isMulti={multiple}
            required={required}
            noOptionsMessage={() => message}
            loadOptions={handleSearch}
            loading={loading}
            {...field}
          />
        )}
      />
    </div>
  );
});
export default InputAsyncSelectComponent;
