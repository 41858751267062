import FileModel from "./FileModel";
import BaseModel from "./BaseModel";

/**
 * Variações (Ex: Azul, vermelhor, amarelo)
 */
class ProductPropertyModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.propertyValue = data.propertyValue;
    this.displayString = data.displayString;
    this.file = data.file ? new FileModel(data.file) : undefined;
    this.viewType = data.viewType;
    // this.propertyClass = data.propertyClass
    //   ? new PropertyClassModel(data.propertyClass)
    //   : undefined;
  }

  // Retorna um array com o arquivo do cliente, caso exista.
  get fileProperty() {
    return this.file;
  }

  /** Add novo tipo de propriedade */
  // setPropertyClass(data) {
  //   this.propertyClass = new PropertyClassModel(data);
  // }

  // get uuidClass() {
  //   const uuid = this.propertyClass ? this.propertyClass.uuid : undefined;
  //   return uuid;
  // }
}
export default ProductPropertyModel;
