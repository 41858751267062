import React, { forwardRef } from 'react';

const InputClickableTextComponent = forwardRef((props, ref) => {
  const { id, onClick, text } = props;
  return (
    <div key={id} className="input-clickable-text" onClick={onClick}>
      {text}
    </div>
  );
});

export default InputClickableTextComponent;
