import React, { forwardRef } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { observer } from 'mobx-react';

const InputSelectAlternative = forwardRef((props, ref) => {
  const {
    control,
    name,
    items = [],
    multiple,
    message = 'Sem opções.',
    placeholder = 'Selecione',
    isClearable,
    defaultValue,
    className,
    required,
  } = props;

  //Estilos customizados do react-select
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: 'gray' },
      border: '1px solid lightgray',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: state.isSelected ? '#00698F' : '#fff',
      ':active': {
        backgroundColor: '#00698F',
        color: '#ffffff',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  if (control) {
    return (
      <div className="select-component" data-cy={props['data-cy']}>
        <Controller
          name={name}
          control={control}
          render={({ field, field: { onChange, value } }) => (
            <Select
              defaultValue={defaultValue}
              styles={customStyles}
              isClearable={isClearable}
              placeholder={placeholder}
              options={items}
              className={className}
              isMulti={multiple}
              required={required}
              noOptionsMessage={() => message}
              {...field}
              ref={ref}
              value={items.find((c) => c.value === value)}
              onChange={(val) => onChange(val.value)}
            />
          )}
        />
      </div>
    );
  } 
});
export default observer(InputSelectAlternative);