import React, { useEffect } from 'react';
import InputSelectComponent from '../InputSelectComponent/InputSelectComponent';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const InputSelectCategoryComponent = (props) => {
  const { categoryStore } = props;
  const { categoriesListSelect } = categoryStore;

  useEffect(() => {
    if(categoriesListSelect === undefined)
      categoryStore.getListCategorySelect();
    // }
  }, []);

  return (
    <InputSelectComponent
      items={categoriesListSelect}
      {...props}
    />
  )
};

export default inject(
  'categoryStore',
)(withRouter(observer(InputSelectCategoryComponent)));