import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

export default class TranslatablesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.language = data.language;
    this.uuid = data.uuid;
    this.name = data.name;
    this.brandLogo = data.brandLogo ? new FileModel(data.brandLogo) : undefined;
    this.keyName = data.keyName;
    this.language = data.language;
    this.value = data.value;
  }
}
