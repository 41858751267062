import React, { forwardRef, useEffect, useState } from 'react';

/**
 * @Name: InputTreeSelectComponent
 * @Data: 2019
 * @Desc: O componente que cria a arvores e permite que vc seleciona
 * @props:
 *
 */

const InputTreeSelectComponent = forwardRef((props, ref) => {
  const {
    categories,
    items = [],
    onSelect,
    multiple = true,
    selectAllParents = false,
    reset = false,
    isCollections = false,
    readOnly = false,
  } = props;

  const [selecteds, setCategories] = useState([]);
  const [parent, setParent] = useState(true);

  useEffect(() => {
    if (reset) {
      setCategories([]);
    }
  }, [reset]);

  useEffect(() => {
    if ((items.length > 0 && selecteds === null) || selecteds.length === 0) {
      setCategories(items);
    }
    if (items.length > 0) {
      setCategories(items);
    }
  }, [items, selecteds]);

  //Verifica selecionados
  const isSelected = (uuidCategory) =>
    selecteds.some((item) => item.uuid === uuidCategory);

  //Seleciona todos os pais da categoria selecionada
  const selectParents = (selected) => {
    const selecteds = [];
    let currentSelected = selected;
    while (currentSelected.parentCategory) {
      selecteds.push(currentSelected.parentCategory);
      currentSelected = currentSelected.parentCategory;
    }
    return selecteds;
  };

  const selectAllChildren = (parent) => {
    const childrens = [];
    let currentSelected = parent;
    while (currentSelected.childrenCategory) {
      childrens.push(currentSelected.childrenCategory);
      currentSelected = currentSelected.childrenCategory;
    }
    return childrens.length > 0 && childrens[0];
  };

  //**Select */
  const handleClicked = (e, selected) => {
    if (readOnly) return;
    e.preventDefault();
    let list = [];
    if (!multiple) {
      if (selecteds.some((s) => s.uuid === selected.uuid)) {
        list = [];
      } else {
        list.push(selected);
        if (selectAllParents && selected.parentCategory) {
          list.push(...selectParents(selected));
        }
      }
    } else if (!isCollections) {
      //Verifica se já está na lista, se sim remove. senão adiciona.
      if (selecteds.some((s) => s.uuid === selected.uuid)) {
        list = selecteds.filter((r) => r.uuid !== selected.uuid);
      } else list = [...selecteds, selected];
    }

    if (isCollections) {
      if (selecteds.some((s) => s.uuid === selected.uuid)) {
        list = selecteds.filter((r) => r.uuid !== selected.uuid);
      } else list = [...selecteds, selected];

      if (selectAllParents && selected.parentCategory) {
        setParent(false);
        list.push(...selectParents(selected));
      }

      if (selected?.childrenCategory.length > 0 && selectAllParents) {
        setParent(!parent);
        if (parent) {
          list.push(...selectAllChildren(selected));
        } else {
          list = [];
        }
      }
    }

    setCategories(list);
    onSelect && onSelect(list);
  };

  const getTreeCategories = (item) => {
    const hasChildren = item.childrenCategory.length > 0;
    return (
      <ul key={item.uuid}>
        <li id="category-list-children">
          {' '}
          <span
            className={isSelected(item.uuid) ? 'selected' : ''}
            onClick={(e) => handleClicked(e, item)}
          >
            <i className="fa fa-folder" />
            {item.categoryName}
          </span>
          {hasChildren &&
            item.childrenCategory.map((child) => getTreeCategories(child))}
        </li>
      </ul>
    );
  };

  return (
    <>
      <div className="tree">
        <ul>
          {categories.map((category, index) => (
            <li key={category.uuid} id="category-list">
              <span
                data-cy={props['data-cy'] + index}
                className={isSelected(category.uuid) ? 'selected' : ''}
                onClick={(e) => handleClicked(e, category)}
              >
                <i className="fa fa-folder" />
                {category.categoryName}
              </span>
              {category.childrenCategory.length > 0 ? (
                <>
                  {category.childrenCategory.map((child) =>
                    getTreeCategories(child)
                  )}
                </>
              ) : (
                <></>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
});

export default InputTreeSelectComponent;
