import React, { forwardRef } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { observer } from "mobx-react";

/**
 * @Name: InputSelectComponent
 * @Data: 2019
 * @Desc: Select padrão do sistema.
 * @param  {} props - todas propriedades do select html e/ou quais outras forem necessárias.
 * @param  {} items - São as opções de seleção do componente
 * @param  {} defaults - defaults é um objeto literal que deverá obrigatóriamente ter como atributos *defaultName e *defaultValue
 * @param {} children - Implementação livre filha do select
 *
 * @obs Quando children for recebido como props, a implementação option padrão será ignorada.
 */

const InputSelectValue = forwardRef((props, ref) => {
  const {
    control,
    name,
    items = [],
    multiple,
    message = "Sem opções.",
    placeholder = "Selecione",
    isClearable,
    defaultValue,
    value,
    className,
    required,
  } = props;

  //Estilos customizados do react-select
  const customStyles = {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "gray" },
      border: "1px solid lightgray",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      border: "none",
      backgroundColor: state.isSelected ? "#00698F" : "#fff",
      ":active": {
        backgroundColor: "#00698F",
        color: "#ffffff",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  if (control) {
    return (
      <div className="select-component">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              ref={ref}
              defaultValue={defaultValue}
              styles={customStyles}
              isClearable={isClearable}
              placeholder={placeholder}
              options={items}
              className={className}
              isMulti={multiple}
              required={required}
              noOptionsMessage={() => message}
              {...field}
              value={value ? value : undefined}
            />
          )}
        />
      </div>
    );
  } else {
    return (
      <>
        <Select
          ref={ref}
          defaultValue={defaultValue}
          styles={customStyles}
          isClearable={isClearable}
          placeholder={placeholder}
          options={items}
          className={className}
          isMulti={multiple}
          required={required}
          noOptionsMessage={() => message}
          {...props}
          value={value ? value : undefined}
        />
      </>
    );
  }
});
export default observer(InputSelectValue);
