import BaseAPI, { URLS } from "./BaseAPI";

class VideoAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.VIDEO}/categories`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de videos" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de videos" };
    }
  }

  static async getFeaturedVideos(params) {
    try {
      const response = await BaseAPI.get(`${URLS.VIDEO}/featured`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de videos em destaque" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar a lista de videos em destaque" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.VIDEO, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: "Falha ao salvar video" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao salvar video" };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.VIDEO}/category/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar video" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao atualizar video" };
    }
  }

  static async updateFeatured(data) {
    try {
      const response = await BaseAPI.put(`${URLS.VIDEO}/featured`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar videos" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao atualizar videos" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.VIDEO}/category/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar video" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao carregar video" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.VIDEO}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar video" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar video" };
    }
  }

  static async removeImage(uuid, imageUuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.VIDEO}/${uuid}/image/${imageUuid}`
      );

      return response.status === 204 || response.status === 200
        ? response.data
        : { error: "Falha ao deletar imagem do video" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao deletar imagem do video" };
    }
  }
}

export default VideoAPI;
