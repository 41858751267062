import { extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

import VideoAPI from "../services/VideoAPI.js";
import UploadAPI from "../services/UploadAPI";

import VideoModel from "../models/VideoModel";
import { v4 as uuidv4 } from "uuid";

class VideoStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      videosList: [],
      videoListSelect: undefined,
      video: undefined,
      videoCopy: undefined,
      videoFile: {},
      videoUuid: undefined,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "language",
      filters: {},
      loading: false,
      featuredVideosList: [],
    });
  }

  reset() {
    this.videosList = [];
    this.videoListSelect = undefined;
    this.video = undefined;
    this.videoCopy = undefined;
    this.videoFile = {};
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "language";
    this.loading = false;
    this.featuredVideosList = [];
  }

  initializeVideo() {
    this.video = new VideoModel();
    this.videoCopy = new VideoModel();
    this.videoFile = {};
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await VideoAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.videosList = [];
      return;
    }

    this.videosList = response.content.map((video) => new VideoModel(video));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async getFeatured(size = 20, page = this.page, filters = this.filters) {
    this.loading = true;
    const response = await VideoAPI.getFeaturedVideos({
      size,
      page,
      ...filters,
    });
    this.loading = false;
    this.featuredVideosList = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setFiltersFeatured(filters) {
    await this.getFeatured(this.size, this.page, filters);
  }

  async setPageFeatured(page) {
    await this.getFeatured(this.size, page);
  }

  async setPage(page) {
    this.page = page;
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async setFiltersVideos(filters) {
    this.videoCopy = { ...this.video };
    const tempVideo = { ...this.videoCopy };
    if (filters !== "") {
      const videos = this.video?.videos.filter(
        (item) =>
          item?.name?.toLowerCase().includes(filters?.toLowerCase()) ||
          item?.url?.toLowerCase().includes(filters?.toLowerCase())
      );
      tempVideo.videos = videos;
      this.videoCopy = new VideoModel(tempVideo);
    } else {
      this.videoCopy = { ...this.video };
    }
  }

  async saveVideo(uuid) {
    this.videoUuid = uuid;
  }

  async uploadFiles(files) {
    const promisses = files
      .filter((item) => item.attachment)
      .map(async (item) => {
        const reponse = await UploadAPI.saveFile(item.attachment, "video");

        return {
          file: {
            "@class": "br.com.stoom.clickstarrett.model.ClickFile",
            uuid: reponse?.data?.uuid,
          },
          name: item?.name,
          ordering: item?.ordering,
          shortDescription: item?.subDescription,
          description: item?.description,
          url: item?.url,
          downloadLink: item?.downloadLink,
          featured: item?.featured,
        };
      });

    const responses = await Promise.all(promisses);

    if (responses.filter((el) => el.error).length > 0) {
      await this.roollbackFiles(responses.filter((el) => !el.error));
      throw new Error(
        responses
          .filter((el) => el.error)
          .map((el) => el.error)
          .join("; ")
      );
    }

    return responses;
  }

  async roollbackFiles(files) {
    try {
      const promisses = files.map((file) =>
        UploadAPI.removeFile(file.file.uuid)
      );
      await Promise.all(promisses);
    } catch (error) {
      console.log("roollbackFiles error", error);
    }
  }

  async save() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;
    const filesResponses = await this.uploadFiles(this.video.files);
    const data = JSON.stringify({
      ...this.video,
      files: filesResponses.filter((file) => !file.error),
      merchant,
    });

    const response = await VideoAPI.save(data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    }

    this.loading = false;
    return response;
  }

  async update() {
    this.loading = true;

    const filesResponses = await this.uploadFiles(this.video.videos);
    const videos = this.video.videos.filter((file) => !file.attachment);
    const newVideos = filesResponses.filter((file) => !file.error);
    const data = JSON.stringify({
      ...this.video,
      videos: videos.concat(newVideos),
    });

    const response = await VideoAPI.update(this.video.uuid, data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
    }

    return {};
  }

  async updateFeatured() {
    this.loading = true;

    const data = JSON.stringify([
      ...this.featuredVideosList,
    ]);

    const response = await VideoAPI.updateFeatured(data);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
    }

    return {};
  }

  async get(uuid) {
    this.loading = true;
    const response = await VideoAPI.get(uuid);
    this.loading = false;
    this.video = new VideoModel(response);
    this.videoCopy = new VideoModel(response);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await VideoAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  async addFile() {
    const tempVideo = this.video;
    tempVideo.videos.push({
      tempUuid: uuidv4(),
      attachment: this.videoFile.video_file_file,
      description: this.videoFile.video_file_description,
      url: this.videoFile.video_file_link,
      downloadLink: this.videoFile.video_file_link_download,
      name: this.videoFile.video_file_name,
      ordering: this.videoFile.video_file_ordering,
      shortDescription: this.videoFile.video_file_subDescription,
      featured: false,
    });

    this.video = new VideoModel(tempVideo);
    this.videoCopy = { ...this.video };
    this.videoFile = {};
  }

  removeFile(file) {
    const tempVideo = this.video;
    tempVideo.videos = tempVideo.videos.filter(
      (item) => item.uuid !== file.uuid || item.tempUuid !== file.tempUuid
    );
    this.video = new VideoModel(tempVideo);
    this.videoCopy = { ...this.video };
  }

  setFeaturedVideo(videoSelected, value) {
    const tempVideos = this.video.videos.map((item) => {
      if (item?.uuid) {
        if (item?.uuid === videoSelected?.uuid) {
          return { ...item, featured: value };
        }
      } else if (item?.tempUuid) {
        if (item?.tempUuid === videoSelected?.tempUuid) {
          return { ...item, featured: value };
        }
      }
      return item;
    });
    this.video.videos = [...tempVideos];
  }

  setFeaturedVideoPage(videoSelected, value) {
    const tempVideos = this.featuredVideosList.map(item => {
      if (item === videoSelected) {
        return { ...item, featured: value };
      }
      return item;
    });
    this.featuredVideosList = [...tempVideos];
  }

  onChangeVideoFile(prop, value) {
    switch (prop) {
      default: {
        this.videoFile[prop] = value;
      }
    }
  }

  onChange(prop, value) {
    const tempVideo = this.video;
    switch (prop) {
      case "video_file_file":
      case "video_file_name":
      case "video_file_ordering":
      case "video_file_link":
      case "video_file_link_download":
      case "video_file_subDescription":
      case "video_file_description":
        this.onChangeVideoFile(prop, value);
        return;
      case "ordering":
        tempVideo["ordering"] = parseInt(value);
        break;
      default:
        tempVideo[prop] = value;
    }

    this.video = new VideoModel(tempVideo);
  }
}

export default VideoStore;
