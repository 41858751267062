export const ComponentHomeTypesEnum = Object.freeze({
  BANNER_CAROUSEL: "BANNER_CAROUSEL",
  CATEGORY_CAROUSEL: "CATEGORY_CAROUSEL",
  PRODUCT_CAROUSEL: "PRODUCT_CAROUSEL",
  // INSTITUTIONAL_BANNER: "INSTITUTIONAL_BANNER",// Noticias não mudar
  // MINI_BANNER: "MINI_BANNER",
  // CUSTOMER_PRODUCT_HISTORY: "CUSTOMER_PRODUCT_HISTORY",
  DEPARTMENTS_LIST: "DEPARTMENTS_LIST",
  // LINX_RECOMMENDATIONS: "LINX_RECOMMENDATIONS",
  // FIXED_BANNER: "FIXED_BANNER",
  // TOP_BANNER: "TOP_BANNER",
});
