import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useDropzone } from "react-dropzone";

const InputDropzoneComponent = forwardRef((props, ref) => {
  const {
    message = "Arraste um arquivo, ou clique...",
    accept = "image/*",
    disabled = false,
    items,
    multiple = false,
    children,
    deleteImage,
    onChange,
    maxSize = 5,
    minSize = 0,
    file,
    removeDelete,
  } = props;

  //estados de arquivos (hooks)
  const [files, setFiles] = useState(file?.length > 0 ? file : []);
  const [fileErros, setErrors] = useState([]);
  // const [loadDefault, setLoad] = useState(true);

  /**Cria preview de imagem */
  const createPreview = (values) =>
    values.map((file) =>
      file.preview
        ? file
        : Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
    );

  /*const cleanFiles = useCallback(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files]);*/

  //On update
  useEffect(() => {
    if (items) {
      const values = Array.isArray(items) ? items : [items];
      setFiles(values);
    }
  }, [items]);

  //Evento após imagem ser arrastada (add no array de imagens)
  const onDrop = useCallback(
    (acceptedFiles, rejecteds) => {
      if (acceptedFiles?.length > 0) {
        const accepteds = createPreview(acceptedFiles);
        const values = [...accepteds];
        setFiles(values);
        onChange && onChange(accepteds);
      } else if (rejecteds?.length > 0) setErrors(rejecteds);
      //onChange && onChange(accepteds);
    },
    [onChange]
  );

  //Remove image from preview
  const onRemove = (e, fileRemove) => {
    if (deleteImage && fileRemove.uuid) {
      deleteImage(fileRemove);
    } else {
      const copyArray = [].concat(files);
      URL.revokeObjectURL(fileRemove.preview);
      copyArray.splice(copyArray.indexOf(fileRemove), 1);
      setFiles(copyArray);
      onChange && onChange(copyArray);
    }
  };

  /**Verificação se é imagem */
  const isImage = () => accept.includes("image");
  const extentionFile = (file) =>
    file.type ? file.type.slice(-3) : file.name.slice(-3);

  //Dropzoneconfig
  const maxMb = maxSize * 1024 * 1024;
  const minMb = minSize * 1024 * 1024;
  const { getRootProps, getInputProps } = useDropzone({
    accept: `${accept}`,
    onDrop,
    maxSize: maxMb,
    minSize: minMb,
  });

  return !files ? (
    <></>
  ) : (
    <section className="container container-uploaded">
      {/**Quando uma imagem for inputada */}

      {fileErros?.length > 0 && console.log(fileErros)}

      {!multiple && files?.length > 0 ? (
        files.map((file, index) => (
          <>
            <div
              className={removeDelete ? "thumb-view" : "thumb"}
              key={index}
              onClick={(e) => !disabled && onRemove(e, file)}
            >
              <div className={removeDelete ? "thumb-view-only" : "thumb-inner"}>
                {isImage(file) ? (
                  <div className="preview-image-upload">
                    <img
                      className="image-uploaded"
                      alt="content"
                      src={file.preview}
                    />
                  </div>
                ) : (
                  <div className="preview-file-upload">
                    <div className="file-preview">
                      <div>
                        <i className="far fa-file" />
                      </div>
                      {disabled ? (
                        <a
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.path}
                        >
                          <div className="extention-file">
                            {extentionFile(file)}
                          </div>
                        </a>
                      ) : (
                        <div className="extention-file">
                          {extentionFile(file)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!removeDelete ? (
                  <>
                    <div className="icon-remove">
                      <i className="fas fa-trash-alt" />
                    </div>
                    <div className="info-file">
                      <div className="limited-file-name">{file.name}</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ))
      ) : (
        <>
          {/**Comp dropzone */}
          <div {...getRootProps({ className: "dropzone container-dropzone" })}>
            <div className="container-add-image">
              <input  data-cy={props['data-cy']} {...getInputProps()} multiple={multiple} />
              {children || (
                <>
                  <span>{message}</span>
                  {/* <i className="fas fa-cloud-upload-alt" /> */}
                  <div className="add-image">
                    <span>ADICIONAR IMAGEM</span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/** Mostra imagem logo abaixo do input do dropzone. */}
          {multiple && files?.length > 0 ? (
            files.map((file, index) => (
              <div
                key={index}
                className="thumb"
                onClick={(e) => !disabled && onRemove(e, file)}
              >
                <div className="thumb-inner">
                  {isImage(file) ? (
                    <div className="preview-image-upload">
                      <img
                        className="image-uploaded"
                        alt="content"
                        src={file.preview}
                      />
                    </div>
                  ) : (
                    <div className="preview-file-upload">
                      <div className="file-preview">
                        <div>
                          <i className="far fa-file" />
                        </div>
                        {disabled ? (
                          <a
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            href={file.path}
                          >
                            <div className="extention-file">
                              {extentionFile(file)}
                            </div>
                          </a>
                        ) : (
                          <div className="extention-file">
                            {extentionFile(file)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="icon-remove">
                    <i className="fas fa-trash-alt" />
                  </div>
                  <div className="info-file">
                    <div className="limited-file-name">{file.name}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      )}
    </section>
  );
});

export default InputDropzoneComponent;
