import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import FiltersComponent from "~/components/FiltersComponent";
import TitlePageComponent from "~/components/TitlePageComponent/TitlePageComponent";
import CardPainelComponent from "~/components/CardPainelComponent/CardPainelComponent";
import TableDinamicComponent from "~/components/TableDinamicComponent/TableDinamicComponent";
import TypeTableEnum from "~/helpers/utils/enums/TypeTableEnum";
import { InputTypes } from "~/helpers/utils/InputTypes";
import ModalConfirmationComponent from "~/components/ModalConfirmationComponent/ModalConfirmationComponent";
import { ModalIdsEnum } from "~/helpers/utils/enums/ModalIdsEnum";
import { statusFilterTypes } from "../../helpers/utils/Selects";

const ManagerOurStoresContainer = ({ history, ourStoresStore }) => {
  const routerPath = process.env.REACT_APP_ROUTE_PATH || ''
  const [ourStoreSelected, setOurStoreSelected] = useState();

  useEffect(() => {
    ourStoresStore.getList();
    ourStoresStore.getChannels(false);
    return () => {
      ourStoresStore.reset();
    };
  }, []);

  const newOurStoreRedirect = () => {
    history.push(`${routerPath}/cadastrar-loja`);
  };

  const editOurStoreRedirect = (item) => {
    history.push(`${routerPath}/editar-loja/${item.uuid}`);
  };

  const onFilter = (filters) => {
    ourStoresStore.setFilters({
      ...filters,
      name: filters.name ? filters.name : null,
      channelSalesRepresentativeUuid: filters.channelSalesRepresentativeUuid ? filters.channelSalesRepresentativeUuid.value : null,
      active: filters.active ? filters.active.value : null,
    });
  };

  const openDeleteModal = async (e) => {
    setOurStoreSelected(e);

    window.$(`#${ModalIdsEnum.DELETE_MANAGER}`).modal("show");
  };

  const onDelete = async () => {
    if (ourStoreSelected) {
      const response = await ourStoresStore.delete(ourStoreSelected.uuid);
      if (!response?.error) {
        ourStoresStore.getList();
        window.$(`#${ModalIdsEnum.DELETE_MANAGER}`).modal("hide");
      }
    }
  };

  const columns = [
    { heading: "Nome", fieldKey: "name", type: TypeTableEnum.LINE },
    {
      heading: "Data de criação",
      fieldKey: "created",
      type: TypeTableEnum.DATE,
    },
    {
      heading: "Status",
      fieldKey: "active",
      type: TypeTableEnum.BOOLEAN,
      textOn: "Ativo",
      textOff: "Inativo",
    },
    {
      heading: "Editar",
      type: TypeTableEnum.ICON,
      icon: "fa fa-edit",
      action: editOurStoreRedirect,
    },
    {
      heading: "Deletar",
      type: TypeTableEnum.ICON,
      icon: "fa fa-trash",
      action: openDeleteModal,
    },
  ];

  const { ourStoresList, loading } = ourStoresStore;

  return (
    <>
      <TitlePageComponent
        title="Lojas"
        buttonTitle="Cadastrar"
        clickButton={{ click: newOurStoreRedirect }}
      />
      <CardPainelComponent>
        <FiltersComponent
          onFilter={onFilter}
          group={{
            cols: 12,
            fields: [
              {
                id: "name",
                name: "name",
                label: "Nome",
                required: false,
                size: 6,
                type: InputTypes.TEXT,
              },
              {
                id: "active",
                name: "active",
                label: "Status",
                required: false,
                size: 6,
                type: InputTypes.SELECT,
                items: statusFilterTypes,
              },
            ],
          }}
        />
      </CardPainelComponent>

      {!loading && (
        <CardPainelComponent title="Lojas cadastradas">
          <TableDinamicComponent
            columns={columns}
            items={ourStoresList}
            totalPages={ourStoresStore.page}
            initialPage={ourStoresStore.totalPages}
            onChangePage={(page) => ourStoresStore.setPage(page)}
            onSort={(sort) => ourStoresStore.setSort(sort)}
            initialSort={ourStoresStore.sort}
          />
        </CardPainelComponent>
      )}

      {loading && (
        <CardPainelComponent loading={loading}>
          <div className="box-loading"></div>
        </CardPainelComponent>
      )}

      <ModalConfirmationComponent
        subtitle={
          <p>
            Você deseja excluir a loja <strong>{ourStoreSelected?.name}</strong>
            ?
          </p>
        }
        id={ModalIdsEnum.DELETE_MANAGER}
        onYesClick={onDelete}
      />
    </>
  );
};

export default inject(
  "ourStoresStore",
    "menuStore"
)(withRouter(observer(ManagerOurStoresContainer)));
