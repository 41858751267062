import { assistanceTypes } from "../helpers/utils/Selects";
import AddressModel from "./AddressModel";
import BaseModel from "./BaseModel";

class AssistanceModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.uuid = data.uuid;
    this.name = data.name;
    this.type = data.type;
    this.typeLabel = data.uuid
      ? assistanceTypes.filter((item) => item.value === data.type)[0]?.label
      : undefined;
    this.address = data.address ? new AddressModel(data.address) : {};
    this.primaryEmail = data.primaryEmail;
    this.secondaryEmail = data.secondaryEmail;
    this.primaryPhone = data.primaryPhone;
    this.secondaryPhone = data.secondaryPhone;
    this.channelSalesRepresentative = data.channelSalesRepresentative;
    this.foodTechnician = data.foodTechnician;
    this.starrettTechnician = data.starrettTechnician;
  }
}

export default AssistanceModel;
