import { convertPrice } from "~/helpers/utils/Functions";
import BaseModel from "./BaseModel";
import CategoryModel from "./CategoryModel";
import FileModel from "./FileModel";
// import ItemsCombo from './ItemsCombo';
import MerchantModel from "./MerchantModel";
import PriceModel from "./PriceModel";
import ProductPropertyModel from "./ProductPropertyModel";
import StockModel from "./StockModel";
import SupplierModel from "./SupplierModel";
import ColorModel from "./ColorModel";
import SizeModel from "./SizeModel";
import RecommendedProductsModel from "./RecommendedProductsModel";
import CompetitorProductsModel from "./CompetitorProductsModel";

export default class ProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.clickstarrett.model.ClickProduct";
    this.idProduct = data.idProduct;
    this.externalId = data.externalId;
    this.icmsStSp = data.icmsStSp;
    this.ipi = data.ipi;
    this.ncm = data.ncm;
    this.cest = data.cest;
    this.cst = data.cst;
    this.mto = data.mto;
    this.mta = data.mta;
    this.density = data.density;
    this.cubage = data.cubage;
    this.parent = data.parent;
    this.linkProduct = data.linkProduct;
    this.skuCode = data.skuCode;
    this.barcode = data.barcode;
    this.classOnu = data.classOnu;
    this.brand = data.brand;
    this.plasticPackagedWeight = data.plasticPackagedWeight;
    this.aluminumPackagedWeight = data.aluminumPackagedWeight;
    this.cardboardPackagedWeight = data.cardboardPackagedWeight;
    this.metalPackagedWeight = data.metalPackagedWeight;
    this.moq = data.moq;

    this.recommendedProducts = data.stocks
      ? data.recommendedProducts.map(
          (props) => new RecommendedProductsModel(props)
        )
      : [];

    this.competitorProducts = data.stocks
      ? data.competitorProducts.map(
          (props) => new CompetitorProductsModel(props)
        )
      : [];

    this.accessories = data.accessories;
    this.accessoriesType = data.accessoriesType;
    this.professionalUse = data.professionalUse;
    this.industrialUse = data.industrialUse;
    this.hobbyistUse = data.hobbyistUse;

    this.name = data.name;
    this.shortName = data.shortName;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.metaTitle = data.metaTitle;
    this.metaKeywords = data.metaKeywords;
    this.metaDescription = data.metaDescription;
    this.metaCanonical = data.metaCanonical;
    this.slug = data.slug;
    this.sendStockEmail = data.sendStockEmail;
    this.quantityStockEmail = data.quantityStockEmail;
    this.redirect = data.redirect;
    this.display = data.display !== undefined ? data.display : true;
    this.searchable = data.searchable !== undefined ? data.searchable : true;
    this.supplier = data.supplier
      ? new SupplierModel(data.supplier)
      : undefined;
    this.supplierNumber = data.supplierNumber;
    this.currency = data.currency ? data.currency : "BRL";
    this.fakePrice = data.fakePrice;
    this.absoluteDiscount = data.absoluteDiscount;
    this.percentualDiscount = data.percentualDiscount;
    this.discountBeginDate =
      data.discountBeginDate && new Date(data.discountBeginDate);
    this.discountEndDate =
      data.discountEndDate && new Date(data.discountEndDate);
    this.releaseDate = data.releaseDate && new Date(data.releaseDate);
    this.weight = data.weight;
    this.height = data.height;
    this.width = data.width;
    this.length = data.length;
    this.cst = data.cst;
    this.shippingWeight = data.shippingWeight;
    this.shippingHeight = data.shippingHeight;
    this.shippingWidth = data.shippingWidth;
    this.shippingLength = data.shippingLength;
    this.stocks = data.stocks
      ? data.stocks.map((props) => new StockModel(props))
      : [];
    this.prices = data.prices
      ? data.prices.map((props) => new PriceModel(props))
      : [];

    this.color = data.color ? new ColorModel(data.color) : null;
    this.size = data.size ? new SizeModel(data.size) : null;
    this.committedStock = data.committedStock;
    this.securityStock = data.securityStock;
    this.deliveryTime = data.deliveryTime;
    this.stockUnit = data.stockUnit;
    this.merchant = new MerchantModel(data.merchant);
    this.productVariations = data.productVariations
      ? data.productVariations.map((prop) => new ProductModel(prop))
      : [];
    this.productProperties = data.productProperties
      ? data.productProperties.map((prop) => new ProductPropertyModel(prop))
      : [];

    this.alternativeProduct = data.alternativeProduct;

    // this.alternativeProduct = data.alternativeProduct
    //   ? data.alternativeProduct.map((prop) => new AlternativeProductModel(prop))
    //   : [];

    // this.itemsCombo = data.itemsCombo
    //   ? data.itemsCombo.map((prop) => new ItemsCombo(prop))
    //   : [];

    this.baseProduct = data.baseProduct;
    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];

    this.minAmountSales = data.minAmountSales;
    this.warehouse = data.warehouse;

    this.heightIndividualCm = data.heightIndividualCm;
    this.lengthIndividualCm = data.lengthIndividualCm;
    this.widthIndividualCm = data.widthIndividualCm;
    this.weightIndividualGrams = data.weightIndividualGrams;
    this.heightPackageCm = data.heightPackageCm;
    this.lengthPackageCm = data.lengthPackageCm;
    this.widthPackageCm = data.widthPackageCm;
    this.weightPackageGrams = data.weightPackageGrams;
    this.heightMasterBoxCm = data.heightMasterBoxCm;
    this.lengthMasterBoxCm = data.lengthMasterBoxCm;
    this.widthMasterBoxCm = data.widthMasterBoxCm;
    this.weightMasterBoxGrams = data.weightMasterBoxGrams;
    this.masterBoxCode = data.masterBoxCode;
    this.masterBoxAmount = data.masterBoxAmount;
    this.alternativeProduct = data.alternativeProduct;
    this.amountRto = data.amountRto;
    this.nationalOrImported = data.nationalOrImported;
    this.multipleSales = data.multipleSales;
    this.descriptionFunctionalities = data.descriptionFunctionalities;
    this.descriptionCharacteristics = data.descriptionCharacteristics;
    this.descriptionApplication = data.descriptionApplication;
    this.descriptionTechnique = data.descriptionTechnique;
    this.palletAmount = data.palletAmount;
    this.warrantyTimeLabor = data.warrantyTimeLabor;
    this.warrantyTimeUsage = data.warrantyTimeUsage;
    this.materialType = data.materialType;
    this.manufacturer = data.manufacturer;
    this.dateIntegration = data.dateIntegration;
    this.created = data.created;
    this.operationType = data.operationType;

    this.translatables = data.translatables
      ? data.translatables.map((file) => new TranslatablesModel(file))
      : [];

    /*this.files = data.files
      ? data.files.reduce((list, file) => {
        if (!list.some((lFile) => lFile.metaTags[0] === file.metaTags[0])) {
          list.push(new FileModel(file));
        }
        return list;
      }, [])
      : [];*/

    this.categories = data.categories
      ? data.categories.map((cat) => new CategoryModel(cat))
      : [];

    // NEW ITEMS
    this.fiscalClassificationCode = data.fiscalClassificationCode;
    this.icms4Perc = data.icms4Perc;
    this.icms7Perc = data.icms7Perc;
    this.icms12Perc = data.icms12Perc;
    this.icms18Perc = data.icms18Perc;
    this.barcode = data.barcode;
    this.ipi8Perc = data.ipi8Perc;
    this.obsIcms = data.obsIcms;
    this.translatables = data.translatables
      ? data.translatables.map((cat) => new TranslatablesModel(cat))
      : [];
  }

  get supplierName() {
    return this.supplier ? this.supplier.name : "";
  }

  getFile(tag) {
    return this.files.find(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFirstFile() {
    return this.files?.length > 0 ? this.files[0] : null;
  }

  getOthersFiles() {
    return this.files?.length > 0 ? this.files.slice(1) : null;
  }

  /** Preço formatado em string R$99999,9999 */
  get fakePriceString() {
    if (this.fakePrice) return convertPrice(this.fakePrice);
    return undefined;
  }

  /** Desconto absoluto formatado em string R$999999,99 */
  get absoluteDiscountString() {
    if (this.absoluteDiscount) return convertPrice(this.absoluteDiscount);
    return "";
  }

  /** Add novo supplier */
  setSupplier(supplier) {
    this.supplier = new SupplierModel(supplier);
  }

  get hasFiles() {
    return this.files.length > 0;
  }
}

class TranslatablesModel {
  constructor(data = {}) {
    this.created = data.created;
    this.active = data.active;
    this.deleted = data.deleted;
    this.keyName = data.keyName;
    this.language = data.language;
    this.modified = data.modified;
    this.uuid = data.uuid;
    this.value = data.value;
  }
}
