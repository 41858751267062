import { BannerTagEnum } from "./enums/BannerTagEnum";
import { ComponentHomeTypesEnum } from "./enums/ComponentHomeTypesEnum";

/**Status */
export const maritalStatus = [
  { id: "SOLTEIRO", value: "SOLTEIRO", label: "Solteiro(a)" },
  { id: "CASADO", value: "CASADO", label: "Casado(a)" },
  { id: "VIUVO", value: "VIUVO", label: "Viúvo(a)" },
  { id: "OUTRO", value: "OUTRO", label: "Outro" },
];

export const unityTypes = [
  { id: "UN", value: "UN", label: "UN" },
  { id: "CX", value: "CX", label: "CX" },
];

/**Sexos */
export const genders = [
  { id: "M", value: "MASCULINO", label: "Masculino" },
  { id: "F", value: "FEMININO", label: "Feminino" },
  { id: "O", value: "OUTROS", label: "Outros" },
];

/**Tipos de entidade */
export const entityTypes = [
  { id: "F", value: "FISICA", label: "Física" },
  { id: "J", value: "JURIDICA", label: "Jurídica" },
];

/**Status**/
export const statusTypes = [
  { id: "active", value: true, label: "Ativo" },
  { id: "inactive", value: false, label: "Inativo" },
];

/**Tipos de moeda */
export const currency = [{ id: "BRL", value: "BRL", label: "R$ - Real" }];

/**Tipos de categoria */
export const categoryTypes = [{ value: "VITRINE", label: "Vitrine" }];

/**Tipos de categoria */
export const comparativeTypes = [
  { id: "TEXT", value: "TEXT", label: "Texto" },
  { id: "BOOLEAN", value: "BOOLEAN", label: "Boolean" },
];

/**Tags para Banner */
export const tagsBanners = [
  { label: "Slideshow Principal", value: "SLIDESHOW_PRINCIPAL" }, // Imagens a vontade, pode criar a vontade
  // { label: 'Slideshow Principal Mini', value: 'SLIDESHOW' }, // Imagens a vontade, pode criar a vontade
  { label: "Imagens Home", value: "IMAGENS_HOME" }, // Regra Esp. abrir drop. selecionar a disposição da imagens com 1, ou mais...até 6 imagens
  // { label: 'Carrinho', value: 'CARRINHO' }, // Até 1 Imagem
  // { label: 'Vitrine (PLP)', value: 'VITRINE_PLP' }, // uma imagem, e após selecionar é preciso abrir o dropwdown de categoria, o label
  // { label: 'Checkout', value: 'CHECKOUT' }, // Até 1 Imagem
  { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem
  { label: "Banner Parceiros", value: "BANNER_PARCEIROS" }, // Imagens a vontade, pode criar a vontade
  // { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem
  // { label: "Banner Parceiros", value: "BANNER_PARCEIROS" }, // Imagens a vontade, pode criar a vontade
  { label: "Vídeo Home", value: "VIDEO_HOME" }, // Até 1 vídeo
  { label: "Banner Catálogo", value: "BANNER_CATALOGO" }, // Até 1 imagem + texto
  { label: "Marcas", value: "MARCAS" },
];

/**Tags para usar no filtro Banner */
export const tagsFilterBanners = [
  { label: "Todas", value: "all" },
  { label: "Slideshow Principal", value: "SLIDESHOW_PRINCIPAL" }, // Imagens a vontade, pode criar uma ativa um de cada (responsivo e não responsivo)
  // { label: 'Slideshow Principal Mini', value: 'SLIDESHOW' }, // Imagens a vontade, pode criar a vontade
  { label: "Imagens Home", value: "IMAGENS_HOME" }, // Até 6 imagens, pode criar umas um de cada (responsivo e não responsivo)
  // { label: 'Carrinho', value: 'CARRINHO' }, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  // { label: 'Vitrine (PLP)', value: 'VITRINE_PLP' }, // Uma imagem, tem que adicionar categoria, pode criar uma ativa um de cada categoria (responsivo e não responsivo)
  // { label: 'Checkout', value: 'CHECKOUT' }, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  { label: "Vídeo Home", value: "VIDEO_HOME" }, // Até 1 vídeo
  { label: "Banner Catálogo", value: "BANNER_CATALOGO" }, // Até 1 imagem + texto
  // { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  { label: "Marcas", value: "MARCAS" },
];

/**Tipos de categoria com coleção*/
export const categoryTypesWithCollection = [
  { id: "VITRINE", value: "VITRINE", label: "Vitrine" },
  { id: "COLECAO", value: "COLECAO", label: "Coleção" },
];

export const tagsImageHome = [
  { label: "Banner 01", value: "BANNER_01" },
  { label: "Banner 02", value: "BANNER_02" },
];

export const confirmTypes = [
  { id: true, value: true, label: "Sim" },
  { id: false, value: false, label: "Não" },
];

export const statusFilterTypes = [
  { id: "all", value: "", label: "Todos" },
  { id: "ativo", value: true, label: "Ativo" },
  { id: "inativo", value: false, label: "Inativo" },
];

export const bannerTagsTypes = [
  {
    label: 'Slideshow',
    value: 'SLIDESHOW_PRINCIPAL'
  },
  {
    label: 'Blog',
    value: 'BLOG'
  },
  {
    label: 'Imagem',
    value: 'IMAGENS_HOME'
  },
  {
    label: 'Imagem largura total',
    value: 'IMAGENS_FULL'
  },
  {
    label: 'Banner Parceiros',
    value: 'BANNER_PARCEIROS'
  },
  {
    label: 'Categorias',
    value: 'CATEGORY_BANNER'
  },
  {
    label: 'Vídeo',
    value: 'VIDEO_HOME'
  },
  {
    label: 'Catálogo',
    value: 'BANNER_CATALOGO'
  },
  {
    label: 'Rede Sociais',
    value: 'MARCAS'
  },
  {
    label: 'Banner em Notícias/Feiras e Eventos PT-BR',
    value: 'CUSTOMER_PRODUCT_HISTORY'
  },
  {
    label: 'Banner em Notícias/Feiras e Eventos ES',
    value: 'CUSTOMER_PRODUCT_HISTORY_ES'
  },
]

export const assistanceTypes = [
  {
    label: "Instrumentos de Medição",
    value: "MEASURING_INSTRUMENTS",
  },
  {
    label: "Máquinas de Serra de Fita",
    value: "BAND_SAW_MACHINES",
  },
  {
    label: "Técnicos Starrett",
    value: "STARRETT_TECHNICIANS",
  },
  {
    label: "Representantes Starrett",
    value: "STARRETT_SALES_REPRESENTATIVE",
  },
  {
    label: "Distribuidores América Latina",
    value: "DISTRIBUTOR",
  },
];

export const ComponentHomeTypes = [
  {
    id: ComponentHomeTypesEnum.BANNER_CAROUSEL,
    value: ComponentHomeTypesEnum.BANNER_CAROUSEL,
    label: "Imagem",
  },
  {
    id: ComponentHomeTypesEnum.PRODUCT_CAROUSEL,
    value: ComponentHomeTypesEnum.PRODUCT_CAROUSEL,
    label: "Carrosel",
  },
  {
    id: ComponentHomeTypesEnum.CATEGORY_CAROUSEL,
    value: ComponentHomeTypesEnum.CATEGORY_CAROUSEL,
    label: "Categorias Destacadas",
  },
  {
    id: ComponentHomeTypesEnum.DEPARTMENTS_LIST,
    value: ComponentHomeTypesEnum.DEPARTMENTS_LIST,
    label: "Notícias",
  },
];

export const languages = [
  { label: "Português", value: "ptBR" },
  { label: "Espanhol", value: "es" },
  { label: "Inglês", value: "enUS" },
];

export const countries = [
  { label: "Argentina", value: "Argentina" },
  { label: "Bolívia", value: "Bolívia" },
  { label: "Brasil", value: "Brasil" },
  { label: "Chile", value: "Chile" },
  { label: "Colômbia", value: "Colômbia" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Cuba", value: "Cuba" },
  { label: "Equador", value: "Equador" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Haiti", value: "Haiti" },
  { label: "Honduras", value: "Honduras" },
  { label: "México", value: "México" },
  { label: "Nicarágua", value: "Nicarágua" },
  { label: "Panamá", value: "Panamá" },
  { label: "Paraguai", value: "Paraguai" },
  { label: "Peru", value: "Peru" },
  { label: "República Dominicana", value: "República Dominicana" },
  { label: "Uruguai", value: "Uruguai" },
  { label: "Venezuela", value: "Venezuela" },
];
