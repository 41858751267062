import React, { forwardRef, useEffect, useState } from 'react';
import InputComponent from '../InputComponent';
import { formatNumbers } from '~/helpers/utils/Functions';

const InputDecimalComponent = (
  { prefix = '', defaultValue, onChange, ...props },
  ref
) => {
  const [initValue, setInitValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setInitValue(formatNumbers(defaultValue));
    } else {
      setInitValue('');
    }
  }, [defaultValue, prefix]);

  const onChangeInput = (e) => {
    if (!e.target.value) e.target.value = '';

    e.target.value = formatNumbers(e.target.value);

    setInitValue(e.target.value);

    onChange && onChange(e);
  };

  return (
    <InputComponent
      ref={ref}
      value={initValue}
      onChange={onChangeInput}
      {...props}
    />
  );
};

export default forwardRef(InputDecimalComponent);
