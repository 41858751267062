import BaseAPI, { URLS } from './BaseAPI';

class CampaignAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.CAMPAIGNS, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar lista de campanhas' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de campanhas');
    }
  }

  /**
   * Request que troca a prop active uma campanha.
   * @param  {String} uuid - Uuid da campanha que será atualizada
   */
  static async switchActiveFlag(uuid) {
    try {
      const response = await BaseAPI.patch(`${URLS.CAMPAIGNS}/${uuid}`);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      )
        return response;
      return { error: 'Erro inesperado ao ativar/desativar a campanha' };
    } catch (e) {
      return this._exception(e, 'Falha ao ativar/desativar a campanha');
    }
  }

  /**
   * Request que cria nova campanha.
   * @param  {Object} campaign - Objeto da campanha que será criada
   */
  static async save(campaign) {
    try {
      const response = await BaseAPI.post(URLS.CAMPAIGNS, campaign);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar a campanha' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar a campanha');
    }
  }

  /**
   * Request que atualiza uma campanha.
   * @param  {Object} campaign - Objeto da campanha que será atualizada
   * @param  {String} uuid - Uuid da campanha que será atualizada
   */
  static async update(campaign, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.CAMPAIGNS}/${uuid}`, campaign);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao atualizar a campanha' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar a campanha');
    }
  }

  /**
   * Request que busca campanha pelo uuid.
   * @param  {String} uuid - Uuid da campanha
   */
  static async getCampaignByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CAMPAIGNS}/${uuid}`);
      if (response.status === 201 || response.status === 200)
        return response.data;
      return { error: 'Erro ao recuperar informação da campanha!' };
    } catch (e) {
      return this._exception(e, 'Erro ao recuperar informação da campanha!');
    }
  }

  /**
   * Request que deleta uma campanha.
   * @param  {String} uuid - Uuid da campanha que será atualizada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CAMPAIGNS}/${uuid}`);
      if (response.status === 200 || response.status === 201) return response;
      return { error: 'Erro inesperado ao deletar a campanha' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar a campanha');
    }
  }
}

export default CampaignAPI;
