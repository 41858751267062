import React, { useEffect } from 'react';
import InputSelectComponent from '../InputSelectComponent/InputSelectComponent';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const InputSelectBranchesComponent = (props) => {
  const { branchesStore } = props;
  const { branchesListSelect } = branchesStore;

  useEffect(() => {
    if (branchesListSelect.length === 0) branchesStore.getBranchesListSelect();
  }, []);

  return <InputSelectComponent items={branchesListSelect} {...props} />;
};

export default inject('branchesStore')(
  withRouter(observer(InputSelectBranchesComponent))
);
