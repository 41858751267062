import axios from "axios";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import { decodeJWT } from "~/helpers/utils/Functions";

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
class BaseAPI {
  /** Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /** Métodos post */
  static async post(endpoint, data = {}, config) {
    const requestConfig = { code: config };
    return await api.post(endpoint, data, requestConfig);
  }

  /** Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () =>
  process.env.REACT_APP_API_URL ||
  "https://click-starrett-showcase-api.dev.stoomlab.com.br/";

// Cria uma instancia de api
const api = axios.create({ baseURL: baseURL(), withCredentials: true });
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = {
      "Content-Type": "application/json; charset=utf-8",
      accept: "*/*",
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config?.code?.headers?.code) {
      config.headers = { '2fa': config?.code?.headers?.code };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;
    if (token) {
      const currentToken = response.headers.authorization.split(" ")[1];
      const decode = decodeJWT(currentToken);
      StorageUtil.setItem(KEYS.AUTH_KEY, decode.Authorities);
      StorageUtil.setItem(KEYS.TOKEN_KEY, currentToken);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const MANAGER = "/manager";
export const URLS = {
  MANAGER,
  LOGIN: "/login",
  FILES: "/files",
  FORGET_PASSWORD: "/forget-password",
  ORDERS: `${MANAGER}/orders`,
  SEARCHORDERS: `${MANAGER}/orders`,
  CUSTOMERS: `${MANAGER}/customers`,
  MERCHANT: `${MANAGER}/merchants`,
  SUPPLIER: `${MANAGER}/suppliers`,
  AUTOMAKER: `${MANAGER}/automakers`,
  SHIPPING: `${MANAGER}/shipping-company`,
  APPLICATION: `${MANAGER}/applications`,
  PRODUCTS: `${MANAGER}/products`,
  CAMPAIGNS: `${MANAGER}/campaigns`,
  CATEGORIES: `${MANAGER}/categories`,
  ACCOUNTS: `${MANAGER}/accounts`,
  PROPERTY: `${MANAGER}/properties`,
  CLASSES: `${MANAGER}/properties/classes`,
  PRE_ORDERS: `${MANAGER}/preorders`,
  INSTITUTIONAL: `${MANAGER}/institutional`,
  ZIPCODERANGE: `${MANAGER}/zipcode-range`,
  CUSTOMER_GROUP: `${MANAGER}/insumosHolambra-customers/types`,
  INSUMOS_HOLAMBRA: `${MANAGER}/insumosHolambra-customers`,
  BANNER: `${MANAGER}/banners`,
  NEWS_LETTER: `${MANAGER}/newsletters`,
  SALES: `${MANAGER}/reports/sales-general`,
  SALES_DETAILS: `${MANAGER}/reports/sales-details`,
  BRANCHES: `${MANAGER}/branches`,
  STORES: `${MANAGER}/stores`,
  BANNERS: `${MANAGER}/banners`,
  DYNAMIC_HOME: `${MANAGER}/components`,
  COLORS: `${MANAGER}/colors`,
  SIZES: `${MANAGER}/sizes`,
  COUPONS: `${MANAGER}/promotion`,
  COUPONS_SEARCH: `${MANAGER}/promotion/search`,
  BRANDS: `${MANAGER}/brands`,
  VIDEO: `${MANAGER}/videos`,
  ASSISTANCES: `${MANAGER}/assistances`,
  CATALOGOS: `${MANAGER}/catalogs`,
  EVENT: `/event`,
  NEWS: `/news`,
};

export default BaseAPI;
