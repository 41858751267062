import React, { forwardRef, useEffect, useState } from 'react';
import InputComponent from '../InputComponent';

const InputIntComponent = (
  { defaultValue, onChange, ...props },
  ref
) => {
  const [initValue, setInitValue] = useState('');

  const onChangeInput = (e) => {
    if (e.target.value) setInitValue(parseInt(e.target.value));
    else setInitValue('');
    onChange && onChange(e);
  };

  useEffect(() => {
    if (defaultValue) {
      setInitValue(parseInt(defaultValue));
    } else {
      setInitValue('');
    }
  }, [defaultValue]);

  return (
    <InputComponent
      ref={ref}
      value={initValue}
      onChange={(e) => !isNaN(e.target.value) && onChangeInput(e)}
      {...props}
    />
  );
};

export default forwardRef(InputIntComponent);
