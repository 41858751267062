import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useDropzone } from "react-dropzone";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import { observer } from "mobx-react";
import FileModel from "../../../models/FileModel";

const InputDropzoneComponent = forwardRef((props, ref) => {
  const {
    message = "Arraste um arquivo, ou clique...",
    accept = "image/*",
    items,
    onDeleteImage,
    onAddImage,
    onChangeOrder,
    maxSize = 5,
    minSize = 0,
  } = props;

  //estados de arquivos (hooks)
  const [filePreview, setFilePreview] = useState([]);
  const [files, setFiles] = useState(false);

  /**Verificação se é imagem */
  const isImage = () => accept.includes("image");
  const extentionFile = (file) =>
    file.type ? file.type.slice(-3) : file.name.slice(-3);

  /**Cria preview de imagem */
  const createPreview = (values) =>
    values.map((file) =>
      file.preview
        ? file
        : Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
    );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      const accepted = createPreview(acceptedFiles);
      const values = [...accepted];
      // setFiles(values);
      setFilePreview(values);
      reader.readAsArrayBuffer(file);
      // onChange && onChange(accepted);
    });
  }, []);

  const maxMb = maxSize * 1024 * 1024;
  const minMb = minSize * 1024 * 1024;

  const { getRootProps, getInputProps } = useDropzone({
    accept: `${accept}`,
    onDrop,
    maxSize: maxMb,
    minSize: minMb,
  });

  const sendImage = (e) => {
    e.preventDefault();
    onAddImage(filePreview);
    setFilePreview([]);
  };

  //Remove image from preview
  const onRemove = async (e, fileRemove) => {
    e.preventDefault();
    setFiles(onDeleteImage(fileRemove));
  };

  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find(
      (item, index) => index === event.oldIndex
    );

    const remainingItems = originalArray.filter(
      (item, index) => index !== event.oldIndex
    );

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];

    const newArray = reorderedItems.map((object, index) => {
      return new FileModel({ ...object, ordering: index });
    });

    return newArray;
  };

  const changeOrder = (index, direction) => {
    const reorderedArray = reorderArray(
      { oldIndex: index, newIndex: index + (direction === "UP" ? -1 : 1) },
      files
    );
    onChangeOrder(reorderedArray);
    setFiles(reorderedArray);
  };

  useEffect(() => {
    if (items) {
      const values = Array.isArray(items) ? items : [items];
      setFiles(values);
    }
  }, [items]);

  const removePreview = () => {
    setFilePreview([]);
  };

  return !files ? (
    <></>
  ) : (
    <div className="container-slotable-uploaded">
      <div className="left-block">
        <div {...getRootProps({ className: "dropzone container-dropzone" })}>
          <div className="image-container thumb">
            {filePreview.length > 0 ? (
              <>
                {filePreview.map((file, index) => (
                  <div
                    className="thumb"
                    key={index}
                    onClick={(e) => removePreview(e, file)}
                  >
                    <div className="thumb-inner">
                      {isImage(file) ? (
                        <div className="preview-image-upload">
                          <img
                            className="image-uploaded"
                            alt="content"
                            src={file.preview}
                          />
                        </div>
                      ) : (
                        <div className="preview-file-upload">
                          <div className="file-preview">
                            <div>
                              <i className="far fa-file" />
                            </div>
                            <div className="extention-file">
                              {extentionFile(file)}
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="icon-remove"
                        onClick={(e) => removePreview(e, file)}
                      >
                        <i className="fas fa-trash-alt" />
                      </div>
                      <div className="info-file">
                        <div className="limited-file-name">{file.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <input {...getInputProps()} />
                <span>{message}</span>
                <i className="fas fa-cloud-upload-alt" />
              </>
            )}
          </div>
        </div>
        <button onClick={(e) => sendImage(e)}>Enviar</button>
      </div>

      <div className="right-block">
        {files?.map((item, index) => {
          return (
            <div className="conteiner-component">
              <p className="order-image">{index + 1}</p>
              <div className="conteiner-image">
                <div className="block-image">
                  <img
                    className="image-table col-3"
                    alt="content"
                    src={item?.file?.preview ?? item?.preview}
                  />
                  {item?.file?.name ? (
                    <p>{item.file.name}</p>
                  ) : (
                    <a
                      className="color-link"
                      href={item?.path}
                      target="__blank"
                      className="col-9"
                    >
                      {item?.path}
                    </a>
                  )}

                  <div className="action-conteiner ">
                    <button
                      className="button-action"
                      onClick={(e) => onRemove(e, item)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="arrow-action">
                <div
                  className="button-action button-bordered"
                  onClick={index !== 0 && (() => changeOrder(index, "UP"))}
                >
                  <FaLongArrowAltUp />
                </div>
                <div
                  className="button-action button-bordered"
                  onClick={
                    index !== files.length - 1 &&
                    (() => changeOrder(index, "DOWN"))
                  }
                >
                  <FaLongArrowAltDown />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default observer(InputDropzoneComponent);
