import BaseModel from "./BaseModel";

class CatalogosModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this["@class"] = "br.com.stoom.clickstarrett.model.ClickBanner";
    this.language = data.language;
    this.active = data.active;
    this.uuid = data.uuid;
    this.ordering = data.ordering;
    this.module = data.module;
    this.subjects = data.subjects || [];
  }
}

export default CatalogosModel;
