import { extendObservable } from "mobx";

import NewsAPI from "../services/NewsAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import UploadAPI from "../services/UploadAPI";

import NewsModel from "~/models/NewsModel";

class NewsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      news: new NewsModel(),
      newsList: [],
      loading: false,
      totalPages: 0,
      translatables: [],
      active: false,
      highlight: false,
      event: false,
      ordering: 0,
      file : undefined,
      size: 10,
      page: 0,
      sort: "created,desc",
      filters: {},
    });
  }

  reset() {
    this.news = new NewsModel();
    this.file = {};
    this.newsList = [];
    this.sort = "created,desc";
    this.translatables = [];
    this.active = false;
    this.highlight = false;
    this.event = false;
    this.ordering = 0;
    this.size = 10;
    this.page = 0;
    this.loading = false;
  }

  initializeNews() {
    this.news = new NewsModel();
    this.file = undefined;
  }

  async onChange(prop, value) {
    switch (prop) {
      case "highlight":
        this.highlight = value;
        break;
      case "active":
        this.active = value;
        break;
      case "ordering":
        this.ordering = value;
        break;
      case "file":
        this.file = value;
        break;
      case "event":
        this.event = value;
        break;
      default:
        break;
    }
  }

  async onCreate() {
    this.loading = true;

    const json = new NewsModel()
    json.highlight = this.highlight;
    json.active = this.active;
    json.translatables = this.translatables;
    json.ordering = this.ordering;
    json.event = this.event;

    const responseFile = await UploadAPI.saveFile(this.file, "news");

    if (responseFile?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, responseFile?.error);
    } else {
      json.file = {
        "@class": "br.com.stoom.clickstarrett.model.ClickFile",
        uuid: responseFile.data.uuid
      }
    }

    const data = JSON.stringify(json);
    const response = await NewsAPI.create(data);
    this.loading = false;
    if (!response.error) {
      this.news = new NewsModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Notícia criada com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "created,desc";

    sort ? (this.sort = sort) : (this.sort = "created,desc");

    await this.getList();
    return true;
  }


  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await NewsAPI.getAlls({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.newsList = response.content.map(
      (usr) => new NewsModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }
  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  /**
    * @param  {string} Uuid
   */
  async get(data) {
    this.loading = true;
    const response = await NewsAPI.getUser(data);
    this.translatables = response.translatables;
    this.active = response.active;
    this.highlight = response.highlight;
    this.event = response.event;
    this.ordering = response.ordering;
    this.file = response.file;

    this.loading = false;
    if (!response.error) {
      this.news = await new NewsModel(response);
      return this.news;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async onUpdate() {
    this.loading = true;
    const json = this.news
    json.highlight = this.highlight;
    json.active = this.active;
    json.translatables = this.translatables;
    json.ordering = this.ordering;
    json.event = this.event;
    if (this.file.uuid === undefined) {
      const responseFile = await UploadAPI.saveFile(this.file, "news");

      if (responseFile?.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, responseFile?.error);
      } else {
        json.file = {
          "@class": "br.com.stoom.clickstarrett.model.ClickFile",
          uuid: responseFile.data.uuid
        }
      }
    } else {
      json.file['@class'] = 'br.com.stoom.clickstarrett.model.ClickFile';
    }
    const data = JSON.stringify(json);
    const response = await NewsAPI.update(
      this.news.uuid,
      data
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Notícia atualizada com sucesso!"
      );
      this.news = new NewsModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response
  }

  updateTranslatables(value, language, keyName) {

    const objIndex = this.translatables.findIndex(
      (item) =>
        item.language === language &&
        item.keyName === keyName
    );
    if (objIndex !== -1 && value === '') {
      const filter = this.translatables.filter(
        (_, index) => index !== objIndex
      );

      this.translatables = filter;
    } else if (objIndex !== -1) {
      const object = this.translatables.find(
        (item, index) => index === objIndex
      );

      const data = {
        active: object.active,
        deleted: object.deleted,
        created: object.created,
        keyName: object.keyName,
        language: object.language,
        uuid: object.uuid,
        value: value,
      };

      const updateTranslatable = this.translatables.map((item, index) =>
        index === objIndex ? { ...data } : item
      );

      this.translatables = updateTranslatable;
    } else {
      const data = {
        value: value,
        keyName: keyName,
        language: language,
      };

      this.translatables.push(data);
    }
  }


  async onDelete(uuid) {
    this.loading = true;
    const response = await NewsAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Notícia excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }


}
export default NewsStore;
