import BaseModel from "../BaseModel";
import PreOrderItemModel from "./PreOrderItemModel";
import ProductModel from "../ProductModel";
import OrderModel from "./OrderModel";

class OrderItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.eurobrake.model.EuroOrderItem";
    this.preOrderItem =
      data.preOrderItem && new PreOrderItemModel(data.preOrderItem);
    this.product = data.product ? new ProductModel(data.product) : undefined;
    this.order = data.order && new OrderModel(data.order);
    this.amount = data.amount;
    this.unitPrice = data.unitPrice;
    this.observation = data.observation || "";
  }

  getValuesToList() {
    return {
      file: this.file ? this.file.path : "",
      name: this.product.name,
      observation: this.observation,
      amount: this.amount,
    };
  }
}

export default OrderItemModel;
