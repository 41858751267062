import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import BrandModel from "~/models/BrandModel";
import BrandAPI from "../services/BrandAPI";
import FileModel from "~/models/FileModel";
import UploadAPI from "../services/UploadAPI";

const initValues = {
  brand: undefined,
  brands: [],
  loading: false,
  brandOptions: [],
};

class BrandStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = "created,desc";
  filter = "";
  term = "";
  active = "all";

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  reset() {
    this.brand = new BrandModel();
  }

  updateProp(prop, value) {
    const { brand } = this;
    brand[prop] = value;
    this.brand = new BrandModel(brand);
  }

  async save() {
    this.loading = true;
    const brand = {
      active: this.brand.active,
      deleted: this.brand.deleted,
      description: this.brand.description,
      name: this.brand.name,
      slug: this.brand.slug,
    };

    const response = await BrandAPI.save(brand);

    if (!response.error) {
      if (!this.brand.file.uuid) {
        this.sendNewFiles(this.brand.file, response.data.uuid);
      }
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Marca cadastrada com sucesso!"
      );
      this.loading = false;
      return true;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return false;
    }
  }

  async update(uuidBrand) {
    const brand = {
      active: this.brand.active,
      deleted: this.brand.deleted,
      description: this.brand.description,
      name: this.brand.name,
      slug: this.brand.slug,
      uuid: uuidBrand,
    };

    const response = await BrandAPI.update(uuidBrand, brand);

    if (!response.error) {
      if (!this.brand.file.uuid) {
        this.sendNewFiles(this.brand.file, this.brand.uuid);
      }
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Marca atualizada com sucesso!"
      );
      return true;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return false;
    }
  }

  async getAllBrands() {
    this.loading = true;

    try {
      const response = await BrandAPI.getAllSelect();

      if (!response.error) {
        this.brandOptions = response.data;
      }

      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.toastHelper.notify(STATUS_HELPER.ERROR, e);
    }
  }

  getBrandsList() {
    const brandsList = [];

    if (this.brandOptions) {
      this.brandOptions.map((brand) => {
        brandsList.push({
          label: brand.name,
          value: brand.uuid,
          data: brand,
        });
      });
    }

    return brandsList;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : "created,desc";

    if (this.name) {
      await this.searchByName(this.name);
    } else {
      await this.getList();
    }
  }

  /** Busca todos as marcas */
  async getList(sortByName = false) {
    this.loading = true;

    // if (this.name) {
    //   this.page = 0;
    //   this.sort = 'created,desc';
    //   this.name = '';
    // }

    let params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    if (sortByName) {
      params.name = this.name;
    } else {
      this.name = "";
    }

    if (this.active !== "all") {
      params = {
        page: this.page,
        size: this.size,
        sort: this.sort,
        active: this.active,
      };
    }

    const response = await BrandAPI.getAll(params);

    if (!response.error) {
      this.brands = await response.data.content.map(
        (brand) => new BrandModel(brand)
      );
      this.totalPages = response.data.totalPages;
      this.page = response.data.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByName(inputValue) {
    if (!this.name) {
      this.page = 0;
      this.sort = "created,desc";
    }

    this.name = inputValue;

    let params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    if (this.active !== "all") {
      params = {
        page: this.page,
        size: this.size,
        sort: this.sort,
        active: this.active,
      };
    }

    this.loading = true;

    const response = await BrandAPI.findByName(params, this.name);

    if (!response.error) {
      this.brands = await response.data.content.map(
        (brand) => new BrandModel(brand)
      );
      this.totalPages = response.data.totalPages;
      this.page = response.data.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getBrandByUuid(uuidBrand) {
    this.loading = true;

    const response = await BrandAPI.getByUuid(uuidBrand);

    if (!response.error) {
      this.brand = new BrandModel(response.data);
      this.loading = false;
      return response.data;
    } else {
      this.loading = false;
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return false;
    }
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BrandAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.getList();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  /**Adiciona imagem ao produto */
  addImageBrand(dropedFile, tag) {
    if (dropedFile) {
      const newFileItem = new FileModel({
        file: dropedFile,
        ...dropedFile,
        metaTags: [tag],
      });
      this.brand.file = newFileItem;
    } else {
      this.brand.file = new FileModel();
    }
  }

  /**Envia imagem da categoria. */
  async sendNewFiles(file, brandUuid) {
    await UploadAPI.uploadBrand(brandUuid, file);

    return true;
  }

  updateStatusFilter(value) {
    this.active = value;
  }

  resetBrandManager() {
    this.totalPages = 0;
    this.page = 0;
    this.size = 10;
    this.sort = "created,desc";
    this.filter = "";
    this.term = "";
    this.active = "all";
  }
}

decorate(BrandStore, {
  get: action,
  getAllBrands: action,
  save: action,
});

export default BrandStore;
