export const BannerTagEnum = Object.freeze({
  SLIDESHOW_PRINCIPAL: "SLIDESHOW_PRINCIPAL",
  BLOG: "BLOG",
  IMAGENS_HOME: "IMAGENS_HOME",
  IMAGENS_FULL: "IMAGENS_FULL",
  // CARRINHO: "CARRINHO",
  // VITRINE_PLP: "VITRINE_PLP",
  // CHECKOUT: "CHECKOUT",
  // SUB_HEADER: "SUB_HEADER",
  // MARCAS: "MARCAS",
  // VITRINE_PLP_MARCA: "VITRINE_PLP_MARCA",
  // VITRINE_PLP_CAMPANHA: "VITRINE_PLP_CAMPANHA",
  BANNER_PARCEIROS: "BANNER_PARCEIROS",
  CATEGORY_BANNER: "CATEGORY_BANNER",
  VIDEO_HOME: "VIDEO_HOME",
  BANNER_CATALOGO: "BANNER_CATALOGO",
  MARCAS: "MARCAS",
});
