/* eslint-disable no-console */
import BaseAPI, { URLS } from './BaseAPI';

class SalesAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   *@description Consulta o relatório de vendas
   *@param {string} tab rota que será consultada (city ou state ou general)
   *@param {string} from data
   *@param {string} to data
   */
   static async getSales(params) {
    try {
      const url = `${URLS.SALES}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao consultar vendas.' };
    } catch (e) {
      return this._exception(e, 'Falha ao consultar vendas.');
    }
  }

    /**
   *@description Consulta os detalhes do relatório de vendas
   *@param {string} from data
   *@param {string} to data
   */
   static async getSalesDetails(params) {
    try {
      const url = `${URLS.SALES_DETAILS}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao consultar vendas.' };
    } catch (e) {
      return this._exception(e, 'Falha ao consultar vendas.');
    }
  }
}

export default SalesAPI;
