import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';

class AsyncSelectComponent extends Component {
  //Estilos customizados do react-select
  customStyles = () => ({
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: 'gray' },
      border: '1px solid lightgray',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      border: 'none',
      backgroundColor: state.isSelected ? '#da333c' : '#fff',
      ':active': {
        backgroundColor: '#da333c',
        color: '#ffffff',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  });

  filterOptions = async (inputValue) => {
    //props.
    const { findByName, items = [] } = this.props;
    //função para filtro
    let filter = items.filter(
      (item) => item.label.toLowerCase() === inputValue.toLowerCase()
    );
    if (filter.length === 0) {
      return await findByName(inputValue);
    }
    return filter;
  };

  handleSearch = (inputValue) => {
    const { delay = 800 } = this.props;
    return new Promise((resolve) => {
      clearTimeout(this.delayTimer);
      if (inputValue === '') return '';
      this.delayTimer = setTimeout(() => {
        resolve(this.filterOptions(inputValue));
      }, delay);
    });
  };

  render() {
    const {
      items = [],
      isLoading = true,
      placeholder,
      onSelect,
      message = 'Nenhuma opção disponivel',
      isClearable = true,
      value,
      autoFocus = false,
      onBlur,
      isMulti,
      isDisabled = false,
    } = this.props;
    return (
      <AsyncSelect
        isMulti={isMulti}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onBlur={onBlur && onBlur}
        styles={this.customStyles()}
        placeholder={placeholder}
        onChange={onSelect}
        autoFocus={autoFocus}
        cacheOptions
        isClearable={isClearable}
        defaultOptions={items}
        noOptionsMessage={() => message}
        loadingMessage={() => 'Pesquisando...'}
        value={value}
        loadOptions={this.handleSearch}
      />
    );
  }
}

export default AsyncSelectComponent;
