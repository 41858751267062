import AdministratorStore from "./AdministratorStore";
import AuthStore from "./AuthStore";
import MenuStore from "./MenuStore";
import MerchantStore from "./MerchantStore";
import UsersStore from "./UsersStore";
import CustomerTypeStore from "./CustomerTypeStore";
import CategoryStore from "./CategoryStore";
import ProductStore from "./ProductStore";
import VideoStore from "./VideoStore";
import BranchesStore from "./BranchesStore";
import BannersStore from "./BannersStore";
import AssistanceStore from "./AssistanceStore";
import CatalogosStore from "./CatalogosStore";
import OurStoresStore from "./OurStoresStore";
import NotificationStore from "./NotificationStore";
import DynamicHomeStore from "./DynamicHomeStore";
import ColorStore from "./ColorStore";
import SizeStore from "./SizeStore";
import NewsLetterStore from "./NewsLetterStore";
import CampaignStore from "./CampaignStore";
import ProductCommentStore from "./ProductCommentStore";
import CustomerStore from "./CustomerStore";
import SalesStore from "./SalesStore";
import CouponStore from "./CouponStore";
import ShippingClassStore from "./ShippingClassStore";
import ShippingServiceStore from "./ShippingServiceStore";
import OrderStore from "./OrderStore";
import ZipCodeRangeStore from "./ZipCodeRangeStore";
import BrandStore from "./BrandStore";
import NewsStore from "./NewsStore"
import EventStore from "./EventStore";

/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      usersStore: {},
      customerTypeStore: {},
      authStore: {},
      menuStore: {},
      merchantStore: {},
      administratorStore: {},
      categoryStore: {},
      productStore: {},
      videoStore: {},
      branchesStore: {},
      outStoresStore: {},
      notificationStore: {},
      colorStore: {},
      sizeStore: {},
      newsLetterStore: {},
      campaignStore: {},
      productCommentStore: {},
      customerStore: {},
      shippingClassStore: {},
      salesStore: {},
      couponStore: {},
      shippingServiceStore: {},
      orderStore: {},
      ZipCodeRangeStore: {},
      brandStore: {},
      newsStore : {},
      eventStore : {}
    }
  ) {
    if (this._mobxStores === null) {
      this.usersStore = new UsersStore(this, {
        ...serializedStores.usersStore,
      });
      this.customerTypeStore = new CustomerTypeStore(this, {
        ...serializedStores.customerTypeStore,
      });
      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.merchantStore = new MerchantStore(this, {
        ...serializedStores.merchantStore,
      });
      this.administratorStore = new AdministratorStore(this, {
        ...serializedStores.administratorStore,
      });
      this.categoryStore = new CategoryStore(this, {
        ...serializedStores.categoryStore,
      });
      this.productStore = new ProductStore(this, {
        ...serializedStores.productStore,
      });
      this.videoStore = new VideoStore(this, {
        ...serializedStores.videoStore,
      });
      this.branchesStore = new BranchesStore(this, {
        ...serializedStores.branchesStore,
      });
      this.ourStoresStore = new OurStoresStore(this, {
        ...serializedStores.outStoresStore,
      });
      this.bannersStore = new BannersStore(this, {
        ...serializedStores.bannersStore,
      });
      this.assistanceStore = new AssistanceStore(this, {
        ...serializedStores.assistanceStore,
      });
      this.catalogosStore = new CatalogosStore(this, {
        ...serializedStores.catalogosStore,
      });
      this.notificationStore = new NotificationStore(this, {
        ...serializedStores.notificationStore,
      });
      this.dynamicHomeStore = new DynamicHomeStore(this, {
        ...serializedStores.dynamicHomeStore,
      });
      this.colorStore = new ColorStore(this, {
        ...serializedStores.colorStore,
      });
      this.sizeStore = new SizeStore(this, {
        ...serializedStores.sizeStore,
      });
      this.orderStore = new OrderStore(this, {
        ...serializedStores.orderStore,
      });
      this.newsLetterStore = new NewsLetterStore(this, {
        ...serializedStores.newsLetterStore,
      });
      this.campaignStore = new CampaignStore(this, {
        ...serializedStores.campaignStore,
      });
      this.productCommentStore = new ProductCommentStore(this, {
        ...serializedStores.productCommentStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });
      this.salesStore = new SalesStore(this, {
        ...serializedStores.salesStore,
      });
      this.couponStore = new CouponStore(this, {
        ...serializedStores.couponStore,
      });
      this.shippingClassStore = new ShippingClassStore(this, {
        ...serializedStores.shippingClassStore,
      });
      this.shippingServiceStore = new ShippingServiceStore(this, {
        ...serializedStores.shippingServiceStore,
      });
      this.zipCodeRangeStore = new ZipCodeRangeStore(this, {
        ...serializedStores.zipCodeRangeStore,
      });
      this.brandStore = new BrandStore(this, {
        ...serializedStores.brandStore,
      });
      this.newsStore = new NewsStore(this, {
        ...serializedStores.newsStore,
      });
      this.eventStore = new EventStore(this, {
        ...serializedStores.eventStore
      })
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      usersStore: this.usersStore,
      customerTypeStore: this.customerTypeStore,
      authStore: this.authStore,
      menuStore: this.menuStore,
      merchantStore: this.merchantStore,
      administratorStore: this.administratorStore,
      categoryStore: this.categoryStore,
      productStore: this.productStore,
      videoStore: this.videoStore,
      branchesStore: this.branchesStore,
      ourStoresStore: this.ourStoresStore,
      bannersStore: this.bannersStore,
      assistanceStore: this.assistanceStore,
      catalogosStore: this.catalogosStore,
      notificationStore: this.notificationStore,
      dynamicHomeStore: this.dynamicHomeStore,
      colorStore: this.colorStore,
      sizeStore: this.sizeStore,
      newsLetterStore: this.newsLetterStore,
      campaignStore: this.campaignStore,
      productCommentStore: this.productCommentStore,
      customerStore: this.customerStore,
      salesStore: this.salesStore,
      zipCodeRangeStore: this.zipCodeRangeStore,
      couponStore: this.couponStore,
      shippingClassStore: this.shippingClassStore,
      shippingServiceStore: this.shippingServiceStore,
      orderStore: this.orderStore,
      brandStore: this.brandStore,
      newsStore: this.newsStore,
      eventStore: this.eventStore
    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
