import React, { forwardRef, useEffect, useState } from 'react';
import BlockErrorComponent from '../../BlockErrorComponent/BlockErrorComponent';
import InputComponent from '../InputComponent';
import { Controller } from 'react-hook-form';

const InputTimeComponent = ({ name, control, id, onBlur, value, defaultValue, ...props }, ref) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (!value) {
      setShowErrorMessage(false);
    }
  }, [value]);

  const onBlurDate = (e) => {
    // let isValid;

    // const dateValue = e.target.value;

    // if (!dateValue || dateValue.length === 0) {
    //   isValid = true;
    // } else {
    //   isValid = validateDate(dateValue);
    // }

    // if (!isValid) {
    //   setShowErrorMessage(true);
    // } else {
    //   setShowErrorMessage(false);
    // }

    onBlur && onBlur(e);
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputComponent
            ref={ref}
            type="time"
            name={name}
            isValid={!showErrorMessage}
            id={id}
            onBlur={onBlurDate}
            value={value}
            defaultValue={defaultValue}
            {...props}
            {...field}
          />
        )}
      />
      {showErrorMessage && (
        <BlockErrorComponent visible message="Horário invalido." />
      )}
    </>
  );
};

export default forwardRef(InputTimeComponent);
