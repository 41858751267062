import BaseAPI, { URLS } from "./BaseAPI";

class NewsApi {

    /**
     * @param  {Object} data Dados do novo usuário administrador
     */
    static async create(data) {
        try {
            const response = await BaseAPI.post(`/manager${URLS.NEWS}`, data )
            if (response.status === 201) return response;
            else return {error: "Falha ao cadastrar Notícia/Evento"};
        } catch (e) {
            return {
                error: e?.response?.data?.message
                  ? e?.response?.data?.message
                  : 'Falha ao cadastrar Notícia/Evento',
              };
            
            
        }
    }

    /**
     * @param  {string} uuid Uuid do usuário que será editado
     * @param {object} data dados do usuário que será editado
     */
    static async update(uuid, data) {
        try{
            const response = await BaseAPI.put(`/manager${URLS.NEWS}`, data);
            if(response.status === 200) return response.data;
            else return {error: "Falha ao atualizar Notícia/Evento"};
        } catch(e) {
            console.log(e);
            return {
                error: e?.response?.data?.message
                  ? e?.response?.data?.message
                  : 'Falha ao atualizar Notícia/Evento',
              };
            }
    }

    /**
     * @param  {string} uuid Uuid do usuário que será excluído
     */
    static async delete(uuid) {
        try{
            const response = await BaseAPI.delete(`/manager${URLS.NEWS}/${uuid}`);
            if(response.status === 200 || response.status === 204) return response.data;
            return {error: "Não foi possível excluir a Notícia/Evento"};
        } catch(e) {
            console.log(e);
            return {error: e.message};
        }
    }

    /**Monta uma query de acordo aos dados  */
    /**
     * @param  {object} prop
     * @param  {valor} value
     */
    static async getBy(prop, value) {
        try {
            const url = `${URLS.NEWS}/${value}/`;
            const params = { by: prop };
            const response = await BaseAPI.get(url, params);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar Notícia/Evento' };
        } catch (e) {
            console.log(e.message);
            return { error: e.message };
        }
    }

    /**
      * Busca um usuário admin por username
      * @param  {string} userName username do usuário a ser buscado
      */
     static async getUser(userName) {
        try {
            const url = `${URLS.NEWS}/${userName}`;
            const response = await BaseAPI.get(url);
            if (response.data.status) {
                return { error: 'Falha ao buscar Notícia/Evento' };
            }
            return response.data;
        } catch (e) {
            console.log(e.message);
            return { error: 'Falha ao buscar Notícia/Evento' };
        }
    }
       
    /**Busca todos os admins */
    static async getAlls(params) {
        try{    
            const response = await BaseAPI.get(`${URLS.NEWS}/filter`, params);
            if(response.status === 200) return response.data;
            return { error: 'Falha ao buscar Notícia/Evento' };
        } catch(e) {
            console.log(e.message);
            return { error: 'Falha ao buscar Notícia/Evento' };
        }
    }
}

export default NewsApi;