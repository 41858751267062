import BaseModel from "./BaseModel";
import ShippingCompany from "./ShippingCompany";

class ShippingDetails extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.sportbay.model.SportbayShippingDetails";
    this.active = data.active;
    this.created = data.created;
    this.deleted = data.deleted;
    this.externalId = data.externalId;
    this.modified = data.modified;
    this.shippingCompany = new ShippingCompany(data.shippingCompany);
    this.shippingDiscount = data.shippingDiscount;
    this.shippingPrice = data.shippingPrice;
    this.shippingTotal = data.shippingTotal;
    this.uuid = data.uuid;
  }
}

export default ShippingDetails;
