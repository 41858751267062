import React, { forwardRef } from 'react';
import { maskCoordenate } from '~/helpers/utils/Masks';

import InputComponent from '../InputComponent';
import { Controller } from 'react-hook-form';
/**
 * Coordinate Component
 *  */
const InputCoordinateComponent = forwardRef((props, ref) => {

  const {
    name = 'coordenate',
    id = 'coordenate',
    mask = maskCoordenate,
    required,
    control,
    defaultValue,
  } = props;
  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
            <InputComponent
              {...props}
              mask={mask}
              name={name}
              id={id}
              type="text"
              required={required}
              ref={ref}
              {...field}
            />
        )}
      />
    </>
  );
});

export default InputCoordinateComponent;
