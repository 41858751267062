import React, { forwardRef } from "react";
import LabelComponent from "../LabelComponent/LabelComponent";

import { InputTypes } from "~/helpers/utils/InputTypes";
import InputComponent from "./InputComponent";
import ButtonComponent from "../ButtonComponent";
import InputPasswordComponent from "./InputPasswordComponent/InputPasswordComponent";
import InputCEPComponent from "./InputCEPComponent/InputCEPComponent";
import InputCNPJComponent from "./InputCNPJComponent/InputCNPJComponent";
import InputCPFComponent from "./InputCPFComponent/InputCPFComponent";
import InputCpfCnpjComponent from "./InputCpfCnpjComponent/InputCpfCnpjComponent";
import InputDateComponent from "./InputDateComponent/InputDateComponent";
import InputDecimalComponent from "./InputDecimalComponent/InputDecimalComponent";
import InputEmailComponent from "./InputEmailComponent/InputEmailComponent";
import InputIntComponent from "./InputIntComponent/InputIntComponent";
import InputNumbersComponent from "./InputNumbersComponent/InputNumbersComponent";
import InputTimeComponent from "./InputTimeComponent/InputTimeComponent";
import BlockErrorComponent from "../BlockErrorComponent/BlockErrorComponent";
import CollumComponent from "../CollumComponent/CollumComponent";
import InputCheckboxComponent from "./InputCheckboxComponent";
import InformationPageComponent from "../InformationPageComponent/InformationPageComponent";
import InputClickableTextComponent from "./InputClickableTextComponent/InputClickableTextComponent";
import InputToogleComponent from "./InputToogleComponent/InputToogleComponent";
import InputDropzoneComponent from "./InputDropzoneComponent/InputDropzoneComponent";
import InputDropzoneSlotableComponent from "./InputDropzoneSlotableComponent/InputDropzoneSlotableComponent";
import InputTreeSelectComponent from "./InputTreeSelectComponent/InputTreeSelectComponent";
import InputSelectComponent from "./InputSelectComponent/InputSelectComponent";
import InputAsyncSelectComponent from "./InputAsyncSelectComponent/InputAsyncSelectComponent";
import InputTextAreaComponent from "./InputTextAreaComponent";
import InputBondingTableComponent from "./InputBondingTableComponent";
import TableDinamicComponent from "../TableDinamicComponent/TableDinamicComponent";
import InputSelectBranchesComponent from "./InputSelectBranchesComponent";
import InputSelectBannersComponent from "./InputSelectBannersComponent";
import InputSelectCategoryComponent from "./InputSelectCategoryComponent";
import InputPhoneComponent from "./InputPhoneComponent/InputPhoneComponent";
import InputCoordinateComponent from "./InputCoordinateComponent";
import InputHexCodeColorComponent from "./InputHexCodeColorComponent/InputHexCodeColorComponent";
import InputSelectColorComponent from "./InputSelectColorComponent";
import InputSelectSizeComponent from "./InputSelectSizeComponent";
import InputSelectAlternative from "./InputSelectComponent/InputSelectAlternative";
import InputHTMLComponent from "./InputHTMLComponent";
import InputTextEditorComponent from "./InputTextEditorComponent/InputTextEditorComponent";
import InputSelectValue from "./InputSelectComponent/InputSelectValue";

/**
 * @Name: InputWrapper
 * @Data: 2019
 * @Desc: Input padrão do sistema.
 * @param  {} props - todas propriedades do input html
 * @Obs: Este comp possui muitas props, então para não limitar a sua utilização,
 *       não estamos recebendo apenas alguns valores, mas todas quanto forem necessárias
 *       e fazendo um Spread das props nos inputs(padrão e mascara.).
 *       Caso haja necessidade de alguma lógica relacionada à alguma prop,
 *       esta deverá ser extraida da props e a logica aplicada conforme necessidade.
 */

function getInputToRender(type) {
  switch (type) {
    case InputTypes.PASSWORD:
      return InputPasswordComponent;

    case InputTypes.CEP:
      return InputCEPComponent;

    case InputTypes.CNPJ:
      return InputCNPJComponent;

    case InputTypes.CPF:
      return InputCPFComponent;

    case InputTypes.CPFCNPJ:
      return InputCpfCnpjComponent;

    case InputTypes.DATE:
      return InputDateComponent;

    case InputTypes.DECIMAL:
      return InputDecimalComponent;

    case InputTypes.EMAIL:
      return InputEmailComponent;

    case InputTypes.INT:
      return InputIntComponent;

    case InputTypes.NUMBER:
      return InputNumbersComponent;

    case InputTypes.TIME:
      return InputTimeComponent;

    case InputTypes.INFO:
      return InformationPageComponent;

    case InputTypes.CHECKBOX:
      return InputCheckboxComponent;

    case InputTypes.TOOGLE:
      return InputToogleComponent;

    case InputTypes.CLICKABLETEXT:
      return InputClickableTextComponent;

    case InputTypes.BUTTON:
      return ButtonComponent;

    case InputTypes.DROPZONE:
      return InputDropzoneComponent;

    case InputTypes.DROPZONESLOTABLE:
      return InputDropzoneSlotableComponent;

    case InputTypes.TREE:
      return InputTreeSelectComponent;

    case InputTypes.SELECT:
      return InputSelectComponent;

    case InputTypes.SELECT_ALTERNATIVE:
      return InputSelectAlternative;

    case InputTypes.SELECT_VALUE:
      return InputSelectValue;

    case InputTypes.ASYNCSELECT:
      return InputAsyncSelectComponent;

    case InputTypes.TEXTAREA:
      return InputTextAreaComponent;

    case InputTypes.BONDINGTABLE:
      return InputBondingTableComponent;

    case InputTypes.TABLE:
      return TableDinamicComponent;

    case InputTypes.SELECT_BRANCHES:
      return InputSelectBranchesComponent;

    case InputTypes.SELECT_BANNERS:
      return InputSelectBannersComponent;

    case InputTypes.SELECT_CATEGORY:
      return InputSelectCategoryComponent;

    case InputTypes.SELECT_COLOR:
      return InputSelectColorComponent;

    case InputTypes.SELECT_SIZE:
      return InputSelectSizeComponent;

    case InputTypes.HTML:
      return InputHTMLComponent;

    case InputTypes.PHONE:
      return InputPhoneComponent;

    case InputTypes.COORDINATE:
      return InputCoordinateComponent;

    case InputTypes.HEXCODECOLOR:
      return InputHexCodeColorComponent;

    case InputTypes.EDITOR_TEXT:
      return InputTextEditorComponent;

    default:
      return InputComponent;
  }
}

const InputWrapper = forwardRef((props, ref) => {
  const {
    key,
    id,
    label,
    required,
    size,
    mask,
    error,
    defaultValue,
    hidden = false,
    readOnly = false,
    register,
    type,
    infoPassword,
    ...otherProps
  } = props;

  const RenderedInput = getInputToRender(type);

  return (
    <>
      {!hidden && (
        <CollumComponent cols={size} size={"sm"}>
          {label && (
            <LabelComponent
              name={label}
              id={`${id}_label`}
              htmlFor={id}
              required={required}
              size={12}
            />
          )}

          <RenderedInput
            key={key}
            mask={mask}
            id={id}
            defaultValue={defaultValue}
            readOnly={readOnly}
            {...otherProps}
            {...(register != null ? register(id) : {})}
            infoPassword={infoPassword}
          />
          <BlockErrorComponent
            visible={error != undefined}
            message={error?.message}
          />
        </CollumComponent>
      )}
    </>
  );
});

export default InputWrapper;
