import BaseModel from './BaseModel';
import FileModel from "./FileModel";
import TranslatablesModel from "./TranslatablesModel";

const handleTranslate = (data) => {
  if (data !== undefined) {
    if (data.length !== 0) {
      const newsTitle = data.filter((e) => e.keyName === "NEWS_TITLE")
      const newsTitlePt = newsTitle.filter((title) => title.language === 'ptBR');
      const newsTitleEn = newsTitle.filter((title) => title.language === 'enUS');
      const newsTitleEs = newsTitle.filter((title) => title.language === 'es');
      if (newsTitlePt?.length > 0) {
        return newsTitle.filter((title) => title.language === 'ptBR')[0].value;
      }
      if (newsTitleEn?.length > 0) {
        return newsTitleEn.filter((title) => title.language === 'enUS')[0].value;
      }
      if (newsTitleEs?.length > 0) {
        return newsTitleEs.filter((title) => title.language === 'es')[0].value;
      }
      return "Sem Título"
    } else {
      return "Sem Título"
    }
  } else {
    return "Sem Título"
  }
}

class NewsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.slug = data.slug;
    this.highlight = data.highlight;
    this.ordering = data.ordering;
    this.active = data.active;
    this.event = data.event;
    this.file = data.file ? new FileModel(data.file) : undefined;
    this.translatables = data.translatables
      ? data.translatables.map((file) => new TranslatablesModel(file))
      : [];
    this.title = handleTranslate(data.translatables)

  }
}

export default NewsModel;
