import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { isArrayEmpty } from "~/helpers/utils/Functions";
import FileModel from "~/models/FileModel";
import CouponAPI from "../services/CouponAPI";
import UploadAPI from "../services/UploadAPI";
import ProductModel from "../models/ProductModel";
import CategoryModel from "../models/CategoryModel";
import CouponModel from "../models/CouponModel";

const initValues = {
  loading: false,
  loadingCampaignModels: false,
  coupon: new CouponModel(),
  coupons: [],
  campaignModels: [],
  applicationType: undefined,
  totalPages: 0,
  page: 0,
  size: 10,
  type: undefined,
  isSportbay: true,
};

class CouponStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;

    if (!this.sort) {
      this.sort = "created,desc";
    }

    if (sort) {
      this.sort = sort;
    }

    return await this.getList();
  }

  reset() {
    this.coupon = new CouponModel();
    this.coupons = [];
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.loading = false;
    this.applicationType = undefined;
  }

  updatePropClass(prop, value) {
    if (!this.coupon) {
      this.coupon = new CouponModel();
    }

    this.coupon[prop] = value;
    this.coupon = new CouponModel(this.coupon);
  }

  /** Busca lista de cupons */
  async getList() {
    this.loading = true;

    let params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      type: this.type,
    };

    if (!this.isSportbay) params = { ...params, active: true };

    const response = await CouponAPI.getList(params);

    this.loading = false;

    if (!response.error) {
      this.coupons = response.content.map(
        (mCoupon) => new CouponModel(mCoupon)
      );

      this.totalPages = response.totalPages;
      this.page = response.number;

      return this.coupons;
    }
    return response;
  }

  async delete(uuid) {
    this.loading = true;

    const response = await CouponAPI.delete(uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Cupom/campanha excluída com sucesso"
      );
    } else {
      this.notificationStore.notifyError(response.error);
    }

    this.loading = false;

    return response;
  }

  async update() {
    this.loading = true;

    const bannerToSave = this.coupon.image;
    const promotionUuid = this.coupon.uuid;
    this.coupon.image = undefined;

    const data = JSON.stringify(this.coupon);

    const response = await CouponAPI.update(this.coupon.uuid, data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Alterações gravadas com sucesso."
      );

      if (bannerToSave) {
        this.sendFiles([bannerToSave], promotionUuid);
      }

      this.coupon = new CouponModel();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  async save() {
    this.loading = true;

    const bannerToSave = this.coupon.image;
    this.coupon.image = undefined;

    const data = JSON.stringify(this.coupon);

    const response = await CouponAPI.save(data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Cupom/Campanha inserido com sucesso."
      );

      if (bannerToSave) {
        this.sendFiles([bannerToSave], response.data.uuid);
      }

      this.coupon = new CouponModel();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;

    return response;
  }

  /**Busca apenas um determinado cupom */
  async getCoupon(uuid) {
    this.coupon = new CouponModel();
    this.loading = true;
    const response = await CouponAPI.getCouponByUUID(uuid);

    if (!response.error) this.coupon = new CouponModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loading = false;
  }

  async getCampaignModels() {
    this.loadingCampaignModels = true;

    const response = await CouponAPI.getCampaignModels();

    if (!response.error) this.campaignModels = response;
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loadingCampaignModels = false;
  }

  setProductModel(productModelUuid, productModelName) {
    if (!this.coupon) {
      this.coupon = new CouponModel();
    }

    this.coupon.linkedProduct = new ProductModel({
      uuid: productModelUuid,
      name: productModelName,
    });
  }

  setCategory(categoryUuid) {
    if (!this.coupon) {
      this.coupon = new CouponModel();
    }

    this.coupon.category = new CategoryModel({
      uuid: categoryUuid,
    });
  }

  setPromotionType(promotionType) {
    if (!this.coupon) {
      this.coupon = new CouponModel();
    }

    this.coupon.promotionType = promotionType;

    this.coupon = new CouponModel(this.coupon);
  }

  clearLinkedProduct() {
    this.coupon.linkedProduct = undefined;

    this.coupon = new CouponModel(this.coupon);
  }

  addProduct(product) {
    if (isArrayEmpty(this.coupon.products)) {
      this.coupon.products = [];
    }

    this.coupon.products.push(product);
  }

  clearApplicationValues() {
    this.coupon.linkedProduct = undefined;
    this.coupon.isAllProducts = undefined;
    this.coupon.category = undefined;

    this.coupon = new CouponModel(this.coupon);
  }

  removeProduct(product) {
    if (isArrayEmpty(this.coupon.products)) {
      return;
    }

    this.coupon.products = this.coupon.products.filter(
      (f) => f.uuid !== product.uuid
    );
  }

  removeProductBySku(sku) {
    if (isArrayEmpty(this.coupon.products) || !sku) {
      return;
    }

    this.coupon.products = this.coupon.products.filter(
      (f) => f.skuCode !== sku
    );
  }

  handleBanner(dropedFiles) {
    if (!this.coupon) {
      this.coupon = new CouponModel();
    }

    this.handleImages(dropedFiles, "promotion-banner", this.coupon.image);
  }

  /**Adiciona imagem a propriedade */
  handleImages(dropedFiles, tag, fileItem) {
    /**Altera arquivo. */
    const dropedFile = dropedFiles.length > 0 ? dropedFiles[0] : undefined;
    if (!fileItem && dropedFile) {
      const newFileItem = new FileModel({
        file: dropedFile,
        ...dropedFile,
        metaTags: [tag],
      });

      this.coupon.image = newFileItem;
    } else fileItem.updateFile(dropedFile); //FileItem existe, Atualiza valores do arquivo recebido
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(files, promotionUuid) {
    const newFiles = files.filter((file) => !file.uuid);

    const updateds = files.filter(
      (file) => file.uuid && file.localModified && file.file
    );

    const deleteds = files.filter(
      (file) => file.uuid && !file.file && file.localModified
    );

    if (newFiles.length > 0) {
      await this.sendNewFiles(newFiles, promotionUuid);
    }

    updateds.length > 0 &&
      (await await this.sendNewFiles(updateds, promotionUuid));

    deleteds.length > 0 && (await this.deleteFile(promotionUuid));
  }

  async deleteFile(promotionUuid) {
    await UploadAPI.deleteBannerCampaign(promotionUuid, null);
  }

  async sendNewFiles(files, promotionUuid) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadBannerCampaign(promotionUuid, file);
    });

    await this.handlePromises(promise, "ao incluir imagens");

    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async handlePromises(promise, message) {
    const response = await Promise.all(promise);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    return response;
  }

  /** Associa um merchant a uma promoção */
  async associateMerchantCoupon(merchantUuid, couponUuid) {
    const response = await CouponAPI.associateMerchantCoupon(
      merchantUuid,
      couponUuid
    );

    if (!response.error)
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "A partir de agora você vai participar da promoção."
      );
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  /** Desassocia um merchant a uma promoção */
  async disassociateMerchantCoupon(merchantUuid, couponUuid) {
    const response = await CouponAPI.disassociateMerchantCoupon(
      merchantUuid,
      couponUuid
    );

    if (!response.error)
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "A partir de agora você não vai mais participar da promoção."
      );
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  ////////////////// New Cupom /////////////////////

  /**Remove a categoria da campanha */
  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
  }
}

export default CouponStore;
