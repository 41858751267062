import { dateToText } from "~/helpers/utils/Functions";
import BaseModel from "../BaseModel";
import OrderStatusModel from "./OrderStatusModel";

class StatusChangesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.created = dateToText(data.created, "/", true);
    this.newStatus = new OrderStatusModel(data.newStatus);
    this.previousStatus = new OrderStatusModel(data.previousStatus);
  }
}

export default StatusChangesModel;
