import { extendObservable } from "mobx";
import { removeItemList } from "~/helpers/utils/Functions";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

class NotificationStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.toastHelper = new ToastHelper();
        extendObservable(this, {
            uploadList: []
        })
    }

    /**noticação genérica */
    notify(status = STATUS_HELPER.INFO, message) {
        this.toastHelper.notify(status, message, 3000);
    }

    /**Notificação de erro */
    notifyError(message) {
        this.notify(STATUS_HELPER.ERROR, message);
    }

    notifySucess(message) {
        this.notify(STATUS_HELPER.SUCCESS, message);
    }

    /**Adiciona item na lista de arquivos sendo enviados */
    addItemUpload(item) {
        this.uploadList.push(item);
    }

    /**Remove item da lista de arquivos sendo enviados */
    removeItemUpload(item, delay = 1000) {
        setTimeout(() => {
            removeItemList(this.uploadList, item);
        }, delay);
    }
}

export default NotificationStore;
